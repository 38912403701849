import gql from 'graphql-tag';

export const GetProductsQuery = gql`
  query GetProducts {
    getProducts {
      PK
      SK
      Product {
        ProductID
        ProductName
      }
    }
  }
`;

/**
 *
 * @param {Client} graphClient graphql client
 * @param {Number} carrierId Carrier number
 * @param {Number} plantId Plant number
 * @returns Ship To Address for a carrier
 */
export const getProducts = async (graphClient) => {
  const data = await graphClient.query({
    query: GetProductsQuery,
    fetchPolicy: 'network-only',
  });
  return data;
};
