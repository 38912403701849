import { map, uniq } from 'lodash';

export const statuses = {
  Loaded: 'Loaded',
  Delivered: 'Delivered',
  PDFGenerated: 'PDFGenerated',
  EmailSent: 'EmailSent',
  Void: 'Void',
  Redirected: 'Redirected',
  RedirectNotified: 'RedirectNotified',
};

// Sort rank of status progression
export const statusesSortRank = {
  Void: 0, // lowest priority
  Loaded: 1,
  Delivered: 2,
  PDFGenerated: 3,
  EmailSent: 4,
  Redirected: 5,
  RedirectNotified: 6,
};

export const orderTypes = {
  Mapped: 'M',
  Unmapped: 'U',
};

const isShipmentStatus = (shipmentDetails, statusLabel) => {
  const allStatuses = map(shipmentDetails, 'Status');
  const uniqueStatuses = uniq(allStatuses);

  return uniqueStatuses.length === 1
    && uniqueStatuses[0].toLowerCase() === statusLabel.toLowerCase();
};

/**
 * DEPRECATED, DO NOT USE!
 * @param {*} shipmentDetails
 * @returns
 */
// eslint-disable-next-line arrow-body-style
export const isShipmentStatusDelivered = (shipmentDetails) => {
  return isShipmentStatus(shipmentDetails, statuses.Delivered);
};

// eslint-disable-next-line arrow-body-style
export const isDocketStatusDelivered = (deliveryDetails) => {
  return deliveryDetails?.Status
    && (
      deliveryDetails.Status.toLowerCase() === statuses.Delivered.toLowerCase()
      || deliveryDetails.Status.toLowerCase() === statuses.PDFGenerated.toLowerCase()
      || deliveryDetails.Status.toLowerCase() === statuses.EmailSent.toLowerCase()
    );
};

// eslint-disable-next-line arrow-body-style
export const isShipmentDelivered = (shipmentDetails) => {
  return shipmentDetails.length > 0
    && shipmentDetails.every((detail) => isDocketStatusDelivered(detail));
};
