import React from 'react';
import { Button, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import './_primaryButton.scss';

const PrimaryButton = ({ label, loading, ...buttonProps }) => (
  <Button
    {...buttonProps}
    className={`${buttonProps.className} primary-button p-0`}
  >
    {loading ? <Spinner size="sm" /> : label}
  </Button>

);

PrimaryButton.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

export default PrimaryButton;
