export const getUnixTimestamp = () => Math.round((new Date()).getTime() / 1000);

export const getFormattedDateTime = () => {
  const date = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
  const time = new Date().toLocaleTimeString('en-GB', {
    hour: '2-digit', minute: 'numeric', second: 'numeric', timeZone: 'Australia/Sydney', timeZoneName: 'short',
  });
  return `${date} ${time}`;
};

export const getFormattedDate = () => {
  const date = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
  return `${date}`;
};
