import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import './_dataCollection.scss';

const DataCollection = () => (
  <Container className="data-collection-container" data-testid="data-collection-container">
    <Row className="content-row m-0">
      <Col>
        <Row>
          <div className="heading" data-testid="heading">Data collection notice</div>
        </Row>
        <Row>
          <div className="sub-heading" data-testid="sub-heading">Sub title to go here (H4)</div>
        </Row>
        <Row>
          <div className="content" data-testid="content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Integer placerat nec neque at aliquet. Nulla efficitur leo
            nec turpis vulputate consequat. Suspendisse potenti. Nulla
            egestas turpis quis enim porttitor facilisis ac vel tellus.
            Morbi a elit in neque egestas fermentum non ac nunc. Mauris
            at gravida arcu, et facilisis nisi. Maecenas sed diam erat.
            Morbi vehicula diam a tristique tristique. Maecenas scelerisque
            enim dictum condimentum pellentesque.
            {' '}
            <br />
            <br />
            Donec quis risus sit amet eros commodo maximus. In hac habitasse
            platea dictumst. Vestibulum euismod justo non lorem pulvinar, ac
            aliquet nulla pulvinar. In iaculis vestibulum mi, ac elementum
            quam dignissim nec. Sed mattis augue quam, at pellentesque dolor
            consectetur nec.
          </div>
        </Row>
      </Col>
    </Row>
  </Container>
);

export default DataCollection;
