import gql from 'graphql-tag';

export const GetShipToQuery = gql`
query GetShipTo($pk: String!, $sk: String!) {
  getShipToAddresses(PK: $pk, SK: $sk) {
    PK
    SK
    ShipTo {
      ShipToName
      ShipToAddressLine1
      ShipToAddressLine2
      ShipToAddressLine3
      ShipToCity
      ShipToNumber
      ShipToPostCode
      ShipToEmail
      CarrierEmail
    }
  }
}
`;

/**
 *
 * @param {Client} graphClient graphql client
 * @param {Number} carrierId Carrier number
 * @param {Number} plantId Plant number
 * @returns Ship To Address for a carrier
 */
export const getShipToAddresses = async (graphClient, carrierId, plantId) => {
  const data = await graphClient.query({
    query: GetShipToQuery,
    variables: {
      pk: `CARRIERID#${carrierId}`,
      sk: `SAPPlantID#${plantId}`,
    },
    // fetchPolicy: 'network-only',
  });
  return data;
};
