import React, { useState, useContext } from 'react';
import {
  Container, Row, Col, Label,
} from 'reactstrap';
import ApolloClient from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import PropTypes from 'prop-types';
import { Trash } from '@bphxd/ds-core-react/icons';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useAppContext, DropContext } from '../../state/GlobalState';
import './_dropDetailCard.scss';
import withClient from '../../hoc/withClient';
import { isDocketStatusDelivered } from '../../helpers/shipmentStatus';
import ConfirmDeleteModal from '../ConfirmActionModal/ConfirmActionModal';
import { deleteDropDetails } from '../../api/graphQl/DropDetails';
import { editTankClickHandler } from '../../analytics/events';
import pageTitleConstant from '../../analytics/constantPageTitle';

const DropDetailCard = ({
  graphClient,
  dropDetail,
  deleteCallback,
  displayLoadId,
  hideEdit,
}) => {
  const history = useHistory();
  const { selectedDocket, handleGraphApiError, setSelectedDocket } = useAppContext();
  const [, setDrop] = useContext(DropContext);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);

  const openModal = () => setIsConfirmDeleteOpen(true);
  const closeModal = () => setIsConfirmDeleteOpen(false);

  const confirmDeleteMsg = (dropDetail.Tank && dropDetail.Tank !== '')
    ? `Are you sure you want to cancel this drop (${dropDetail.Tank})?`
    : 'Are you sure you want to cancel this drop?';

  const deleteResultMsg = (dropDetail.Tank && dropDetail.Tank !== '')
    ? `Drop (${dropDetail.Tank}) has been cancelled.`
    : 'Drop has been cancelled.';

  const deleteDropDetail = async () => {
    setActionInProgress(true);
    await deleteDropDetails(
      graphClient,
      { PK: dropDetail.PK, SK: dropDetail.SK },
      { DropId: dropDetail.DropId },
    )
      .then(() => {
        deleteCallback(deleteResultMsg);
        setActionInProgress(false);
      })
      .catch((error) => handleGraphApiError(error));

    closeModal();
  };

  const normalisedDropDetail = {
    DropId: dropDetail.DropId,
    Product: dropDetail.Product ? dropDetail.Product : '',
    Tank: dropDetail.Tank ? dropDetail.Tank : '',
    Capacity: dropDetail.Capacity ? dropDetail.Capacity : '',
    Compartments: dropDetail.Compartments ? dropDetail.Compartments : '',
    ActualDischargeQty: dropDetail.ActualDischargeQty ? dropDetail.ActualDischargeQty : '',
    DipAfter: dropDetail.DipAfter ? dropDetail.DipAfter : '',
    DipBefore: dropDetail.DipBefore ? dropDetail.DipBefore : '',
    Calculated: dropDetail.Calculated,
    LoadIdAddon: dropDetail.LoadIdAddon ? dropDetail.LoadIdAddon : '',
    TASVehicleID: dropDetail.TASVehicleID ? dropDetail.TASVehicleID : '',
    QuantityAmbient: dropDetail.QuantityAmbient ? dropDetail.QuantityAmbient : '',
    QuantityStandard: dropDetail.QuantityStandard ? dropDetail.QuantityStandard : '',
    PK: dropDetail.PK,
    SK: dropDetail.SK,
  };

  const truncateValue = (value) => {
    const limit = 10;
    return value.length > limit ? `${value.substring(0, limit)}...` : value;
  };

  const setDropAndRedirect = () => {
    setDrop(normalisedDropDetail);
    setSelectedDocket(selectedDocket);
    history.replace('/edit-drop-details');

    editTankClickHandler(pageTitleConstant.dropDetailSummary);
  };

  const docketInProgress = !isDocketStatusDelivered(selectedDocket);

  const hasQuantityValues = dropDetail.QuantityAmbient && dropDetail.QuantityStandard;
  const extraHeight = hasQuantityValues && (displayLoadId || docketInProgress);

  return (
    <Container
      className={clsx('drop-detail-card d-flex flex-column', (extraHeight) && 'extra-height')}
      data-testid="drop-detail-card"
    >
      <Row>
        <Col xs="6">
          {
            displayLoadId && (
              <Label className="bp-label label-title" data-testid="LoadIdAddon-value">
                {dropDetail.LoadIdAddon}
              </Label>
            )
          }
        </Col>

        {
          !hideEdit && (
            <Col xs="6" className="edit-col">
              {docketInProgress && (
                <Label
                  role="button"
                  className="green-link"
                  data-testid="edit-link"
                  onClick={setDropAndRedirect}
                >
                  Edit
                </Label>
              )}
            </Col> // Hide edit option for Aggregate Drop Details Cardß
          )
        }

        <Col xs="12">
          {
            (displayLoadId || docketInProgress) && (
              <div className="divider mb-4" />
            )
          }
        </Col>
      </Row>

      <Row key={dropDetail.DropId} data-testid="drop-detail" className="flex-grow-1 align-content-start">
        <Col xs="6">
          <Label className="drop-detail-label-name">Tank No/Name:</Label>
        </Col>
        <Col xs="6">
          <Label className="drop-detail-label-value" data-testid="Tank-value">
            {truncateValue(dropDetail.Tank)}
          </Label>
        </Col>

        <Col xs="6">
          <Label className="drop-detail-label-name">Product:</Label>
        </Col>
        <Col xs="6">
          <Label className="drop-detail-label-value" data-testid="Product-value">{dropDetail.Product}</Label>
        </Col>

        {hasQuantityValues && (
          <>
            <Col xs="6">
              <Label className="drop-detail-label-name">Qty. Ambient:</Label>
            </Col>
            <Col xs="6">
              <Label className="drop-detail-label-value" data-testid="QtyAmbient-value">{dropDetail.QuantityAmbient}</Label>
            </Col>

            <Col xs="6" className="mb-6">
              <Label className="drop-detail-label-name">Qty. Standard:</Label>
            </Col>
            <Col xs="6" className="mb-6">
              <Label className="drop-detail-label-value" data-testid="QtyStandard-value">{dropDetail.QuantityStandard}</Label>
            </Col>
          </>
        )}

        <Col xs="6">
          <Label className="drop-detail-label-name">Capacity (SFL):</Label>
        </Col>
        <Col xs="6">
          <Label className="drop-detail-label-value" data-testid="Capacity-value">{dropDetail.Capacity}</Label>
        </Col>

        <Col xs="6" className="mb-6">
          <Label className="drop-detail-label-name">Compartment(s):</Label>
        </Col>
        <Col xs="6" className="mb-6">
          <Label className="drop-detail-label-value" data-testid="Compartments-value">{dropDetail.Compartments}</Label>
        </Col>

        <Col xs="6">
          <Label className="drop-detail-label-name">Dip Before (B):</Label>
        </Col>
        <Col xs="6">
          <Label className="drop-detail-label-value" data-testid="DipBefore-value">{dropDetail.DipBefore}</Label>
        </Col>

        <Col xs="6">
          <Label className="drop-detail-label-name">Dip After (A):</Label>
        </Col>
        <Col xs="6">
          <Label className="drop-detail-label-value" data-testid="DipAfter-value">{dropDetail.DipAfter}</Label>
        </Col>

        <Col xs="6">
          <Label className="drop-detail-label-name">Calculated (A-B):</Label>
        </Col>
        <Col xs="6">
          <Label className="drop-detail-label-value" data-testid="Calculated-value">{dropDetail.Calculated}</Label>
        </Col>

        <Col xs="6">
          <Label className="drop-detail-label-name">Actual Discharge Qty:</Label>
        </Col>
        <Col xs="6">
          <Label className="drop-detail-label-value" data-testid="ActualDischargeQty-value">{dropDetail.ActualDischargeQty}</Label>
        </Col>
      </Row>

      {docketInProgress && (
        <Row>
          <Col xs="12">
            <div className="divider mb-2 mt-4" />
          </Col>

          <Col xs="12" className="d-flex justify-content-center">
            <div className="trash-icon-container">
              <Trash className="trash-icon" height="24" onClick={openModal} data-testid="delete-drop-btn" />
            </div>
          </Col>

          <ConfirmDeleteModal
            isOpen={isConfirmDeleteOpen}
            confirmMsg={confirmDeleteMsg}
            handleAction={deleteDropDetail}
            handleCancel={closeModal}
            actionInProgress={actionInProgress}
          />
        </Row>
      )}
    </Container>
  );
};

DropDetailCard.propTypes = {
  dropDetail: PropTypes.shape({
    ActualDischargeQty: PropTypes.number.isRequired,
    Calculated: PropTypes.number.isRequired,
    Capacity: PropTypes.number,
    Compartments: PropTypes.string,
    DipAfter: PropTypes.number,
    DipBefore: PropTypes.number.isRequired,
    DropId: PropTypes.number.isRequired,
    Product: PropTypes.string.isRequired,
    Tank: PropTypes.string,
    LoadIdAddon: PropTypes.string.isRequired,
    TASVehicleID: PropTypes.string,
    QuantityAmbient: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    QuantityStandard: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    PK: PropTypes.string,
    SK: PropTypes.string,
  }).isRequired,
  deleteCallback: PropTypes.func.isRequired,
  graphClient: PropTypes.oneOfType([
    PropTypes.instanceOf(AWSAppSyncClient).isRequired,
    PropTypes.instanceOf(ApolloClient).isRequired,
  ]).isRequired,
  displayLoadId: PropTypes.bool,
  hideEdit: PropTypes.bool,
};

// withClient will provide the client in props
export default withClient(DropDetailCard);
