import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalBody, Input, Label, Row,
} from 'reactstrap';
import {
  Remove32, Info32, Check32, Alert32, Alert24,
} from '@bphxd/ds-core-react/icons';
import SignatureCanvas from 'react-signature-canvas';
import Toggle from 'react-toggle';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { isMobileDevice } from '../../theme/mediaHelpers';
import { DeliveryDocketActions, SignCaptureMode } from '../../pages/DeliveryDocket/DeliveryDocketReducer';
import {
  nextClickHandler, clearSignatureClickHandler, closeClickHandler, signatureToggleOn,
  signatureToggleOff, safeCheckInClickTracker, safeCheckInNextTracker, safeCheckInCloseTracker,
} from '../../analytics/events';
import '../../theme/toggle.scss';
import './_signatureModal.scss';

const SignatureModal = ({
  actionInProgress,
  canvasRef,
  signatureOpen,
  dispatch,
  captureMode,
  canvasClass,
  nameCaptureInput,
  nameInputDisabled,
  clearSignature,
  nextDisabled,
  handleNextClick,
  toggleState,
  geoTag,
  geoNextBtn,
  geoWarningMessage,
  longitude,
  latitude,
}) => {
  const getGeoTag = () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const success = (position) => {
      dispatch({
        type: DeliveryDocketActions.SET_GEO_TAG,
        payload: {
          latitude: position.coords.latitude.toFixed(7).toString(),
          longitude: position.coords.longitude.toFixed(7).toString(),
          geoTag: `${position.coords.latitude.toFixed(7).toString()}, ${position.coords.longitude.toFixed(7).toString()}`,
          geoNextBtn: false,
        },
      });
    };

    const error = (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      dispatch({ type: DeliveryDocketActions.SET_GEO_TAG_WARNING });
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  let signCanvasWidth = 500;
  if (isMobileDevice()) {
    signCanvasWidth = 330;
  }

  return (
    <Modal
      isOpen={signatureOpen}
      toggle={() => dispatch({ type: DeliveryDocketActions.CLOSE_SIGNATURE_MODAL })}
    >
      <ModalBody>
        <Row className="d-flex flex-row-reverse pr-4 mt-n3">
          <Remove32
            onClick={() => {
              dispatch({ type: DeliveryDocketActions.CLOSE_SIGNATURE_MODAL });

              if (toggleState) {
                safeCheckInCloseTracker();
              } else {
                closeClickHandler();
              }
            }}
            className="add-pointer"
          />
        </Row>
        <Row className="align-middle">

          <Label className="m-0 font-14 ml-4 mr-4" data-testid="signature-modal-title">
            {captureMode === SignCaptureMode.CUSTOMER ? 'Customer ' : 'Driver '}
            {isMobileDevice() && (<br />)}
            Signature
          </Label>

          {captureMode === SignCaptureMode.CUSTOMER && (
            <Toggle
              data-testid="signature-modal-toggle"
              defaultChecked={toggleState}
              onChange={() => {
                if (toggleState) {
                  dispatch({
                    type: DeliveryDocketActions.DISABLE_CUSTOMER_SIGN,
                  });
                  signatureToggleOff();
                } else {
                  dispatch({
                    type: DeliveryDocketActions.ENABLE_CUSTOMER_SIGN,
                    payload: canvasRef.current,
                  });
                  signatureToggleOn();
                }
              }}
              aria-labelledby="biscuit-label"
              icons={false}
              className={isMobileDevice() ? 'mt-3 ml-6' : 'mt-n1 ml-6'}
            />
          )}

          {captureMode === SignCaptureMode.CUSTOMER && (
            <Label className="ml-3 font-14 ">
              Unable to get
              {
                isMobileDevice()
                  ? (
                    <br />
                  ) : (
                    <span> </span>
                  )
              }
              customer signature
            </Label>
          )}

          {captureMode !== SignCaptureMode.CUSTOMER && (
            <div className="padding-section1" />
          )}

          {!toggleState && captureMode === SignCaptureMode.CUSTOMER && (
            <Row className="ml-3 mt-1 mb-1">
              <Info32 className={isMobileDevice() ? 'mt-n1' : 'mt-n3'} />
              <Label className="font-12 text-left" style={isMobileDevice() ? { width: '300px' } : { width: '400px' }}>
                If unable to get signature,
                you can share your location as proof of delivery.
              </Label>
            </Row>
          )}
        </Row>

        {!toggleState && (
          <div style={{ height: '370px' }}>
            <SignatureCanvas
              ref={canvasRef}
              onBegin={() => {
                dispatch({ type: DeliveryDocketActions.START_SIGN });
              }}
              penColor="black"
              canvasProps={{ width: signCanvasWidth, height: 200, className: canvasClass }}
              data-testid="signature-canvas"
            />
            <div className="d-flex justify-content-between mt-3">
              <div>
                <Label className="font-14 mt-10">
                  {captureMode === SignCaptureMode.CUSTOMER ? 'Customer ' : 'Driver '}
                  Name :
                </Label>
                <Input
                  aria-label="name-capture-input"
                  disabled={nameInputDisabled}
                  type="text"
                  value={nameCaptureInput}
                  onChange={
                    (e) => dispatch(
                      { type: DeliveryDocketActions.SET_CUSTOMER_TEXT, payload: e.target.value },
                    )
                  }
                />
              </div>
              <div className="d-flex flex-column">
                <Label
                  className={`green font-12 ${clearSignature} ml-4`}
                  data-testid="clear-signature"
                  onClick={() => {
                    if (canvasRef) {
                      dispatch(
                        {
                          type: DeliveryDocketActions.CLEAR_SIGNATURE,
                          payload: canvasRef.current,
                        },
                      );
                    }
                    clearSignatureClickHandler();
                  }}
                >
                  Clear signature
                </Label>
                <div style={{ height: '5px' }} />
                <PrimaryButton
                  className="mt-10"
                  style={{ height: '46px', width: '101px' }}
                  disabled={nextDisabled || actionInProgress}
                  loading={actionInProgress}
                  label="Next"
                  data-testid="next"
                  onClick={() => { handleNextClick('', ''); nextClickHandler(); }}
                />
              </div>
            </div>
          </div>
        )}
        {toggleState && (
          <div>
            <div className="padding-section2" />
            <div style={{ height: '314px' }}>
              <Row className="justify-content-center" data-testid="geo-check">
                <button
                  className="geo-btn mt-11 mb-2"
                  type="button"
                  onClick={() => { getGeoTag(); safeCheckInClickTracker(); }}
                >
                  <span className="font-15">
                    Safe check-in
                  </span>
                </button>
              </Row>
              <Row className="justify-content-center">
                <button
                  className="info-btn mb-2 text-left"
                  type="button"
                >
                  <span className="font-12 d-flex">
                    <span className="mr-3 mt-n1">
                      <Info32 style={{ color: 'black' }} />
                    </span>
                    <span>
                      Please click to capture the current GPS
                      coordinates as a proof of delivery record.
                    </span>
                  </span>
                </button>
              </Row>
              {
                geoTag !== '--' && (
                  <Row className="justify-content-center">
                    <button
                      className="warning-btn"
                      type="button"
                    >
                      <Check32 style={{ color: '#007F00' }} className="mr-2" />
                      <span className="font-15">
                        Your location has been saved
                      </span>
                    </button>
                  </Row>
                )
              }
              {
                geoWarningMessage && (
                  <Row className="justify-content-center">
                    <button
                      className="message-btn mb-2 text-left"
                      type="button"
                    >
                      <span
                        className={isMobileDevice() ? 'font-13 d-flex' : 'font-16 d-flex'}
                      >
                        <span className="mt-n1 mr-3">
                          { isMobileDevice() ? <Alert24 style={{ color: '#FFCB2E' }} /> : <Alert32 style={{ color: '#FFCB2E' }} /> }
                        </span>
                        <span>
                          Please allow bp eDocket to access your location from device settings.
                        </span>
                      </span>
                    </button>
                  </Row>
                )
              }
            </div>
            <div style={{ height: '56px' }} className="d-flex">
              <div className="col-8">
                <Label style={{ height: '46px', width: '200px' }} className="font-12">
                  Delivery Coordinates:
                  {' '}
                  <br />
                  <span className="font-customized">{geoTag}</span>
                </Label>
              </div>
              <div className="padding-section3" />
              <div>
                <PrimaryButton
                  style={{ height: '46px', width: '101px' }}
                  disabled={geoNextBtn}
                  label="Next"
                  data-testid="next"
                  onClick={() => {
                    handleNextClick(longitude, latitude);
                    safeCheckInNextTracker();
                  }}
                />
              </div>
            </div>
          </div>
        )}

      </ModalBody>
    </Modal>
  );
};

SignatureModal.propTypes = {
  actionInProgress: PropTypes.bool,
  signatureOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  captureMode: PropTypes.string.isRequired,
  canvasClass: PropTypes.string.isRequired,
  nameInputDisabled: PropTypes.bool.isRequired,
  nameCaptureInput: PropTypes.string.isRequired,
  clearSignature: PropTypes.string.isRequired,
  nextDisabled: PropTypes.bool.isRequired,
  handleNextClick: PropTypes.func.isRequired,
  canvasRef: PropTypes.object,
  toggleState: PropTypes.bool,
  geoTag: PropTypes.string,
  geoNextBtn: PropTypes.bool,
  geoWarningMessage: PropTypes.bool,
  longitude: PropTypes.string,
  latitude: PropTypes.string,
};

export default SignatureModal;
