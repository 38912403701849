import { last, uniq } from 'lodash';

const delimiter = '#';

/**
 * Get the value of the attribute in a string, the key.
 *
 * The below example will result in '8130264317' returned
 * @example
 * extractAttributeFromKey('DeliveryNo#ORIGINAL#8130264317#LID#337777', 'ORIGINAL#')
 * @param {*} key A string that contains values separated by '#'
 * @param {*} attribute The attribute that you want to extract
 * @returns String
 */
export const extractAttributeFromKey = (key, attribute) => {
  if (!key) {
    return null;
  }
  const parts = key.split(attribute);
  if (parts.length === 1) {
    return null;
  }

  const value = last(parts);

  if (value.indexOf(delimiter) !== -1) {
    return value.split(delimiter)[0];
  }
  return value;
};

/**
 * Get the value of the attributes in a string, the key.
 *
 * You can use this method to pass more than 1 attribute to be extracted.
 *
 * The below example will result in '8130264317' returned
 * @example
 * extractAttributeFromKey('DeliveryNo#ORIGINAL#8130264317#LID#337777', ['ORIGINAL#', 'REDIRECT#'])
 * @param {*} key A string that contain values separated by '#'
 * @param {*} attributes The attributes that you want to extract
 * @returns String
 */
export const extractAttributesFromKey = (key, attributes) => attributes.map((attribute) => {
  const match = extractAttributeFromKey(key, attribute);
  if (match) {
    return match;
  }
  return null;
}).filter((item) => item !== null)[0];

/**
 * Get the attributes values of a key in a collection of objects.
 *
 * This method is useful when you want to many attributes from a collection of objects
 * @param {*} collection A collection of objects that contain values separated by '#'
 * @param {*} key The key in of the object here we want to look for the attribute
 * @param {*} attributes the attributes you want to look for
 * @returns List of attribute values
 */
export const extractAttributesFromCollection = (collection, key, attributes) => {
  const attributeValues = [];
  attributes.forEach((attribute) => {
    attributeValues.push(
      ...collection
        .map((object) => extractAttributeFromKey(object[key], attribute))
        .filter((element) => element !== null),
    );
  });
  return uniq(attributeValues).sort();
};

/**
 * Get the attribute value of a key in a collection of objects.
 *
 * This method is useful when you want to get the value of a single attribute from a collection
 * @param {*} collection A collection of objects that contain values separated by '#'
 * @param {*} key The key in of the object here we want to look for the attribute
 * @param {*} attribute the attribute you want to look for
 * @returns List of attribute values
 */
export const extractAttributeFromCollection = (collection, key, attribute) => {
  const attributes = collection.map((object) => extractAttributeFromKey(object[key], attribute));
  return uniq(attributes).sort();
};
