import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal,
} from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import { Info32 } from '@bphxd/ds-core-react/icons';
import {
  Col, Container, Row, Spinner,
} from 'reactstrap';
import SecondaryButton from '../../components/SecondaryButton/SecondaryButton';
import { useAppContext } from '../../state/GlobalState';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import bpHeaderTablet from '../../images/bp-header-tablet.png';
import './_welcomePage.scss';
import bpUnhappy from '../../images/bp-icon-smile-unhappy.png';
import { selectAccountRequest } from '../../auth/msal-config';
import { signInButtonClickHandler } from '../../analytics/events';

const WelcomePage = () => {
  // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { acquireToken, authoriseUser, driverAuthError } = useAppContext();
  const history = useHistory();

  const myRef = useRef(null);
  const [stickyButton, setStickyButton] = useState(true);

  const onScroll = () => {
    if (myRef && myRef.current) {
      const offsetTop = myRef.current.offsetTop;
      const windowHeight = window.innerHeight;
      const windowScrollY = window.scrollY;

      if (offsetTop - windowHeight > windowScrollY) {
        setStickyButton(true);
      } else {
        setStickyButton(false); // The element is in the view - blend in
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true });
    // clean up code
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    const acquireTokenAndAuthorise = async () => {
      await acquireToken();
      await authoriseUser();
      history.push('/landing-page');
    };

    if (instance && isAuthenticated && accounts.length > 0 && !driverAuthError) {
      acquireTokenAndAuthorise();
    }
  }, [isAuthenticated, accounts]);

  const signInClickHandler = async () => {
    // Will not work as there is a bug in signout
    signInButtonClickHandler();

    // clear all msal session cokkie to avoid issue as per #2327700
    await instance.browserStorage.clear();
    instance.loginRedirect(selectAccountRequest);
  };

  return (
    <Container fluid className="welcome-container m-0 p-0 h-100" data-testid="welcome-container">
      <Row className="ml-0 mr-0">
        <Col className="m-0 p-0">
          <Row className="ml-0 mr-0">
            <Col className="m-0 p-0">
              <img
                className="graphic img-fluid"
                data-testid="graphic"
                alt="bp Header"
                src={bpHeaderTablet}
              />
            </Col>
          </Row>
          <Row className="ml-0 mr-0">
            <Col
              md={{ size: 10, offset: 1 }}
              lg={{ size: 8, offset: 2 }}
              xl={{ size: 6, offset: 3 }}
            >
              <div className="welcome-heading" data-testid="welcome-heading">Welcome to bp eDocket</div>
            </Col>
          </Row>
          <AuthenticatedTemplate>
            <div className="d-flex flex-column align-items-center">
              {driverAuthError && <img className="py-3" src={bpUnhappy} alt="unhappy-face" />}
              {' '}
              {<span id="error-message" className="univers px-10 py-5 d-block text-center" data-testid="authenticated-message">{driverAuthError}</span> || 'Please wait ...' }
              {' '}
              {driverAuthError && <SecondaryButton label="Log out" className="w-50" onClick={() => instance.logout()} />}

              {!!driverAuthError || <Spinner id="welcome-spinner" className="mx-auto" />}
            </div>

          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Row className="ml-0 mr-0">
              <Col className="p-0" md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }} xl={{ size: 6, offset: 3 }}>
                <div className="app-info" data-testid="app-info">
                  bp eDocket provides a digital solution that makes it
                  easier for our partners to deliver fuel.
                </div>
              </Col>
            </Row>
            <Row className="card-container" data-testid="card-container">
              <Col>
                <Col className="card-col">
                  <Row>
                    <Col className="card-col" xs="12" md="4">
                      <div className="card" data-testid="card1">
                        <div className="icon1" data-testid="icon1" role="img" alt="Cloud Icon" />
                      </div>
                      <div className="icon-text">
                        A complete
                        {' '}
                        <b className="bold">digital solution.</b>
                        <br />

                        No more paperwork.
                      </div>
                    </Col>
                    <Col className="card-col" xs="12" md="4">
                      <div className="card" data-testid="card2">
                        <div className="icon2" data-testid="icon2" role="img" alt="Contract Icon" />
                      </div>
                      <div className="icon-text">
                        <b className="bold"> Capture signatures </b>
                        <br />
                        {' '}
                        from the driver and customer electronically.
                      </div>
                    </Col>
                    <Col className="card-col" xs="12" md="4">
                      <div className="card" data-testid="card3">
                        <div className="icon3" data-testid="icon3" role="img" alt="Device Icon" />
                      </div>
                      <div className="icon-text">
                        bp
                        {' '}
                        <b className="bold">eDocket</b>
                        {' '}
                        is emailed
                        {' '}
                        <br />
                        directly to the customer.
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>

            <span ref={myRef} className="ref-container">
              <Row className={`sign-in-row ml-0 mr-0 ${stickyButton ? 'sticky' : 'blended'}`} data-testid="sign-in-row">
                <Col
                  xs={{ size: 10, offset: 1 }}
                  sm={{ size: 10, offset: 1 }}
                  md={{ size: 10, offset: 1 }}
                  lg={{ size: 8, offset: 2 }}
                  xl={{ size: 6, offset: 3 }}
                >
                  <PrimaryButton
                    className="sign-in-button p-0"
                    data-testid="sign-in-button"
                    type="Submit"
                    label="Sign In"
                    onClick={() => signInClickHandler()}
                  />
                </Col>
                <Col
                  className="help-text-col mt-4 d-flex align-items-center justify-content-center"
                  lg={{ size: 8, offset: 2 }}
                  xl={{ size: 6, offset: 3 }}
                >
                  <span>
                    <Info32 className="info-icon" />
                  </span>
                  <span className="example-text" data-testid="example-text">
                    Contact your carrier’s dispatch office to register.
                  </span>
                </Col>
              </Row>
            </span>

          </UnauthenticatedTemplate>
        </Col>
      </Row>
    </Container>
  );
};

export default WelcomePage;
