import React from 'react';

export const ArrowUp = () => (
  <svg width="14" height="120" viewBox="0 0 8 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.35356 0.646439C4.1583 0.45118 3.84171 0.451179 3.64645 0.646439L0.464471 3.82842C0.269209 4.02368 0.269209 4.34026 0.464471 4.53553C0.659733 4.73079 0.976316 4.73079 1.17158 4.53553L4.00001 1.7071L6.82843 4.53553C7.02369 4.73079 7.34028 4.73079 7.53554 4.53553C7.7308 4.34026 7.7308 4.02368 7.53554 3.82842L4.35356 0.646439ZM4.5 120L4.50001 0.999992L3.50001 0.999992L3.5 120L4.5 120Z" fill="#C4C4C4" />
  </svg>
);

export const ArrowRight = () => (
  <svg width="85" height="8" viewBox="0 0 85 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M84.3536 4.35355C84.5488 4.15829 84.5488 3.84171 84.3536 3.64645L81.1716 0.464466C80.9763 0.269204 80.6597 0.269204 80.4645 0.464466C80.2692 0.659728 80.2692 0.976311 80.4645 1.17157L83.2929 4L80.4645 6.82843C80.2692 7.02369 80.2692 7.34027 80.4645 7.53553C80.6597 7.7308 80.9763 7.7308 81.1716 7.53553L84.3536 4.35355ZM0 4.5H84V3.5H0V4.5Z" fill="#C4C4C4" />
  </svg>
);
