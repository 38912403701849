/* eslint max-len: 0 */
import gql from 'graphql-tag';
import {
  getDocketSkDeliveryValue, removeTypename, skHasValue, getLidNumber,
} from '../../helpers/shipmentEngine';

// Example PK: "ShipmentNo#660889#CARRIERID#605882",
// Example SK: "871466" - this value is deliveryNo
export const GetDropDetails = gql`
  query GetDropDetails($pk: String!, $sk: String!) {
    getDropDetails(PK: $pk, SK: $sk) {
      PK
      SK
      DROPS {
        ActualDischargeQty
        Calculated
        Capacity
        Compartments
        DipAfter
        DipBefore
        DropId
        Product
        Tank
        TASVehicleID
        QuantityAmbient
        QuantityStandard
        Aggregate 
      }
    } 
  }
`;

// Example PK: ShipmentNo#305107799#CARRIERID#6901478
// Example SK: DROPDETAILS#DeliveryNo#ORIGINAL#8130264316#LID#337729
/* Example input: {
    ActualDischargeQty: 20,
    Calculated: 20,
    Capacity: 20,
    Compartments: "1, 2, 3, 4",
    DipAfter: 20,
    DipBefore: 20,
    DropId: 0, - id of the drop detail that we're updating
    Product: "BP Unleaded",
    Tank: "T-123"
  }
*/
export const UpdateDropDetails = gql`
  mutation UpdateDropDetails($pk: String!, $sk: String!, $input: DropInput!) {
    updateDropDetails(PK: $pk, SK: $sk, input: $input) {
      PK
      SK
      DROPS {
        ActualDischargeQty
        Calculated
        Capacity
        Compartments
        DipAfter
        DipBefore
        DropId
        Product
        Tank
        TASVehicleID
        QuantityAmbient
        QuantityStandard
        Aggregate  
      }
    }
  }
`;

// Example PK: ShipmentNo#305107799#CARRIERID#6901478
// Example SK: DROPDETAILS#DeliveryNo#ORIGINAL#8130264316#LID#337729
/* Example input: {
    ActualDischargeQty: 10,
    Calculated: 10,
    Capacity: 10,
    Compartments: "1, 3",
    DipAfter: 10,
    DipBefore: 10,
    DropId: 10,
    Product: "BP Diesel",
    Tank: "T-123"
  }
*/

export const CreateDropDetails = gql`
  mutation CreateDropDetails($pk: String!, $sk: String!, $input: DropInput!) {
    postDropDetails(PK: $pk, SK: $sk, input: $input) {
      PK
      SK
      DROPS {
        ActualDischargeQty
        Calculated
        Capacity
        Compartments
        DipAfter
        DipBefore
        DropId
        Product
        Tank
        TASVehicleID
        QuantityAmbient
        QuantityStandard
        Aggregate 
      }
    }
  }
`;

// Example PK: ShipmentNo#305107799#CARRIERID#6901478
// Example SK: DROPDETAILS#DeliveryNo#ORIGINAL#8130264316#LID#337729
// Example dropId: 0 - Drop detail id
export const DeleteDropDetails = gql`
  mutation DeleteDropDetails($pk: String!, $sk: String!, $dropId: String!) {
    deleteDropDetails(PK: $pk, SK: $sk, dropId: $dropId)
  }
`;

/**
 * Use this method to update the cache for drop details after posting or updating drops.
 * @param {*} cache
 * @param {*} dropDetail
 * @param {*} newDropData
 * @param {*} deleteDropData
 */
export const updateDropDetailsCache = (cache, dropDetail, deleteDropData) => {
  const querySKValue = getDocketSkDeliveryValue(dropDetail);
  const newDropData = deleteDropData ? false : dropDetail.DROPS[0];
  const selectedDropId = deleteDropData ? deleteDropData.DropId : newDropData.DropId;

  try {
    // GetDropDetails query needs to be updated with new values
    const dropDetailsCache = cache.readQuery({
      query: GetDropDetails,
      variables: {
        pk: dropDetail.PK,
        sk: querySKValue,
      },
    });
    dropDetailsCache.getDropDetails = [
      // Preserve cache values with different SK as untouched
      ...dropDetailsCache.getDropDetails
        .filter((dropDetailObject) => !skHasValue(dropDetailObject, dropDetail.SK)),
      // Update cache values with the same SK
      {
        PK: dropDetail.PK,
        SK: dropDetail.SK,
        DROPS: [
          // Collect drop details with the same SK
          ...dropDetailsCache.getDropDetails
            .filter(((dropDetailObject) => skHasValue(dropDetailObject, dropDetail.SK)))
          // flat map DROPS to get flat array with all drops - is this needed?
            .flatMap((ddo) => ddo.DROPS)
          // filter out drops with different DropId
            .filter((drop) => drop.DropId !== selectedDropId),
          // Add new or updated drop - works for add new or update existing drop
          // For delete - this element needs to be removed
          ...newDropData ? [{ ...newDropData, __typename: 'Drop' }] : [],
        ],
        // Type is needed for cached structures as we're symulating graphql structure that always has type
        __typename: 'DeliveryDrops',
      },
    ];

    // Write new values to GetDropDetails query
    cache.writeQuery({
      query: GetDropDetails,
      variables: {
        pk: dropDetail.PK,
        sk: querySKValue,
      },
      data: dropDetailsCache,
    });
  } catch (error) {
    if (error.message.includes('Can\'t find field')) {
      // Why? because if we try to read first before any values
      // exist for a key the cache will throw an error
      // It can just tell us that I dont have an entry for this key
      // It.....wants.....to....throw.........an exception ¯\_(ツ)_/¯

      // If the GetDropDetails query is empty, we need to create it
      cache.writeQuery({
        query: GetDropDetails,
        variables: {
          pk: dropDetail.PK,
          sk: querySKValue,
        },
        data: {
          getDropDetails: [
            {
              PK: dropDetail.PK,
              SK: dropDetail.SK,
              DROPS: [
                // New drop is being added to empty DROP array
                ...newDropData ? [{ ...newDropData, __typename: 'Drop' }] : [],
              ],
              __typename: 'DeliveryDrops',
            },
          ],
        },
      });
    }
  }
};

/**
 * Get the drop details for a docket.
 * @param {*} graphClient AWS Graph Client
 * @param {*} deliveryDocket Valid delivery docket
 * @returns Drop details for a docket
 */
export const getDeliveryDropDetails = async (graphClient, deliveryDocket) => {
  const response = await graphClient.query({
    query: GetDropDetails,
    variables: {
      pk: deliveryDocket.PK,
      sk: getDocketSkDeliveryValue(deliveryDocket),
    },
    fetchPolicy: 'network-only', // Doesn't check cache before making a network request
  });
  return response;
};

/**
 * @param {*} graphClient AWS Graphclient
 * @param {*} deliveryDocket Valid delivery docket
 * @returns Drop details for a docket and lid
 */
export const getRedirectDeliveryDropDetails = async (graphClient, deliveryDocket) => {
  const response = await graphClient.query({
    query: GetDropDetails,
    variables: {
      pk: deliveryDocket.PK,
      sk: `${getDocketSkDeliveryValue(deliveryDocket)}`,
    },
    fetchPolicy: 'cache-first', // Cache first, then network
  });

  if (response && response.data && response.data.getDropDetails) {
    const filteredVal = response.data.getDropDetails.filter(({ SK }) => SK.includes(`#LID#${getLidNumber(deliveryDocket)}`));
    return filteredVal;
  }
  return [];
};

/**
 * @param {*} graphClient AWS Graphclient
 * @param {*} dropDetail - contains PK and SK
 * @param {*} dropData - contains drop detail values
 */
export const createDropDetails = async (graphClient, dropDetail, dropData) => {
  const dropDataWithoutTypename = removeTypename(dropData);
  await graphClient.mutate({
    mutation: CreateDropDetails,
    variables: {
      pk: dropDetail.PK,
      sk: dropDetail.SK, // This wants the full SK
      input: dropDataWithoutTypename,
    },
    // store temporary optimistic version of the created object to achieve instantaneous updates to UI
    // when the actual version of the object is returned from the server,
    // the temporary value is replaced with the actual one and the UI is updated again
    // https://www.apollographql.com/docs/react/v2/performance/optimistic-ui/
    optimisticResponse: () => ({
      postDropDetails: {
        PK: dropDetail.PK,
        SK: dropDetail.SK,
        DROPS: [{ ...dropData, __typename: 'Drop' }], // type is always returned from graphql
        __typename: 'DeliveryDrops', // type is always returned from graphql
      },
    }),
    // update is used to apply additional, manual changes that are needed after the mutation is over
    // https://www.apollographql.com/docs/react/v2/data/mutations/#making-all-other-cache-updates
    update: (cache, response) => {
      updateDropDetailsCache(cache, response.data.postDropDetails);
    },
  });
};

/**
 * @param {*} graphClient AWS Graphclient
 * @param {*} dropDetail - contains PK and SK
 * @param {*} loadId - contains load id addon because it is not part of values
 * @param {*} dropData - contains drop detail values
 */
export const updateDropDetails = async (graphClient, dropDetail, loadId, dropData) => {
  const dropDataWithoutTypename = removeTypename(dropData);
  await graphClient.mutate({
    mutation: UpdateDropDetails,
    variables: {
      pk: dropDetail.PK,
      sk: `DROPDETAILS#DeliveryNo#${getDocketSkDeliveryValue(dropDetail)}#LID#${loadId}`,
      input: dropDataWithoutTypename,
    },
    // store temporary optimistic version of the created object to achieve instantaneous updates to UI
    // when the actual version of the object is returned from the server,
    // the temporary value is replaced with the actual one and the UI is updated again
    // https://www.apollographql.com/docs/react/v2/performance/optimistic-ui/
    optimisticResponse: () => ({
      updateDropDetails: {
        PK: dropDetail.PK,
        SK: `DROPDETAILS#DeliveryNo#${getDocketSkDeliveryValue(dropDetail)}#LID#${loadId}`,
        DROPS: [
          { ...dropData, __typename: 'Drop' }, // type is always returned from graphql
        ],
        __typename: 'DeliveryDrops', // type is always returned from graphql
      },
    }),
    // update is used to apply additional, manual changes that are needed after the mutation is over
    // https://www.apollographql.com/docs/react/v2/data/mutations/#making-all-other-cache-updates
    update: (cache, response) => {
      updateDropDetailsCache(cache, response.data.updateDropDetails);
    },
  });
};

/**
 * @param {*} graphClient AWS Graphclient
 * @param {*} dropDetail - contains PK and SK
 * @param {*} dropData - contains DropId
 */
export const deleteDropDetails = async (graphClient, dropDetail, dropData) => {
  await graphClient.mutate({
    mutation: DeleteDropDetails,
    variables: {
      pk: dropDetail.PK,
      sk: dropDetail.SK,
      dropId: `${dropData.DropId}`,
    },
    // store temporary optimistic version of the created object to achieve instantaneous updates to UI
    // when the actual version of the object is returned from the server,
    // the temporary value is replaced with the actual one and the UI is updated again
    // https://www.apollographql.com/docs/react/v2/performance/optimistic-ui/
    optimisticResponse: () => ({
      deleteDropDetails: true,
    }),
    // update is used to apply additional, manual changes that are needed after the mutation is over
    // https://www.apollographql.com/docs/react/v2/data/mutations/#making-all-other-cache-updates
    update: (cache) => {
      updateDropDetailsCache(cache, dropDetail, dropData);
    },
  });
};

/**
 * DEPRECATED
 * This method is hard coded to use the ORIGINAL value in the SK.
 * @param {*} graphClient AWS Graphclient
 * @param {*} shipmentId
 * @param {*} carrierId
 * @param {*} deliveryId
 * @returns
 */
export const getDropDetails = async (graphClient, shipmentId, carrierId, deliveryId) => {
  try {
    const data = await graphClient.query({
      query: GetDropDetails,
      variables: {
        pk: `ShipmentNo#${shipmentId}#CARRIERID#${carrierId}`,
        sk: `ORIGINAL#${deliveryId}`,
      },
      fetchPolicy: 'network-only',
    });
    return data;
  } catch (e) {
    console.error('Error while trying to query: GetDropDetails', e);
    return null;
  }
};
