import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import './_LidDetail.scss';
import {
  Info32, Left32, Right32, Check16,
} from '@bphxd/ds-core-react/icons';
import { extractAttributeFromCollection } from '../../helpers/attributeExtraction';

// change display mode based on screen width
const isMobile = window.screen.width < 768;

const LidDetail = ({
  shipmentDetails, dropDetailsAll, sendDataBackToParent,
}) => {
  const [loadIdAddon, setLoadIdAddon] = useState();

  const uniqLoadIdAddons = extractAttributeFromCollection(shipmentDetails, 'SK', 'LID#');

  const selectedShipmentDetail = shipmentDetails.find((sd) => sd.SK.includes(`LID#${loadIdAddon}`));

  const getSelectedProducts = (selectedShipmentDetail && selectedShipmentDetail.Products)
    ? selectedShipmentDetail.Products
    : [];

  // show 1st page by default
  const [currentPage, setcurrentPage] = useState(1);

  // show 6 products (3 Cols By 3 Rows) for tablet
  // show 3 products per page for mobile
  const [itemsPerPage] = isMobile ? useState(3) : useState(6);

  // change LID after click the LID button
  // Always show the 1st page after changing to a new LID
  const handleLoadIdChange = (e) => {
    setLoadIdAddon(e.target.value);
    setcurrentPage(1);
  };

  // Define how to tell one page from another
  const pages = [];
  for (let i = 1; i <= Math.ceil(getSelectedProducts.length / itemsPerPage); i += 1) {
    pages.push(i);
  }
  const indexofLastItem = currentPage * itemsPerPage;
  const indexofFirstItem = indexofLastItem - itemsPerPage;
  const currentItems = getSelectedProducts.slice(indexofFirstItem, indexofLastItem);

  // Disable previous button if already in 1st page
  const handlePreviousBtn = () => {
    if (currentPage !== pages[0]) {
      setcurrentPage(currentPage - 1);
    }
  };

  // Disable next button if already in last page
  const handleNextBtn = () => {
    if (currentPage !== pages.length) {
      setcurrentPage(currentPage + 1);
    }
  };

  // Calculate total delivered amount for selected delivery docket
  const calculateTotalDeliverd = (dropDetails) => {
    const map = new Map();
    if (dropDetails) {
      dropDetails.forEach((dropDetail) => {
        if (dropDetail.DROPS.length) {
          dropDetail.DROPS.forEach(
            (drop) => {
              if (map.has(dropDetail.SK.split('#LID#')[1] + drop.Product)) {
                map.set(
                  dropDetail.SK.split('#LID#')[1] + drop.Product, map.get(dropDetail.SK.split('#LID#')[1] + drop.Product) + drop.ActualDischargeQty,
                );
              } else { map.set(dropDetail.SK.split('#LID#')[1] + drop.Product, drop.ActualDischargeQty); }
            },
          );
        }
      });
      // console.log(map);
    }
    return map;
  };

  // Judge whether a LID has been fully delivered
  const whetherFullyDelivered = (lid) => {
    const lidProducts = shipmentDetails.find((sd) => sd.SK.includes(`LID#${lid}`)).Products;
    let flag = true;
    Object.values(lidProducts).forEach((product) => {
      if (dropDetailsAll && calculateTotalDeliverd(dropDetailsAll).get(lid + product.Name)) {
        flag = flag && parseInt(product.QuantityAmbient, 10)
          - calculateTotalDeliverd(dropDetailsAll).get(lid + product.Name) === 0;
      } else {
        flag = flag && parseInt(product.QuantityAmbient, 10) === 0;
      }
    });
    return flag;
  };

  const allDelivered = () => {
    let flag = true;
    uniqLoadIdAddons.forEach((uniqLoadIdAddon) => {
      flag = flag && whetherFullyDelivered(uniqLoadIdAddon);
    });
    sendDataBackToParent(flag);
  };
  // Show page numbers and highlight slected page numbers
  const renderPageNumbers = pages.map((number) => (
    <li
      key={number}
      id={number}
    >
      <span className={currentPage === number ? 'page-selected' : 'page-unselected'} />
    </li>
  ));

  // Show products (3 Cols By 3 Rows)
  let productList = [];
  if (currentItems.length > 0) {
    productList = currentItems.reduce((rows, key, index) => (
      index % 3 === 0 ? rows.push([key]) : rows[rows.length - 1].push(key)) && rows, []);
  }

  // set default LID
  if (!loadIdAddon && uniqLoadIdAddons.length > 0) {
    setLoadIdAddon(uniqLoadIdAddons[0]);
  }

  // console.log(dropDetailsAll);

  useEffect(() => {
    allDelivered();
  }, [dropDetailsAll]);

  return (
    <Container
      className="lids-container"
      data-testid="lids-container"
    >
      <Row className="text-center mt-2">
        <Col>
          <Label className="arial font-16 mt-2 font-weight-normal">Product(s) in this delivery docket:</Label>
        </Col>
        <Col sm="12" className="mb-2 d-flex justify-content-center">
          <Info32 className={isMobile ? 'info-icon mt-n1 ml-7 mr-1' : 'info-icon mt-n3 ml-1'} />
          <Label className="validation-info arial font-weight-normal font-12 text-left mr-6">
            Click on a load ID to view its products.
            Each load ID will get ticked off when no fuel remains.
          </Label>
        </Col>
      </Row>

      <Row className="mb-3 justify-content-center">
        {
          uniqLoadIdAddons.map((uniqLoadIdAddon) => (
            <button
              onClick={handleLoadIdChange}
              value={uniqLoadIdAddon}
              key={uniqLoadIdAddon}
              type="button"
              className={uniqLoadIdAddon === loadIdAddon ? 'm-2 rounded-pill btn btn-light' : 'm-2 rounded-pill btn btn-outline-light'}
            >
              {whetherFullyDelivered(uniqLoadIdAddon) && <Check16 />}
              {' '}
              {uniqLoadIdAddon}
            </button>
          ))
        }
      </Row>

      <Row className={isMobile ? 'w-100 d-flex' : 'justify-content-center ml-9'}>
        {/* Hide the previous button when current page is the 1st */}

        <div className={isMobile ? 'col-1 mt-7' : 'col-1 mr-6 mt-7 ml-n5'}>
          {
            (pages.length > 1) && (
              <Left32 className={currentPage === pages[0] ? 'page-inactive' : 'page-active'} onClick={handlePreviousBtn} />
            )
          }
        </div>

        <div className={isMobile ? 'arial col-9 product-list' : 'arial col product-list mb-n8'}>
          <div className={isMobile ? 'row ml-8 mt-1' : 'row justify-content-center'}>
            {
              productList.map((row) => (
                <div key={Math.random().toString(36).substr(2, 9)} className={isMobile ? 'col' : 'col-5 justify-content-center ml-6'}>
                  {
                    row.map((col) => (
                      <Row
                        key={Math.random().toString(36).substr(2, 9)}
                        className={isMobile ? 'product-label-name justify-content-left' : 'product-label-name justify-content-left'}
                      >
                        <Row>
                          <Row className="font-13 text-dark w-100">
                            {col.Name}
                            {' '}
                          </Row>
                          <Row className="customized-font">
                            Qty ambient remaining:
                            {
                              dropDetailsAll
                              && calculateTotalDeliverd(dropDetailsAll).get(loadIdAddon + col.Name)
                              && (
                                <Row className="ml-1">
                                  {col.QuantityAmbient ? (col.QuantityAmbient
                                    - calculateTotalDeliverd(dropDetailsAll).get(loadIdAddon + col.Name)).toFixed(2) : ' -- '}
                                </Row>
                              )
                            }
                            {
                              dropDetailsAll
                              && !calculateTotalDeliverd(dropDetailsAll).get(loadIdAddon + col.Name)
                              && (
                                <Row className="ml-1">
                                  {col.QuantityAmbient ? col.QuantityAmbient : ' -- '}
                                </Row>
                              )
                            }
                          </Row>
                        </Row>
                      </Row>
                    ))
                  }
                </div>
              ))
            }
          </div>
        </div>

        <div className={isMobile ? 'col-1 mr-1 mt-7' : 'col-2 mt-7'}>
          {
            (pages.length > 1) && (
              <Right32 className={currentPage === pages.length ? 'page-inactive' : 'page-active'} onClick={handleNextBtn} />
            )
          }
        </div>

      </Row>

      <Row className={isMobile ? 'pageNumbers justify-content-center mt-n9 mb-3' : 'pageNumbers justify-content-center mt-n3 mb-3'}>
        {
          (pages.length > 1) && (
            <Row>
              {renderPageNumbers}
            </Row>
          )
        }
      </Row>

    </Container>
  );
};

LidDetail.propTypes = {
  shipmentDetails: PropTypes.arrayOf(PropTypes.shape({
    SK: PropTypes.string.isRequired,
    Products: PropTypes.arrayOf(PropTypes.shape({
      Name: PropTypes.string.isRequired,
      QuantityAmbient: PropTypes.string,
      Compartments: PropTypes.string,
    })),
  })).isRequired,
  dropDetailsAll: PropTypes.arrayOf(PropTypes.shape({
    PK: PropTypes.string.isRequired,
    SK: PropTypes.string.isRequired,
    DROPS: PropTypes.arrayOf(PropTypes.shape({
    })),
  })).isRequired,
  sendDataBackToParent: PropTypes.func.isRequired,
};

export default LidDetail;
