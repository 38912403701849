import analytics from './gtmEventsView';
import pageConstant from './constantPageTitle';

// Menu Bar
export const homeClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'menu_home_clicked',
    label: 'home link clicked from menu',
  });
};

export const signOutClickHandlerGA = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'menuBar_signout_clicked',
    label: 'signout link clicked from menu',
  });
};

export const bpeDocketClickTracker = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'menuBar_bpedocket_clicked',
    label: 'bpedocket',
  });
};

export const signOutClickHandlerTimeoutGA = () => {
  analytics.sendEvent({
    category: 'event',
    action: 'sign_out_clicked',
    label: 'sign_out',
  });
};

// Welcome Page
export const signInButtonClickHandler = () => {
  analytics.setGAItem({ page_title: pageConstant.welcome });

  // Will not work as there is a bug in signout
  analytics.sendEvent({
    category: 'event',
    action: 'welcomePage_sign_in',
    label: 'sign-in',
  });
};

// Landing Page
export const lookUpDeliveryClickTracker = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'landingPage_look_up_a_delivery_clicked',
    label: 'look_up_a_delivery_clicked',
  });
};

export const createOpenOrderClickTracker = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'landingPage_create_open_order_clicked',
    label: 'create_open_order_clicked',
  });
};

// Find Shipment
export const goButtonClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'findShipment_enter_shipment_number',
    label: 'enter shipment number button clicked',
  });
};

export const errorHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'findShipment_enter_shipment_error',
    label: 'error labels displayed on enter shipment number screen',
  });
};

export const findShipmentToggleOnTracker = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'findShipment_toggle_on',
    label: 'Toggle on',
  });
};

// Look up open orders
export const openOrderGoTracker = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'lookUpOpenOrder_go_clicked',
    label: 'Go',
  });
};

export const openOrderToggleOffTracker = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'lookUpOpenOrder_toggle_off',
    label: 'Toggle off',
  });
};

export const openOrderBackButtonTracker = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'lookUpOpenOrder_back_clicked',
    label: 'Toggle off',
  });
};

// Shipment Details
export const lookUpDeliveryClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'shipmentDetails_look_up_another_delivery_clicked',
    label: 'look up another delivery',
  });
};

// Delivery Docket Details
export const newDocketClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'shipmentDetails_new_docket_clicked',
    label: 'loaded docket',
  });
};

export const roDocketClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'shipmentDetails_ro_docket_clicked',
    label: 'read only docket',
  });
};

export const ddBackButtonClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'docketDetails_back_clicked',
    label: 'back button clicked on delivery docket screen',
  });
};

export const dropDetailTileClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'docketDetails_drop_detail_tile_clicked',
    label: 'drop_detail_tile',
  });
};

export const signatureTileClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'docketDetails_signature_tile_clicked',
    label: 'signature_tile',
  });
};

export const fullRedirectClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'docketDetails_full_redirect_clicked',
    label: 'full_redirect',
  });
};

// Enter Drop Details
export const lidAddonClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'dropDetails_lid_dropdown_clicked',
    label: 'lid_dropdown',
  });
};

export const productClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'dropDetails_product_dropdown_clicked',
    label: 'product_dropdown',
  });
};

export const saveClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'dropDetails_save_button_clicked',
    label: 'save_button',
  });
};

export const clearFieldsClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'dropDetails_clear_fields_clicked',
    label: 'clear_fields',
  });
};

export const backButtonClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'dropDetails_back_clicked',
    label: 'back',
  });
};

// Tank Details
export const addAnotherTankClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'tankDetails_add_tank_clicked',
    label: 'add_tank',
  });
};

export const dropBackButtonClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'tankDetails_back_clicked',
    label: 'add_tank',
  });
};

export const collectSignaturesClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'tankDetails_collect_signatures_clicked',
    label: 'collect_signatures',
  });
};

export const editTankClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'tankDetails_edit_tank_clicked',
    label: 'edit_tank',
  });
};

// Edit Drop Details
export const editProductClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'editDropDetails_product_dropdown_clicked',
    label: 'product_dropdown',
  });
};

export const editSaveClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'editDropDetails_save_button_clicked',
    label: 'save_button',
  });
};

export const editClearFieldsClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'editDropDetails_clear_fields_clicked',
    label: 'clear_fields',
  });
};

export const editBackButtonClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'editDropDetails_back_clicked',
    label: 'back',
  });
};

// Delete Tank Modal
export const deleteTankYesClickHandler = () => {
  analytics.setGAItem({ page_title: pageConstant.deleteDrop });

  analytics.sendEvent({
    category: 'event',
    action: 'deleteTank_modal_yes_clicked',
    label: 'yes',
  });
};

export const deleteTankNoClickHandler = () => {
  analytics.setGAItem({ page_title: pageConstant.deleteDrop });

  analytics.sendEvent({
    category: 'event',
    action: 'deleteTank_modal_no_clicked',
    label: 'no',
  });
};

// Customer/Driver Signature
export const nextClickHandler = () => {
  analytics.setGAItem({ page_title: pageConstant.customerSignature });
  analytics.sendEvent({
    category: 'event',
    action: 'custSigModal_next_clicked',
    label: 'next',
  });
};

export const clearSignatureClickHandler = () => {
  analytics.setGAItem({ page_title: pageConstant.customerSignature });
  analytics.sendEvent({
    category: 'event',
    action: 'custSigModal_clear_clicked',
    label: 'clear',
  });
};

export const closeClickHandler = () => {
  analytics.setGAItem({ page_title: pageConstant.customerSignature });
  analytics.sendEvent({
    category: 'event',
    action: 'custSigModal_close_clicked',
    label: 'close',
  });
};

export const signatureToggleOn = () => {
  analytics.setGAItem({ page_title: pageConstant.safeCheckIn });
  analytics.sendEvent({
    category: 'event',
    action: 'safeCheckIn_toggle_on',
    label: 'Toggle on',
  });
};

export const signatureToggleOff = () => {
  analytics.setGAItem({ page_title: pageConstant.safeCheckIn });
  analytics.sendEvent({
    category: 'event',
    action: 'safeCheckIn_toggle_off',
    label: 'Toggle off',
  });
};

export const safeCheckInClickTracker = () => {
  analytics.setGAItem({ page_title: pageConstant.safeCheckIn });
  analytics.sendEvent({
    category: 'event',
    action: 'safeCheckIn_safe_check_in',
    label: 'Check in',
  });
};

export const safeCheckInNextTracker = () => {
  analytics.setGAItem({ page_title: pageConstant.safeCheckIn });
  analytics.sendEvent({
    category: 'event',
    action: 'safeCheckIn_next_clicked',
    label: 'Next',
  });
};
export const safeCheckInCloseTracker = () => {
  analytics.setGAItem({ page_title: pageConstant.safeCheckIn });
  analytics.sendEvent({
    category: 'event',
    action: 'safeCheckIn_close_clicked',
    label: 'Close',
  });
};
// Delivery Confirmation Modal
export const deliveryModalYesClickHandler = () => {
  analytics.setGAItem({ page_title: pageConstant.deliveryConfirmation });
  analytics.sendEvent({
    category: 'event',
    action: 'deliveryConfirmation_modal_yes_clicked',
    label: 'yes',
  });
};

export const deliveryModalNoClickHandler = () => {
  analytics.setGAItem({ page_title: pageConstant.deliveryConfirmation });
  analytics.sendEvent({
    category: 'event',
    action: 'deliveryConfirmation_modal_no_clicked',
    label: 'no',
  });
};

export const deliveryModalCloseClickHandler = () => {
  analytics.setGAItem({ page_title: pageConstant.deliveryConfirmation });
  analytics.sendEvent({
    category: 'event',
    action: 'deliveryConfirmation_modal_close_clicked',
    label: 'close',
  });
};

// Redirect Reason Modal
export const redirectReasonDropdownHandler = () => {
  analytics.setGAItem({ page_title: pageConstant.redirect });
  analytics.sendEvent({
    category: 'event',
    action: 'redirectModal_reason_dropdown',
    label: 'redirect reason dropdown',
  });
};

export const redirectContinueClickHandler = () => {
  analytics.setGAItem({ page_title: pageConstant.redirect });
  analytics.sendEvent({
    category: 'event',
    action: 'redirectModal_continue_clicked',
    label: 'continue',
  });
};

export const closeRedirectModalClickHandler = () => {
  analytics.setGAItem({ page_title: pageConstant.redirect });
  analytics.sendEvent({
    category: 'event',
    action: 'redirectModal_close_clicked',
    label: 'close',
  });
};

// Redirected Delivery Docket Details
export const updateDestinationButtonClickHandler = () => {
  analytics.setGAItem({ page_title: pageConstant.redirectedDeliveryDocket });
  analytics.sendEvent({
    category: 'event',
    action: 'redirectDocketDetails_update_destination_clicked',
    label: 'update_destination_button',
  });
};

export const redirectBackClickHandler = () => {
  analytics.setGAItem({ page_title: pageConstant.redirectedDeliveryDocket });
  analytics.sendEvent({
    category: 'event',
    action: 'redirectDocketDetails_back_clicked',
    label: 'update_destination_button',
  });
};

// Update Destination Screen
export const locationDropdownClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'updateDestination_location_dropdown_selected',
    label: 'location_dropdown',
  });
};

export const updateDestinationClearClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'updateDestination_clear_clicked',
    label: 'clear_button',
  });
};

export const updateDestinationSaveClickHandler = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'updateDestination_save_button_clicked',
    label: 'save_button',
  });
};

export const updateDestinationBackTracker = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'updateDestination_back_clicked',
    label: 'back',
  });
};

// Product Discharge Alert
export const productDischargeAlertYesTracker = () => {
  analytics.setGAItem({ page_title: pageConstant.productDischargeAlert });

  analytics.sendEvent({
    category: 'event',
    action: 'productDischargeAlert_yes_clicked',
    label: 'Yes',
  });
};

export const productDischargeAlertNoTracker = () => {
  analytics.setGAItem({ page_title: pageConstant.productDischargeAlert });

  analytics.sendEvent({
    category: 'event',
    action: 'productDischargeAlert_no_clicked',
    label: 'No',
  });
};

// Create Open Order
export const takePhotoClickTracker = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'createOpenOrder_take_photo_clicked',
    label: 'Take photo clicked',
  });
};

export const createOpenOrderContinueClickTracker = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'createOpenOrder_continue_clicked',
    label: 'Continue',
  });
};

export const createOpenOrderBackClickTracker = (pageTitle) => {
  analytics.sendEvent({
    page_title: pageTitle,
    category: 'event',
    action: 'createOpenOrder_back_clicked',
    label: 'Back',
  });
};
