import gql from 'graphql-tag';
import { getDeliveryValue } from '../../helpers/shipmentEngine';
import { getFormattedDateTime } from '../../helpers/timeHelpers';

export const GetCustomerSignature = gql`
query GetCustomerSignature($pk: String!, $sk: String!) {
  getCustomerSignature(PK: $pk, SK: $sk) {
    CreatedAt
    CreatedBy
    CustomerName
    CustomerSignature
    NoSignature
    Latitude
    Longitude
    SK
    PK
  }
}
`;

export const GetDriverSignature = gql`
query GetDriverSignature($pk: String!, $sk: String!) {
  getDriverSignature(PK: $pk, SK: $sk) {
    CreatedAt
    CreatedBy
    DriverSignature
    SAPDriverID
    SK
    PK
  }
}
`;

export const CustomerSignature = gql`
mutation CustomerSignatureCapture(
  $pk: String!,
  $sk: String!,
  $sign: String!,
  $customer: String!,
  $noSign: Boolean!,
  $created: String!,
  $createdBy: String!,
  $longitude: String,
  $latitude: String,
) {
  postCustomerSignature(
    input: {
      CustomerName: $customer,
      CustomerSignature: $sign,
      NoSignature: $noSign,
      CreatedAt: $created,
      CreatedBy: $createdBy,
      Longitude: $longitude,
      Latitude: $latitude,
    }, 
    PK: $pk,
    SK: $sk
  )
}
`;

export const DriverSignature = gql`
mutation DriverSignatureCapture(
$pk: String!,
$sk: String!,
$sign: String!,
$created: String!,
$createdBy: String!
) {
postDriverSignature(
  input: {
    CreatedAt: $created, 
    CreatedBy: $createdBy, 
    DriverSignature: $sign,
  }, 
  PK: $pk, 
  SK: $sk
)
}
`;

export const submitCustomerSignature = async (
  graphClient,
  docket,
  deliveryId,
  signature,
  customerName,
  signaturePresent,
  createdBy,
  longitude,
  latitude,
) => {
  try {
    await graphClient.mutate({
      mutation: CustomerSignature,
      variables: {
        pk: docket.PK,
        sk: deliveryId,
        sign: signature,
        customer: customerName,
        noSign: signaturePresent,
        created: getFormattedDateTime(),
        createdBy,
        longitude,
        latitude,
      },
      optimisticResponse: () => ({
        postCustomerSignature: {
          input: {
            CustomerName: customerName,
            CustomerSignature: signature,
            NoSignature: signaturePresent,
            CreatedAt: getFormattedDateTime(),
            CreatedBy: createdBy,
            Longitude: longitude,
            Latitude: latitude,
          },
          PK: docket.PK,
          SK: `CUSTSIG#DeliveryNo#${deliveryId}`,
        },
      }),
      update: ((cache, response) => {
        if (typeof (response?.data?.postCustomerSignature) === 'object') {
          const { input, PK, SK } = response.data.postCustomerSignature;
          cache.writeQuery({
            query: GetCustomerSignature,
            variables: {
              pk: docket.PK,
              sk: deliveryId,
            },
            data: {
              getCustomerSignature: {
                ...input,
                SK,
                PK,
                __typename: 'CustomerSignatureInfo',
              },
            },
          });
        }
      }),
    });
  } catch (error) {
    console.error(error);
  }
};

export const submitDriverSignature = async (
  graphClient,
  docket,
  deliveryId,
  signature,
  createdBy,
) => {
  try {
    await graphClient.mutate({
      mutation: DriverSignature,
      variables: {
        pk: docket.PK,
        sk: `${deliveryId}`,
        sign: signature,
        created: getFormattedDateTime(),
        createdBy,
      },
      optimisticResponse: () => ({
        postDriverSignature: {
          input: {
            CreatedAt: getFormattedDateTime(),
            CreatedBy: createdBy,
            DriverSignature: signature,
            SAPDriverID: createdBy,
          },
          SK: `DRIVERSIG#DeliveryNo#${deliveryId}`,
          PK: docket.PK,
        },
      }),
      update: ((cache, response) => {
        if (typeof (response?.data?.postDriverSignature) === 'object') {
          const { input, PK, SK } = response.data.postDriverSignature;
          cache.writeQuery({
            query: GetDriverSignature,
            variables: {
              pk: docket.PK,
              sk: deliveryId,
            },
            data: {
              getDriverSignature: {
                ...input,
                PK,
                SK,
                __typename: 'DriverSignatureInfo',
              },
            },
          });
        }
      }),
    });
  } catch (error) {
    console.error(error);
  }
};

export const getCustomerSignature = async (graphClient, deliveryDocket) => {
  const response = await graphClient.query({
    query: GetCustomerSignature,
    variables: {
      pk: deliveryDocket.PK,
      sk: getDeliveryValue(deliveryDocket),
    },
  });
  return response?.data;
};

export const getDriverSignature = async (graphClient, deliveryDocket) => {
  const response = await graphClient.query({
    query: GetDriverSignature,
    variables: {
      pk: deliveryDocket.PK,
      sk: getDeliveryValue(deliveryDocket),
    },
  });
  return response?.data;
};
