const pageTitles = {
  menu: 'Menu',
  welcome: 'Welcome to BP eDocket',
  timeout: 'Session Timeout Page',
  landingPage: 'Landing Page',
  lookUpShipment: 'Look Up Shipment',
  lookUpOpenOrder: 'Look Up Open Order',
  shipmentDetailsPage: 'Shipment Details',
  deliveryDocketPage: 'Delivery Docket',
  dropDetailsForm: 'Drop Details Form',
  editDropDetailsForm: 'Edit Drop Details Form',
  dropDetailSummary: 'Drop Detail Summary',
  deleteDrop: 'Delete Drop',
  customerSignature: 'Customer Signature',
  safeCheckIn: 'Safe Check In',
  driverSignature: 'Driver Signature',
  deliveryConfirmation: 'Delivery Confirmation',
  redirect: 'Redirect',
  redirectedDeliveryDocket: 'Redirected Delivery Docket',
  updateDestination: 'Update Destination',
  productDischargeAlert: 'Product Discharge Alert',
  createOpenOrder: 'Create Open Order',
  sessionTimeout: 'Session Timeout',
};
export default pageTitles;
