import React from 'react';
import {
  Container, Row, Col, Label,
} from 'reactstrap';
import './_backButton.scss';
import { Left24 } from '@bphxd/ds-core-react/icons';

const BackButton = () => (
  <Container className="back-button-container p-0" data-testid="back-button-container">
    <Row>
      <Col className="icon">
        <Left24 />
        <Label className="button-text">Back</Label>
      </Col>
    </Row>
  </Container>
);

export default BackButton;
