import React, { useState, useEffect } from 'react';
import {
  Container, Col, Row, InputGroup, Input, Form,
  FormGroup, Label, FormText, CustomInput, Alert,
} from 'reactstrap';
import { Formik } from 'formik';
import { Info32, Alert24 } from '@bphxd/ds-core-react/icons';
import PropTypes from 'prop-types';
import ApolloClient from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { Link, useHistory } from 'react-router-dom';
import last from 'lodash/last';
import axios from 'axios';
import find from 'lodash/find';
import { useMsal } from '@azure/msal-react';
import withClient from '../../hoc/withClient';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { useAppContext } from '../../state/GlobalState';
import { initialValues, CreateOpenOrderSchema } from './validation';
import { getShipmentDetails, postDocketDetails } from '../../api/graphQl/Shipment';
import { getLoadingPlants } from '../../api/graphQl/LoadingPlant';
import { getShipToAddresses } from '../../api/graphQl/ShipTo';
import { getS3PreSignedUrl } from '../../api/graphQl/FileUpload';
import { getProducts } from '../../api/graphQl/Products';
import BillOfLadingExample from '../../images/bill-of-lading-example.png';
import BackButton from '../../components/BackButton/BackButton';
import { ArrowUp, ArrowRight } from '../../icons/BillOfLadingIcons';
import './_createOpenOrder.scss';
import { statuses, orderTypes } from '../../helpers/shipmentStatus';
import { getFormattedDate, getFormattedDateTime } from '../../helpers/timeHelpers';
import {
  takePhotoClickTracker, createOpenOrderContinueClickTracker, createOpenOrderBackClickTracker,
} from '../../analytics/events';
import pageTitleConstant from '../../analytics/constantPageTitle';
import NetworkSpeedChecker from '../../components/NetworkSpeedChecker/NetworkSpeedChecker';

const CreateOpenOrder = ({ graphClient }) => {
  const history = useHistory();
  const currentUser = useMsal().accounts[0].name;
  const [loadingPlants, setLoadingPlants] = useState([]);
  const [shipmentExists, setShipmentExists] = useState(false);
  const [fileObj, setFileObj] = useState();
  const [dataUri, setDataUri] = useState();
  const [status, setStatus] = useState();

  const {
    carrierId, handleGraphApiError, setShipmentId, setPlantId, setAddresses, setBpProducts,
  } = useAppContext();

  const fetchLoadingPlants = async () => {
    try {
      const response = await getLoadingPlants(
        graphClient,
        carrierId,
      );

      if (response?.data?.getLoadingPlants?.PLANTS) {
        setLoadingPlants(response.data.getLoadingPlants.PLANTS);
      }
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  const fetchShipTos = async (plantId) => {
    try {
      const response = await getShipToAddresses(
        graphClient,
        carrierId,
        plantId,
      );

      if (response?.data?.getShipToAddresses?.ShipTo) {
        setAddresses(response?.data?.getShipToAddresses?.ShipTo);
      }
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  const fetchPreSignedS3Url = async (fileName, fileExtension, mimeType) => {
    try {
      const response = await getS3PreSignedUrl(
        graphClient,
        fileName,
        fileExtension,
        mimeType,
      );

      if (response?.data?.getS3PreSignedUrl) {
        return response.data.getS3PreSignedUrl;
      }
    } catch (error) {
      handleGraphApiError(error);
    }
    return null;
  };

  const getInitialValues = () => {
    const overrideValues = {};

    if (loadingPlants.length === 1) {
      overrideValues.LoadingPlant = loadingPlants[0].PlantID;
    }

    // Pre-select value for loading plant if only one present
    return Object.assign(initialValues, overrideValues);
  };

  const handlePhotoCapture = (e) => {
    if (e && e.target && e.target.files && e.target.files[0]) {
      const fileInput = e.target.files[0];

      setFileObj(fileInput);
      setDataUri(URL.createObjectURL(fileInput));
    } else {
      console.error('Photo not captured.');
    }
  };

  const createFileWithNewName = (bits, name, options) => {
    try {
      // If this fails, we go for Blob
      return new File(bits, name, options);
    } catch (e) {
      // If we're here a new File could not be constructed
      const myBlob = new Blob(bits, options || {});
      myBlob.lastModified = new Date();
      myBlob.name = name;
      return myBlob;
    }
  };

  const onSubmit = async (values) => {
    const shipmentId = `${values.ReferenceNo}-${values.LoadingPlant}`;
    setStatus('Checking if the order exists');
    try {
      const shDetails = await getShipmentDetails(graphClient, shipmentId, carrierId);
      if (shDetails?.data?.getShipmentDetails && shDetails?.data?.getShipmentDetails.length > 0) {
        setStatus();
        setShipmentExists(true);
        return;
      }
      setShipmentExists(false);
    } catch (error) {
      handleGraphApiError(error);
    }

    const fileName = `BOL/ReferenceNo_${values.ReferenceNo}_PlantID_${values.LoadingPlant}`;
    const fileExtenstion = last(fileObj.name.split('.'));
    const newName = `${fileName}.${fileExtenstion}`;
    const mimeType = fileObj.type;

    setStatus('Preparing the photo for upload');
    const s3Url = await fetchPreSignedS3Url(
      fileName,
      fileExtenstion,
      mimeType,
    );

    const newFileObj = createFileWithNewName([fileObj], newName, { type: mimeType });

    setStatus('Uploading BOL photo');
    try {
      await axios.put(s3Url, newFileObj, {
        headers: {
          'Content-Type': mimeType,
        },
      });
    } catch (error) {
      console.log(error);
      return;
    }

    const selectedPlant = find(loadingPlants, { PlantID: values.LoadingPlant });
    const bolName = `ReferenceNo_${values.ReferenceNo}_PlantID_${values.LoadingPlant}.${fileExtenstion}`;

    // Fetch Ship to Addresses
    setStatus('Fetching data');
    await fetchShipTos(selectedPlant.PlantID);

    // Create an open order
    setStatus('Creating an open order');

    const openOrderShipment = {
      PK: `ShipmentNo#${shipmentId}#CARRIERID#${carrierId}`,
      SK: `DeliveryNo#ORIGINAL#${shipmentId}#LID#${values.ReferenceNo}`,
      Status: statuses.Loaded,
      BOLName: bolName,
      DateUpdated: getFormattedDateTime(),
      PlannedStartDate: getFormattedDate(),
      SAPPlantId: selectedPlant ? selectedPlant.PlantID : '',
      TASVehicleID: values.Vehicle,
      PlannedDriver: currentUser,
      LoadingPlant: selectedPlant ? selectedPlant.PlantName : '',
      OrderType: orderTypes.Unmapped,
      BPEmail: process.env.REACT_APP_bp_email || '',
      RedirectEmail: process.env.REACT_APP_redirect_email || '',
      PONumber: '', // needed for pdf generation
      OrderNo: '', // needed for pdf generation
    };

    await postDocketDetails(graphClient, openOrderShipment, true)
      .catch((error) => handleGraphApiError(error));

    // Successful record creation - redirect to the next page
    setStatus('Successfully created an open order');
    setShipmentId(shipmentId);
    setPlantId(selectedPlant.PlantID);

    history.push('/shipment-details');
  };

  const fetchProducts = async () => {
    try {
      const response = await getProducts(graphClient);
      if (response?.data?.getProducts?.Product) {
        // Carrier products schema has different field names (ProductName, ProductID),
        // so we need to map them to the field names (Name, Id) that drop details forms expect
        const products = response?.data?.getProducts?.Product.map((prod) => ({
          Name: prod.ProductName,
          Id: prod.ProductID,
        }));
        setBpProducts(products);
      }
    } catch (error) {
      handleGraphApiError(error);
    }
  };
  useEffect(() => {
    if (carrierId) {
      fetchLoadingPlants();
    }
    fetchProducts();
  }, [carrierId]);

  return (
    <Container data-testid="create-open-order-container">
      <NetworkSpeedChecker />
      <Row>
        <Col className="p-0">
          <Link
            className="link"
            to="/landing-page"
            onClick={() => createOpenOrderBackClickTracker(pageTitleConstant.createOpenOrder)}
          >
            <BackButton />
          </Link>
        </Col>
      </Row>

      <Formik
        initialValues={getInitialValues()}
        validationSchema={CreateOpenOrderSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
          isValid,
          dirty,
          values,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit} data-testid="open-order-form">
            <Row>
              <Col>
                <Row>
                  <Col className="mt-6">
                    <h4 className="mb-4">Create an open order</h4>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Label className="online-mode-help mb-8 font-14">You need to be online to complete this step.</Label>
                  </Col>
                </Row>

                { /* 'input-colour' - this css class is used globally for all input fields which is
                  defined in _enterDropDetails.scss */}
                <Row>
                  <Col xs="12" md="6">
                    {/* Field: ReferenceNo */}
                    <FormGroup className="mb-8">
                      <Label for="ReferenceNo">
                        TAS Reference/BOL Number/Load ID:
                      </Label>
                      <InputGroup>
                        <Input
                          name="ReferenceNo"
                          data-testid="ReferenceNo-field"
                          value={values.ReferenceNo}
                          onChange={(e) => {
                            handleChange(e);
                            setShipmentExists(false);
                          }}
                          onBlur={handleBlur}
                          invalid={Boolean(errors.ReferenceNo)}
                          autoComplete="off"
                          type="tel"
                          className="input-colour"
                        />
                      </InputGroup>
                      {
                        errors.ReferenceNo
                          ? <FormText color="error">{errors.ReferenceNo}</FormText>
                          : <FormText color="help">Enter any one of the IDs available on the Bill of Lading.</FormText>
                      }
                    </FormGroup>
                  </Col>

                  {/* Field: LoadingPlant */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-8">
                      <Label for="LoadingPlant">
                        Loading Plant Name:
                      </Label>
                      <InputGroup className="input-colour">
                        <CustomInput
                          type="select"
                          name="LoadingPlant"
                          id="LoadingPlant-select"
                          data-testid="LoadingPlant-select"
                          value={values.LoadingPlant}
                          onChange={(e) => {
                            handleChange(e);
                            setShipmentExists(false);
                          }}
                          onBlur={handleBlur}
                          invalid={Boolean(errors.LoadingPlant) && touched.LoadingPlant}
                          bsSize="lg"
                          disabled={loadingPlants <= 1}
                        >
                          <option key="" value="" hidden>Select an option...</option>
                          {
                            loadingPlants.map(
                              (plant) => (
                                <option
                                  key={plant.PlantID}
                                  value={plant.PlantID}
                                  data-testid={`LoadingPlant-option-${plant.PlantID}`}
                                >
                                  {plant.PlantName}
                                </option>
                              ),
                            )
                          }

                        </CustomInput>
                      </InputGroup>
                      {
                        Boolean(errors.LoadingPlant) && touched.LoadingPlant
                          && <FormText color="error">{errors.LoadingPlant}</FormText>
                      }
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6">
                    {/* Field: Vehicle */}
                    <FormGroup className="mb-8">
                      <Label for="Vehicle">
                        Vehicle:
                      </Label>
                      <InputGroup>
                        <Input
                          name="Vehicle"
                          data-testid="Vehicle-field"
                          value={values.Vehicle}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={Boolean(errors.Vehicle) && touched.Vehicle}
                          autoComplete="off"
                          className="input-colour"
                        />
                      </InputGroup>
                      {
                        Boolean(errors.Vehicle) && touched.Vehicle
                          ? <FormText color="error">{errors.Vehicle}</FormText>
                          : <FormText color="help">Enter the vehicle number as listed on the Bill of Lading.</FormText>
                      }
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6">
                    {/* Field: Photo */}
                    <FormGroup className="mb-8">
                      <Label for="Photo">
                        Add a photo of the Bill of Lading:
                      </Label>

                      {
                        fileObj
                          ? (
                            <Row>
                              <Col xs="12" className="d-flex justify-content-center">
                                <div className="photo-preview-container" data-testid="photo-preview-container">
                                  <img src={dataUri} alt="photoPreview" />
                                </div>
                              </Col>

                              <Col xs="12" className="mt-6 d-flex justify-content-center">

                                <Label
                                  role="button"
                                  className="green-link"
                                  data-testid="retake-link"
                                  onClick={() => {
                                    setFieldValue('Photo', '');
                                    setFileObj(undefined);
                                    takePhotoClickTracker(pageTitleConstant.createOpenOrder);
                                  }}
                                >
                                  Retake photo
                                </Label>
                              </Col>
                            </Row>
                          )
                          : (
                            <>
                              <Row>
                                <Col className="mt-3 mt-md-0">
                                  <Label
                                    for="file-upload-input"
                                    htmlFor="file-upload-input"
                                    className="btn btn-secondary secondary-button custom-file-upload"
                                  >
                                    Take photo
                                  </Label>

                                  <InputGroup>
                                    <Input
                                      name="Photo"
                                      data-testid="Photo-field"
                                      value={values.Photo}
                                      onChange={(e) => {
                                        handleChange(e);
                                        handlePhotoCapture(e);
                                      }}
                                      onBlur={handleBlur}
                                      invalid={Boolean(errors.Photo) && touched.Photo}
                                      id="file-upload-input"
                                      type="file"
                                      accept="image/jpeg"
                                      capture="environment"
                                    />
                                  </InputGroup>
                                </Col>
                              </Row>

                              {/* Instructions */}
                              <Row className="ml-0 mr-0 mt-3">
                                <Col xs="6" md="12" className="m-0 mt-4 d-flex align-items-start pl-md-0 pr-md-0">
                                  <span>
                                    <Info32 className="info-icon-open-order" />
                                  </span>
                                  <span className="example-text icon-level">
                                    This is mandatory. Please take a clear photo of the
                                    Bill of Lading.
                                  </span>
                                </Col>

                                <Col xs="6" md="12" className="mt-4 d-flex justify-content-center">
                                  <div className="d-flex flex-column">
                                    <div>
                                      <ArrowUp className="bol-arrow-top" />
                                      <img
                                        className="bol-graphic"
                                        alt="Bill of Lading Example Graphic"
                                        src={BillOfLadingExample}
                                      />
                                    </div>

                                    <div className="bol-arrow-right">
                                      <ArrowRight />
                                    </div>

                                  </div>

                                </Col>
                              </Row>
                            </>
                          )
                      }
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  {
                    shipmentExists && (
                      <Col xs="12">
                        <Alert
                          key="0"
                          color="warning"
                          className="mb-6 existing-shipment-alert"
                          data-testid="existing-shipment-alert"
                        >
                          <Alert24 className="existing-shipment-alert-icon" />
                          <span>
                            <p className="mb-5 arial font-13 line-height-20">
                              This open order already exists.
                              Please double-check that you entered the reference number correctly
                              and selected the right plant name.
                            </p>
                            <p className="arial font-13 line-height-20">
                              If you still want to proceed with this existing open order then please
                              go to the look up screen to search for it.
                            </p>
                          </span>

                        </Alert>
                      </Col>
                    )
                  }

                  <Col xs="12" className="mb-4 font-12 d-flex justify-content-center status-msg-container">
                    <Label className="mb-0">{status}</Label>
                  </Col>

                  <Col xs="12">
                    <PrimaryButton
                      className="continue-button"
                      data-testid="continue-button"
                      type="Submit"
                      label="Continue"
                      loading={isSubmitting}
                      disabled={shipmentExists || isSubmitting || !isValid || !dirty || !fileObj}
                      onClick={
                        () => createOpenOrderContinueClickTracker(pageTitleConstant.createOpenOrder)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

/**
 * We use oneOfType because
 * the application uses AppSyncClient
 * the tests/jest provide ApolloClient as we use the <MockProvider/>
 */
CreateOpenOrder.propTypes = {
  graphClient: PropTypes.oneOfType([
    PropTypes.instanceOf(AWSAppSyncClient).isRequired,
    PropTypes.instanceOf(ApolloClient).isRequired,
  ]).isRequired,
};

// withClient will provide the client in props
export default withClient(CreateOpenOrder);
