import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.scss';
import { ApolloConsumer, ApolloProvider } from 'react-apollo';
import { Rehydrated } from 'aws-appsync-react'; // this needs to also be installed when working with React
import GlobalState, { AppProvider } from './state/GlobalState';
import Routes from './routes/Routes';

// Import BP UI component library CSS
import '@bphxd/ds-core-react/src/scss/bp-bootstrap.scss';
import '@bphxd/ds-core-react/src/plugins/_plugins.scss';

// Import the aws appsync client - with AWS AppSync configurations
import { appSyncClient } from './api/client';
import { msalConfig } from './auth/msal-config';

// Configuration object constructed.
const config = msalConfig;

// create PublicClientApplication instance
const publicClientApplication = new PublicClientApplication(config);

const AppWithClient = () => (
  <MsalProvider instance={publicClientApplication}>
    <ApolloProvider client={appSyncClient}>
      <Rehydrated>
        <ApolloConsumer>
          {(client) => (
            <AppProvider graphClient={client}>
              <GlobalState>
                <Router>
                  <Routes />
                </Router>
              </GlobalState>
            </AppProvider>
          )}
        </ApolloConsumer>
      </Rehydrated>
    </ApolloProvider>
  </MsalProvider>
);

const rootElement = document.getElementById('root');
ReactDOM.render(<AppWithClient />, rootElement);
