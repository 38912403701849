export const SignCaptureMode = {
  CUSTOMER: 'CUSTOMER',
  DRIVER: 'DRIVER',
};

export const RedirectMode = {
  FULL: 'FULL',
  PARTIAL: 'PARTIAL',
};

export const deliveryDocketState = {
  signatureOpen: false,
  nextDisabled: true,
  nameInputDisabled: true,
  nameCaptureInput: '',
  captureMode: SignCaptureMode.CUSTOMER,
  canCaptureCustomerSign: true,
  canvasClass: 'sigCanvas',
  clearSignature: 'invisible',
  dropCount: -1,
  shipmentDetails: [],
  deliveryDocket: {
    PlannedStartDate: '',
    ShipmentAddress: {
      ShipToAddressLine1: '',
      ShipToAddressLine2: '',
      ShipToAddressLine3: '',
      ShipToCity: '',
      ShipToName: '',
      ShipToNumber: '',
      ShipToPostCode: '',
    },
    LoadingPlant: '',
    SAPPlantId: '',
    OrderNo: '',
    OrderType: 'OrderType', // Random value to help handle initial state
    Products: [],
    PONumber: '',
    PermanentInstruction: '',
    DeliveryComments: '',
  },
  completeDeliveryOpen: false,
  redirectOpen: false,
  modalLoading: false,
  modalLoadingMessage: '',
  redirectMode: undefined,
  isShippingAddressLoaded: false,
  toggleState: false,
  geoTag: '--',
  geoNextBtn: true,
  geoWarningMessage: false,
  longitude: '',
  latitude: '',
};

export const DeliveryDocketActions = {
  OPEN_SIGNATURE_MODAL: 'OPEN_SIGNATURE_MODAL',
  CLOSE_SIGNATURE_MODAL: 'CLOSE_SIGNATURE_MODAL',
  CLEAR_SIGNATURE: 'CLEAR_SIGNATURE',
  START_SIGN: 'START_SIGN',
  SET_CUSTOMER_TEXT: 'SET_CUSTOMER_TEXT',
  DISABLE_CUSTOMER_SIGN: 'DISABLE_CUSTOMER_SIGN',
  ENABLE_CUSTOMER_SIGN: 'ENABLE_CUSTOMER_SIGN',
  SET_SHIPMENT_DETAILS: 'SET_SHIPMENT_DETAILS',
  CAPTURE_DRIVER_SIGNATURE: 'CAPTURE_DRIVER_SIGNATURE',
  UPDATE_DROP_COUNT: 'UPDATE_DROP_COUNT',
  OPEN_DELIVERY_COMPLETE_MODAL: 'OPEN_DELIVERY_COMPLETE_MODAL',
  CLOSE_DELIVERY_COMPLETE_MODAL: 'CLOSE_DELIVERY_COMPLETE_MODAL',
  CLOSE_REDIRECT_MODAL: 'CLOSE_REDIRECT_MODAL',
  SET_DELIVERED_STATUS: 'SET_DELIVERED_STATUS',
  SET_REDIRECT_STATUS: 'SET_REDIRECT_STATUS',
  SET_SIGPAD_REF: 'SET_SIGPAD_REF',
  FULL_REDIRECT: 'FULL_REDIRECT',
  PARTIAL_REDIRECT: 'PARTIAL_REDIRECT',
  MODAL_LOADING_TRUE: 'MODAL_LOADING_TRUE',
  MODAL_LOADING_FALSE: 'MODAL_LOADING_FALSE',
  COMPLETE_REDIRECT_REASON_CAPTURE: 'COMPLETE_REDIRECT_REASON_CAPTURE',
  UPDATE_MODAL_LOADING_MESSAGE: 'UPDATE_MODAL_LOADING_MESSAGE',
  REDIRECT_PARTIAL_REDIRECT: 'REDIRECT_PARTIAL_REDIRECT',
  LOADING_SHIPMENT_ADDRESS: 'LOADING_SHIPMENT_ADDRESS',
  // to prevent double click error when clicking Next button in collecting signature
  DISABLE_DOUBLECLICK_MODAL: 'DISABLE_DOUBLECLICK_MODAL',
  SET_GEO_TAG: 'SET_GEO_TAG',
  SET_GEO_TAG_WARNING: 'SET_GEO_TAG_WARNING',
};

export const deliverDocketReducer = (state, action) => {
  switch (action.type) {
    // to prevent double click error when clicking Next button in collecting signature
    case DeliveryDocketActions.DISABLE_DOUBLECLICK_MODAL: {
      const canvas = action.payload;
      canvas.on();
      canvas.clear();
      return {
        ...state,
        nextDisabled: true,
      };
    }

    case DeliveryDocketActions.OPEN_SIGNATURE_MODAL:
      return {
        ...state,
        signatureOpen: true,
      };
    case DeliveryDocketActions.CAPTURE_DRIVER_SIGNATURE: {
      const { driverName } = action.payload;
      return {
        ...state,
        nameCaptureInput: driverName,
        nameInputDisabled: true,
        captureMode: SignCaptureMode.DRIVER,
        nextDisabled: true,
        canvasClass: 'sigCanvas',
        toggleState: false,
      };
    }
    case DeliveryDocketActions.CLOSE_SIGNATURE_MODAL:
      return {
        ...state,
        signatureOpen: false,
        nameCaptureInput: '',
        canvasClass: 'sigCanvas',
        nextDisabled: true,
        nameInputDisabled: true,
        captureMode: SignCaptureMode.CUSTOMER,
        toggleState: false,
        geoTag: '--',
        nextGeoBtn: true,
        geoWarningMessage: false,
      };
    case DeliveryDocketActions.CLEAR_SIGNATURE: {
      const canvas = action.payload;
      canvas.clear();
      const nameInputValue = state.captureMode === SignCaptureMode.DRIVER ? state.nameCaptureInput : '';
      return {
        ...state,
        nextDisabled: true,
        nameInputDisabled: true,
        nameCaptureInput: nameInputValue,
        clearSignature: 'invisible',
      };
    }
    case DeliveryDocketActions.START_SIGN: {
      const enableNameInput = state.captureMode === SignCaptureMode.CUSTOMER;
      return {
        ...state,
        nextDisabled: false,
        nameInputDisabled: !enableNameInput,
        clearSignature: 'visible',
      };
    }
    case DeliveryDocketActions.SET_CUSTOMER_TEXT:
      return {
        ...state,
        nameCaptureInput: action.payload,
      };
    case DeliveryDocketActions.DISABLE_CUSTOMER_SIGN: {
      return {
        ...state,
        nameCaptureInput: '',
        nameInputDisabled: true,
        canvasClass: 'sigCanvas-disabled',
        nextDisabled: false,
        canCaptureCustomerSign: false,
        clearSignature: 'invisible',
        toggleState: false,
      };
    }
    case DeliveryDocketActions.ENABLE_CUSTOMER_SIGN: {
      const canvas = action.payload;
      canvas.on();
      return {
        ...state,
        canvasClass: 'sigCanvas',
        nextDisabled: true,
        canCaptureCustomerSign: true,
        toggleState: true,
      };
    }
    case DeliveryDocketActions.LOADING_SHIPMENT_ADDRESS:
      return {
        ...state,
        isShippingAddressLoaded: false,
      };
    case DeliveryDocketActions.SET_SHIPMENT_DETAILS:
      return {
        ...state,
        deliveryDocket: action.payload.currentDeliveryDetail,
        shipmentDetails: action.payload.allDeliveries,
        isShippingAddressLoaded: true,
      };
    case DeliveryDocketActions.UPDATE_DROP_COUNT:
      return {
        ...state,
        dropCount: action.payload,
      };
    case DeliveryDocketActions.OPEN_DELIVERY_COMPLETE_MODAL:
      return {
        ...state,
        completeDeliveryOpen: true,
      };
    case DeliveryDocketActions.CLOSE_DELIVERY_COMPLETE_MODAL:
      return {
        ...state,
        completeDeliveryOpen: false,
      };
    case DeliveryDocketActions.CLOSE_REDIRECT_MODAL:
      return {
        ...state,
        modalLoading: false,
        redirectOpen: false,
      };
    case DeliveryDocketActions.SET_DELIVERED_STATUS:
      return {
        ...state,
        completeDeliveryOpen: false,
      };
    case DeliveryDocketActions.PARTIAL_REDIRECT:
      return {
        ...state,
        completeDeliveryOpen: false,
        redirectOpen: true,
        redirectMode: RedirectMode.PARTIAL,
      };
    case DeliveryDocketActions.SET_SIGPAD_REF:
      return {
        ...state,
        sigPadRef: action.payload,
      };
    case DeliveryDocketActions.FULL_REDIRECT:
      return {
        ...state,
        redirectOpen: true,
        redirectMode: RedirectMode.FULL,
      };
    case DeliveryDocketActions.MODAL_LOADING_TRUE:
      return {
        ...state,
        modalLoading: true,
      };
    case DeliveryDocketActions.MODAL_LOADING_FALSE:
      return {
        ...state,
        modalLoading: false,
      };
    case DeliveryDocketActions.COMPLETE_REDIRECT_REASON_CAPTURE:
      return {
        ...state,
        redirectOpen: false,
        deliveryDocket: action.payload,
        modalLoading: false,
      };
    case DeliveryDocketActions.UPDATE_MODAL_LOADING_MESSAGE:
      return {
        ...state,
        modalLoadingMessage: action.payload,
      };
    case DeliveryDocketActions.REDIRECT_PARTIAL_REDIRECT:
      return {
        ...state,
        redirectMode: RedirectMode.PARTIAL,
      };
    case DeliveryDocketActions.SET_GEO_TAG: {
      const {
        latitude, longitude, geoTag, geoNextBtn,
      } = action.payload;
      return {
        ...state,
        latitude,
        longitude,
        geoTag,
        geoNextBtn,
        geoWarningMessage: false,
      };
    }
    case DeliveryDocketActions.SET_GEO_TAG_WARNING:
      return {
        ...state,
        geoWarningMessage: true,
      };
    default:
      return state;
  }
};
