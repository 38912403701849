import React from 'react';
import {
  Col, Row, Label, Modal, ModalBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Remove32 } from '@bphxd/ds-core-react/icons';

const SupportInformationModal = ({
  isOpen,
  title,
  paraOne,
  stressedWord,
  restContent,
  paraTwo,
  handleClose,
}) => (
  <Modal isOpen={isOpen} className="support-info-modal pt-13" data-testid="support-info-modal">
    <ModalBody>
      <Row className="justify-content-end pr-4">
        <Remove32 onClick={() => { handleClose(); }} data-testid="support-info-modal-close" />
      </Row>
      <Row className="pt-4">
        <Col xs={{ size: 10, offset: 1 }}>
          <Row>
            <Col>
              <Col>
                <Label
                  className="mb-4 univers font-16 lightweight text-left confirm-modal-title"
                  data-testid="support-info-modal-title"
                >
                  {title}
                </Label>
              </Col>
              <Col sm="12" className="arial mb-4 d-flex font-12 text-left">
                <Label className="arial" data-testid="support-info-modal-content">
                  {paraOne}
                  <span className="font-weight-bold">
                    {stressedWord}
                  </span>
                  {restContent}
                </Label>
              </Col>
              <Col sm="12" className="arial mb-4 d-flex font-12 text-left">
                {paraTwo}
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </ModalBody>
  </Modal>
);

SupportInformationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  paraOne: PropTypes.string,
  stressedWord: PropTypes.string,
  restContent: PropTypes.string,
  paraTwo: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};

export default SupportInformationModal;
