import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../state/GlobalState';
import './_sideBar.scss';
import { isSmallDevice } from '../../theme/mediaHelpers';
import { homeClickHandler, signOutClickHandlerGA } from '../../analytics/events';
import pageTitleConstant from '../../analytics/constantPageTitle';

const SideBar = () => {
  const { instance } = useMsal();
  const { menuOpen } = useAppContext();
  // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider

  const signOutClickHandler = () => {
    // Will not work as there is a bug in signout
    signOutClickHandlerGA(pageTitleConstant.menu);
    instance.logout();
  };

  return (
    <div data-testid="sidebar" className={`sidebar ${menuOpen && (isSmallDevice() ? 'sidebar-mobile' : 'sidebar-tablet')}`}>
      <ul className="m-0 p-0">
        <li className="p-4 mt-2">
          <Link to="/landing-page">
            <button className="sidebar-link universe font-14" type="button" onClick={() => homeClickHandler(pageTitleConstant.menu)}>Home</button>
          </Link>
        </li>
        <li className="p-4 mt-2">
          <button type="button" className="sidebar-link universe font-14" onClick={() => signOutClickHandler(pageTitleConstant.menu)}>
            Sign Out
          </button>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
