import React from 'react';
import {
  Col, Row, Label, Modal, ModalBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SecondaryButton from '../SecondaryButton/SecondaryButton';

const RedirectCheckModal = ({
  isOpen,
  onRedirectHandler,
  onCloseHandler,
  loadingFeedback,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} className="redirect-check-modal" data-testid="redirect-check-modal" centered>
      <ModalBody>
        <Row className="pt-12 pb-12">
          <Col xs={{ size: 10, offset: 1 }}>
            <Row>
              <Col xs={{ size: 10, offset: 1 }}>
                <Label className="mb-8 roboto font-30 lightweight text-center redirect-check-modal-title" data-testid="redirect-check-modal-title">
                  Would you like to redirect this delivery docket?
                </Label>
              </Col>
            </Row>

            <Row>
              <Col className="pl-0">
                <PrimaryButton label="Yes, redirect it" onClick={onRedirectHandler} data-testid="redirect-btn" />
              </Col>

              <Col className="pr-0">
                <SecondaryButton label="Cancel" onClick={onCloseHandler} data-testid="cancel-btn" />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mb-2">
          <Label className="univers font-14 text-center">{loadingFeedback}</Label>
        </Row>
      </ModalBody>
    </Modal>
  );
};

RedirectCheckModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRedirectHandler: PropTypes.func.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  loadingFeedback: PropTypes.string,
};

export default RedirectCheckModal;
