import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import DefaultLayout from '../layouts/Default/DefaultLayout';
import TimeoutLayout from '../layouts/Timeout/TimeoutLayout';
import FindShipment from '../pages/Shipment/FindShipment';
import ShipmentDetails from '../pages/ShipmentDetails/ShipmentDetails';
import DriverPage from '../pages/Driver/Driver';
import DeliveryDocket from '../pages/DeliveryDocket/DeliveryDocket';
import PrivateRoute from './PrivateRoute';
import EnterDropDetails from '../pages/EnterDropDetails/EnterDropDetails';
import WelcomePage from '../pages/WelcomePage/WelcomePage';
import WelcomeLayout from '../layouts/WelcomeLayout/WelcomeLayout';
import DataCollection from '../pages/FooterPages/DataCollection/DataCollection';
import DropDetailsPage from '../pages/DropDetails/DropDetails';
import EditDropDetails from '../pages/EditDropDetails/EditDropDetails';
import CreateOpenOrder from '../pages/CreateOpenOrder/CreateOpenOrder';
import AddShipTo from '../pages/AddShipTo/AddShipTo';
import LandingPage from '../pages/LandingPage/LandingPage';
import TimeoutPage from '../pages/TimeoutPage/TimeoutPage';
import analytics from '../analytics/gtmEventsView';
import FindOpenOrder from '../pages/Shipment/FindOpenOrder';

const useAnalytics = () => {
  const location = useLocation();

  React.useEffect(() => {
    analytics.init();
  }, []);

  React.useEffect(() => {
    const currentPath = location.pathname + location.search;
    document.title = analytics.getPageTitle(currentPath);
  }, [location]);
};

const Routes = () => {
  useAnalytics();
  return (
    <Switch>
      <Route exact path="/">
        <WelcomeLayout>
          <WelcomePage />
        </WelcomeLayout>
      </Route>
      <Route path="/timeout">
        <TimeoutLayout>
          <TimeoutPage />
        </TimeoutLayout>
      </Route>
      <PrivateRoute path="/driver" component={DriverPage} layout={DefaultLayout} />
      <PrivateRoute path="/landing-page" component={LandingPage} layout={DefaultLayout} />
      <PrivateRoute path="/shipment" component={FindShipment} layout={DefaultLayout} />
      <PrivateRoute path="/find-open-order" component={FindOpenOrder} layout={DefaultLayout} />
      <PrivateRoute path="/shipment-details" component={ShipmentDetails} layout={DefaultLayout} />
      <PrivateRoute path="/delivery-docket" component={DeliveryDocket} layout={DefaultLayout} />
      <PrivateRoute path="/enter-drop-details" component={EnterDropDetails} layout={DefaultLayout} />
      <PrivateRoute path="/edit-drop-details" component={EditDropDetails} layout={DefaultLayout} />
      <PrivateRoute path="/drop-details" component={DropDetailsPage} layout={DefaultLayout} />
      <PrivateRoute path="/data-collection" component={DataCollection} layout={DefaultLayout} />
      <PrivateRoute path="/add-ship-to" component={AddShipTo} layout={DefaultLayout} />
      <PrivateRoute path="/open-order" component={CreateOpenOrder} layout={DefaultLayout} />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
