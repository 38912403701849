import React from 'react';
import {
  Container, Row, Col, Label,
} from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import './_shipmentAddress.scss';
import { Link } from 'react-router-dom';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import { DeliveryDocketActions } from '../../pages/DeliveryDocket/DeliveryDocketReducer';
import {
  isDocketShipmentAddressInValid, ORIGINAL, REDIRECT, skHasValue, isUnmappedOpenOrder,
} from '../../helpers/shipmentEngine';
import { isDocketStatusDelivered } from '../../helpers/shipmentStatus';
import { useAppContext } from '../../state/GlobalState';
import { fullRedirectClickHandler, updateDestinationButtonClickHandler } from '../../analytics/events';
import pageTitleConstant from '../../analytics/constantPageTitle';

const ShipmentAddress = ({
  address,
  dispatch,
  docketDetails,
  dropCount,
  isLoaded = true,
}) => {
  const {
    ShipToName,
    ShipToNumber,
    ShipToAddressLine1,
    ShipToAddressLine2,
    ShipToAddressLine3,
    ShipToCity,
    ShipToPostCode,
  } = address || {};

  const {
    setNewShipToAddress,
  } = useAppContext();

  const addressLine4 = [];
  if (ShipToCity && ShipToCity !== '') {
    addressLine4.push(ShipToCity);
  }

  if (ShipToPostCode && ShipToPostCode !== '') {
    addressLine4.push(ShipToPostCode);
  }

  const handleUpdateDestination = () => {
    setNewShipToAddress(address);

    updateDestinationButtonClickHandler();
  };

  // Show the full redirect only when the shipment is original and there are no drops
  const showFullRedirect = !isUnmappedOpenOrder(docketDetails)
    && skHasValue(docketDetails, ORIGINAL)
    && dropCount === 0;

  // Show update destination if the status is redirect and no shipment
  // Or if the order type is U (Unmapped open order) and ORIGINAL with no drops
  const showUpdateDestination = (
    (skHasValue(docketDetails, REDIRECT) && dropCount < 1)
    || (isUnmappedOpenOrder(docketDetails) && skHasValue(docketDetails, ORIGINAL) && dropCount < 1)
    || isDocketShipmentAddressInValid(docketDetails)
  )
  && !isDocketStatusDelivered(docketDetails);

  return (
    <Container data-testid="address-container">
      <Row className="mb-4">
        <Col className="ship-to rounded flex-column">
          <Row>
            <Col>
              <Label className="univers font-weight-light pt-4" data-testid="tile-title">Ship To:</Label>
            </Col>
          </Row>
          {isLoaded && (
          <Row data-testid="address-content" className="mb-3">
            <Col>
              <Label className="univers font-13 font-weight-bold text-uppercase" data-testid="ship-to-details-name">{ShipToName}</Label>

              { ShipToNumber
                && (
                <Label className="univers font-13 d-block" data-testid="ship-to-number">
                  {ShipToNumber}
                </Label>
                ) }

              { ShipToAddressLine1
                && <Label className="univers font-13 d-block" data-testid="ship-to-address1">{ShipToAddressLine1}</Label> }

              { ShipToAddressLine2
                && <Label className="univers font-13 d-block" data-testid="ship-to-address2">{ShipToAddressLine2}</Label> }

              { ShipToAddressLine3
                && <Label className="univers font-13 d-block" data-testid="ship-to-address3">{ShipToAddressLine3}</Label> }

              { (addressLine4.length > 0)
                && <Label className="univers font-13 d-block" data-testid="ship-to-last-line">{addressLine4.join(', ')}</Label> }

            </Col>
          </Row>
          ) }
          {!isLoaded && (
            <Row data-testid="loading-content" className="mb-3">
              <Col>
                <Skeleton height="20%" width="90%" count={1} />
                <Skeleton height="20%" width="70%" count={1} />
                <Skeleton height="20%" width="50%" count={1} />
              </Col>
            </Row>
          ) }
          {showUpdateDestination && isLoaded && (
          <Row className="mb-4 align-items-center justify-content-center">
            <Col>
              <Link className="link" to="/add-ship-to">
                <SecondaryButton
                  className="univers"
                  data-testid="update-destination-button"
                  label="Update destination"
                  onClick={handleUpdateDestination}
                />
              </Link>
            </Col>
          </Row>
          )}

          { showFullRedirect && (
          <Row>
            <Col>
              <SecondaryButton
                className="univers mb-5"
                data-testid="submit-button-redirect"
                type="Submit"
                label="Full Redirect"
                onClick={() => dispatch({
                  type: DeliveryDocketActions.FULL_REDIRECT,
                }) || fullRedirectClickHandler(pageTitleConstant.deliveryDocketPage)}
              />
            </Col>
          </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

ShipmentAddress.propTypes = {
  address: PropTypes.shape({
    ShipToName: PropTypes.string.isRequired,
    ShipToNumber: PropTypes.string,
    ShipToAddressLine1: PropTypes.string,
    ShipToAddressLine2: PropTypes.string,
    ShipToAddressLine3: PropTypes.string,
    ShipToCity: PropTypes.string,
    ShipToPostCode: PropTypes.string,
  }), // Not required because redirects and open orders intially don't have the address
  dispatch: PropTypes.func.isRequired,
  docketDetails: PropTypes.object.isRequired,
  dropCount: PropTypes.number,
  isLoaded: PropTypes.bool,
};

export default ShipmentAddress;
