import React from 'react';
import './_NetworkSpeedChecker.scss';
import { Info32 } from '@bphxd/ds-core-react/icons';
import { Row, Label } from 'reactstrap';
import { readNetworkSpeedToken } from '../../helpers/localStorage';
import { isMobileDevice } from '../../theme/mediaHelpers';

const NetworkSpeedChecker = () => {
  const networkCheckRequired = process.env.REACT_APP_show_offline_message;
  if (networkCheckRequired === 'true' && readNetworkSpeedToken() > 5000) {
    return (
      <div className="_1alIC">
        <Row className="ml-3 mt-1 mb-1">
          <Info32 style={{ color: '#4FC3F7' }} />
          <Label className="font-16 text-left no-margin" style={isMobileDevice() ? { width: '80%' } : { width: '90%' }}>
            You have entered an area with poor connectivity, so the app may take longer to respond.
            Please be patient or try again when you have better connectivity.
          </Label>
        </Row>
      </div>
    );
  }
  return null;
};

export default NetworkSpeedChecker;
