import React from 'react';
import { ApolloConsumer } from 'react-apollo';

/**
 * Provides your component with graphClient as props.
 * You must add PropTypes to any component that you wrap with this HOC
 * A good example of usage is the DeliveryDocket component.
 * @param {AnyComponent} Component
 * @returns Your component with graphClient
 */
const withClient = (Component) => (props) => (
  <ApolloConsumer>
    {
      (client) => (
        <Component graphClient={client} {...props} />
      )
    }
  </ApolloConsumer>
);

export default withClient;
