import React, { useEffect } from 'react';
import {
  Container, Col, Row, Navbar, NavbarBrand,
} from 'reactstrap';
import './_defaultLayout.scss';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import {
  Hamburger48, Hamburger64, Remove48, Remove64,
} from '@bphxd/ds-core-react/icons';
import SideBar from '../../components/SideBar/SideBar';
import { useAppContext } from '../../state/GlobalState';
import { isSmallDevice } from '../../theme/mediaHelpers';
import analytics from '../../analytics/gtmEventsView';
import { bpeDocketClickTracker } from '../../analytics/events';
import pageTitleConstant from '../../analytics/constantPageTitle';

const CustomNavbar = () => {
  const { menuOpen, setMenuOpen } = useAppContext();
  const location = useLocation();

  useEffect(() => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  }, [location]);

  useEffect(() => {
    if (menuOpen) {
      analytics.sendEvent({
        category: 'event',
        action: 'burger_menu_opened',
        label: 'burger menu opened',
      });
    }
  }, [menuOpen]);

  return (
    <Navbar color="faded" light fixed="top" className="navbar m-0 p-0" data-testid="navbar">
      {
        menuOpen === false ? (
          <div className="hamburger-container">
            <button className="hamburger-button" id="navbar-icon" type="button" onClick={() => setMenuOpen(true)}>
              { isSmallDevice() ? <Hamburger48 /> : <Hamburger64 /> }
            </button>
          </div>
        )
          : (
            <div className="x-button">
              <button id="navbar-icon" type="button" onClick={() => setMenuOpen(false)}>
                { isSmallDevice() ? <Remove48 /> : <Remove64 /> }
              </button>
            </div>
          )
    }

      <NavbarBrand href="/" onClick={() => bpeDocketClickTracker(pageTitleConstant.menu)}>
        <div className="title univers">bp eDocket</div>
      </NavbarBrand>

      <div className="logo-container">
        <div className="navbar-logo" role="img" alt="BP Logo" />
      </div>
    </Navbar>
  );
};

// Hide all links apart for 'Privacy' for Realease 1
const CustomFooter = () => (
  <Row className="footer m-0" data-testid="footer">
    {/* <Col sm="12" md="4" lg="4" className="footer-link pt-lg-4">
      Copyright &copy; BP
      {` ${new Date().getFullYear()}`}
    </Col>
    <Col sm="12" md="4" lg="2" className="footer-link pt-lg-4">Contact</Col>
    <Col sm="12" md="4" lg="2" className="footer-link pt-lg-4">Legal Notice</Col>
    <Col md="4" className="d-none d-md-block d-lg-none" /> */}
    <Col sm="12" md="4" lg="2" className="footer-link pt-lg-4">
      <a href="https://www.bp.com/en_au/australia/home/privacy-statement.html" target="blank" rel="noopener noreferrer">Privacy</a>
    </Col>
    {/* <Col sm="12" md="4" lg="2" className="footer-link pt-lg-4">
      <Link to="/data-collection">Data Collection</Link>
    </Col> */}
  </Row>
);

const DefaultLayout = ({ children }) => {
  const rootRef = React.useRef();
  const location = useLocation();
  useEffect(() => {
    rootRef.current.scrollIntoView();
  }, [location]);
  return (
    <div ref={rootRef}>
      <Container fluid className="layout-container p-0 m-0 h-100" data-testid="layout-container">
        <CustomNavbar />
        <SideBar />

        <Row className="main-content mr-0 ml-0 flex-grow-1" data-testid="main-content">
          <Col
            xs={{ size: 10, offset: 1 }}
            lg={{ size: 8, offset: 2 }}
            xl={{ size: 6, offset: 3 }}
          >
            {children}
          </Col>
        </Row>

        <CustomFooter />
      </Container>
    </div>
  );
};

DefaultLayout.defaultProps = {
  children: PropTypes.node,
};

DefaultLayout.propTypes = {
  children: PropTypes.node,
};

export default DefaultLayout;
