import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Col,
  Row,
  InputGroup,
  Input,
  CustomInput,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  FormText,
} from 'reactstrap';
import { Formik } from 'formik';
import { Link, Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ApolloClient from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import flatten from 'lodash/flatten';
import _ from 'lodash';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import {
  useAppContext, DeliveryContext, DropContext,
} from '../../state/GlobalState';
import {
  DropDetailsSchema, quantityWarningMsg, initialValues, ambientWarningMsg,
} from '../EnterDropDetails/validation';
import PageHeading from '../../components/PageHeading/PageHeading';
import BackButton from '../../components/BackButton/BackButton';
import { extractAttributeFromCollection } from '../../helpers/attributeExtraction';
import { getShipmentDetails } from '../../api/graphQl/Shipment';
import { updateDropDetails, getDeliveryDropDetails } from '../../api/graphQl/DropDetails';
import withClient from '../../hoc/withClient';
import { skHasValue, isUnmappedOpenOrder } from '../../helpers/shipmentEngine';
import {
  editProductClickHandler, editSaveClickHandler, editClearFieldsClickHandler,
  editBackButtonClickHandler,
} from '../../analytics/events';
import LidDetail from '../../components/LidDetail/LidDetail';
import { getShipmentHeader } from '../../helpers/openOrders';
import { statuses } from '../../helpers/shipmentStatus';

const EditDropDetails = ({
  graphClient,
}) => {
  const history = useHistory();
  const {
    carrierId, shipmentId, handleGraphApiError, bpProducts, selectedDocket,
  } = useAppContext();
  const [deliveryId] = useContext(DeliveryContext);

  const [quantityWarning, setQuantityWarning] = useState();
  const [shipmentDetails, setShipmentDetails] = useState([]);
  const [drop] = useContext(DropContext);
  const [selectedProduct, setSelectedProduct] = useState();
  const [dropDetailsAll, setDropDetailsAll] = useState([]);

  const [ambientDisable, setambientDisable] = useState(false);

  const [selectedLoadIdAddon, setSelectedLoadIdAddon] = useState();
  const [resetValue, setResetValue] = useState();
  const selectedShipmentDetail = shipmentDetails.find((sd) => sd.SK.includes(`LID#${selectedLoadIdAddon}`));
  const [calculatedresult, setCalculatedresult] = useState();

  const isOpenOrder = isUnmappedOpenOrder(selectedDocket);

  const getSelectedProducts = () => {
    if (!selectedShipmentDetail) return [];

    if (isOpenOrder) return bpProducts;
    return selectedShipmentDetail.Products;
  };

  // get lid status from its child component - LidDetails
  const [, setLidStatus] = useState(false);
  const sendDataBackToParent = (valueBack) => {
    setLidStatus(valueBack);
  };
  if (!shipmentId || !deliveryId) {
    // If there's no shipment id or delivery id, redirect to find shipment page
    return <Redirect to="/shipment" />;
  }

  const setShipmentDetailsForDelivery = (localShipmentDetails) => {
    const shipmentDetailsForDelivery = localShipmentDetails.filter(
      (sd) => skHasValue(sd, deliveryId),
    );
    setShipmentDetails(shipmentDetailsForDelivery);
  };

  const getProducts = (values) => {
    if (isOpenOrder) return bpProducts;

    if (values.LoadIdAddon) {
      const selectedDetail = shipmentDetails.find((sd) => sd.SK.includes(`LID#${values.LoadIdAddon}`));
      return selectedDetail ? selectedDetail.Products : [];
    }
    return [];
  };

  const getLoadIdAddons = () => (shipmentDetails ? extractAttributeFromCollection(shipmentDetails, 'SK', 'LID#') : []);

  // Calculate total delivered amount
  const calculateTotalDeliverd = (dropDetailsAl) => {
    const map = new Map();
    if (dropDetailsAl) {
      dropDetailsAl.forEach((dropDetail) => {
        if (dropDetail.DROPS.length) {
          dropDetail.DROPS.forEach(
            (dropSelected) => {
              if (map.has(dropDetail.SK.split('#LID#')[1] + dropSelected.Product)) {
                map.set(
                  dropDetail.SK.split('#LID#')[1] + dropSelected.Product, map.get(dropDetail.SK.split('#LID#')[1] + dropSelected.Product) + dropSelected.ActualDischargeQty,
                );
              } else { map.set(dropDetail.SK.split('#LID#')[1] + dropSelected.Product, dropSelected.ActualDischargeQty); }
            },
          );
        }
      });
    }
    return map;
  };

  const calculateSelectedProduct = (dropsDelivered) => {
    if (dropsDelivered) {
      getSelectedProducts().filter((product) => product.Name === selectedProduct)
        .map((product) => (
          calculateTotalDeliverd([...new Set(dropDetailsAll)])
            .get(selectedLoadIdAddon + product.Name)
            ? setCalculatedresult((product.QuantityAmbient
              - calculateTotalDeliverd([...new Set(dropDetailsAll)])
                .get(selectedLoadIdAddon + product.Name) + resetValue).toFixed(2))
            : setCalculatedresult(product.QuantityAmbient)
        ));
    }
    return calculatedresult;
  };

  const ambientValidationCheck = (values) => {
    if (values.DipBefore?.length !== 0
      && values.DipAfter?.length !== 0
      && dropDetailsAll
    ) {
      calculateSelectedProduct([...new Set(dropDetailsAll)]);
      if (
        values.Calculated && values.ActualDischargeQty
        && dropDetailsAll
        && parseInt(values.ActualDischargeQty, 10) > parseInt(calculatedresult, 10)
      ) {
        setQuantityWarning(`${ambientWarningMsg}(${calculatedresult}) .`);
        setambientDisable(true);
        // This is a flag
      } else if (
        values.ActualDischargeQty
          && parseInt(values.Calculated, 10) !== parseInt(values.ActualDischargeQty, 10)
      ) {
        setQuantityWarning(quantityWarningMsg);
        setambientDisable(false);
      } else {
        setQuantityWarning();
        setambientDisable(false);
      }
    }
  };

  const updateCalculatedValue = (setFieldValue, values) => {
    if (values.DipBefore?.length === 0 || values.DipAfter?.length === 0) {
      setFieldValue('Calculated', 0);
      ambientValidationCheck({ Calculated: 0, ...values });
    } else {
      const newCalculated = values.DipAfter - values.DipBefore;
      setFieldValue('Calculated', newCalculated);
      ambientValidationCheck({ ...values, Calculated: newCalculated });
    }
  };

  const getInitialValues = () => Object.assign(drop, { OpenOrder: isOpenOrder });

  const onSubmit = async (values) => {
    const inputValues = {
      // DropId and TASVehicleID values are not editable, they are being taken from the saved record
      DropId: drop.DropId,
      TASVehicleID: drop.TASVehicleID,
      Product: values.Product,
      Tank: values.Tank,
      Capacity: parseInt(values.Capacity, 10),
      Compartments: values.Compartments,
      ActualDischargeQty: parseInt(values.ActualDischargeQty, 10),
      DipAfter: parseInt(values.DipAfter, 10),
      DipBefore: parseInt(values.DipBefore, 10),
      Calculated: parseInt(values.Calculated, 10),
      QuantityAmbient: values.QuantityAmbient,
      QuantityStandard: values.QuantityStandard,
      Aggregate: false,
    };

    // We pass the first element in the ShipmentDetails because use it just to get
    // information like the PK and deliveryId
    await updateDropDetails(graphClient, shipmentDetails[0], values.LoadIdAddon, inputValues);
    history.push('/drop-details');
  };

  const fetchAndSetAllDropDetails = async () => {
    try {
      const shDetails = await getShipmentDetails(graphClient, shipmentId, carrierId);
      if (shDetails?.data?.getShipmentDetails) {
        setShipmentDetailsForDelivery(shDetails.data.getShipmentDetails);
        shDetails.data.getShipmentDetails.forEach(
          async (sd) => {
            // As sd.SK is always in format like: DeliveryNo#ORIGINAL#204077233#LID#820641 or
            // DeliveryNo#REDIRECT#204077232-R1#LID#820641, so:
            // 1. sd.SK.split('#')[2] is used to obatin the Delivery Docket number
            // 2. split('-')[0] is used to extract the Delivery Docket number without '-R'
            if (sd.Status !== statuses.Void && sd.SK.split('#')[2].split('-')[0] === deliveryId.split('-')[0]) {
              const response = await getDeliveryDropDetails(graphClient, sd);
              if (response?.data?.getDropDetails) {
                // We have to set the state here because drop detail fetch is async
                setDropDetailsAll(
                  (oldArray) => flatten([...oldArray, response.data.getDropDetails]),
                );
              }
            }
          },
        );
      }
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  const resetEditDropDetailsCard = (allDrops) => {
    const allDropsAfterReset = _.cloneDeep(allDrops);
    allDropsAfterReset.forEach((findDropToRest) => {
      const index = findDropToRest.DROPS.findIndex((eachDrop) => eachDrop.DropId === drop.DropId);
      if (index !== -1) {
        findDropToRest.DROPS.splice(index, 1);
      }
    });
    return allDropsAfterReset;
  };

  useEffect(() => {
    setSelectedLoadIdAddon(drop.LoadIdAddon);
    setSelectedProduct(drop.Product);
    setResetValue(parseInt(drop.ActualDischargeQty, 10));
    const getDataForComponent = async () => {
      if (drop.DipBefore.toString().length === 0) {
        drop.DipBefore = 0;
      }
    };
    getDataForComponent()
      .catch((error) => handleGraphApiError(error));
    fetchAndSetAllDropDetails();
  }, [deliveryId, drop]);

  return (
    <Container className="content pl-0 pr-0" data-testid="enter-drop-details-container">
      <Link className="link" to="/drop-details" onClick={editBackButtonClickHandler}>
        <BackButton />
      </Link>

      <PageHeading label="Delivery docket number" refNumber={getShipmentHeader(deliveryId)} />

      <Formik
        initialValues={getInitialValues()}
        validationSchema={DropDetailsSchema}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
          values,
          errors,
          touched,
          resetForm,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit} data-testid="enter-drop-details-form">
            <Row>
              <Col>
                {!isOpenOrder && (
                  <Row>
                    <LidDetail
                      shipmentDetails={shipmentDetails}
                      dropDetailsAll={resetEditDropDetailsCard([...new Set(dropDetailsAll)])}
                      sendDataBackToParent={sendDataBackToParent}
                    />
                  </Row>
                )}

                <Row className="mb-8">
                  <Col xs="12">
                    <div className="drop-details-form-title"><h5>Enter drop details</h5></div>
                  </Col>

                  <Col xs="12">
                    <div className="drop-details-form-subtitle">Enter details for each drop, one at a time</div>
                  </Col>
                </Row>

                {/* 'input-colour' - this css class is used globally for all input fields which is
                  defined in _enterDropDetails.scss */}
                <Row>
                  { !isOpenOrder && (
                    /* Field: LoadIdAddon */
                    <Col xs="12" md="6">
                      <FormGroup className="mb-10 mb-md-8">
                        <Label for="LoadIdAddon">Load ID Addon:</Label>
                        <InputGroup className="input-colour">
                          <CustomInput
                            type="select"
                            name="LoadIdAddon"
                            id="LoadIdAddon-select"
                            data-testid="LoadIdAddon-select"
                            value={values.LoadIdAddon}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue('Product', '');
                            }}
                            onBlur={handleBlur}
                            invalid={Boolean(errors.LoadIdAddon) && touched.LoadIdAddon}
                            bsSize="lg"
                            disabled
                            aria-label="LoadIdAddon"
                          >
                            <option key="" value="" hidden>Select an option...</option>
                            {
                            getLoadIdAddons().map(
                              (addon) => (
                                <option
                                  key={addon}
                                  value={addon}
                                  data-testid={`LoadIdAddon-option-${addon}`}
                                >
                                  {addon}
                                </option>
                              ),
                            )
                          }

                          </CustomInput>

                          <FormFeedback type="invalid" data-testid="LoadIdAddon-feedback">{errors.LoadIdAddon}</FormFeedback>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  )}

                  <Col>
                    <Label for="TASVehicleID" className="vehicle-row mb-10 mt-md-8 mb-md-8">Vehicle:&nbsp;</Label>
                    <Label
                      name="TASVehicleID"
                      data-testid="TASVehicleID"
                      className="vehicle-no"
                      value={drop.TASVehicleID}
                    >
                      <b>{drop.TASVehicleID}</b>

                    </Label>
                  </Col>

                  <Col xs="12">
                    <div className="tablet-divider" />
                  </Col>
                </Row>

                <Row>
                  {/* Field: Tank */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="Tank">
                        Tank Number / Name -
                        {' '}
                        <span className="optional">optional</span>
                        :
                      </Label>
                      <InputGroup className="input-colour">
                        <Input
                          type="text"
                          name="Tank"
                          data-testid="Tank-field"
                          value={values.Tank}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={Boolean(errors.Tank) && touched.Tank}
                          autoComplete="off"
                          aria-label="Tank"
                        />

                        <FormFeedback type="invalid" data-testid="Tank-feedback">{errors.Tank}</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  {/* Field: Product */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="product">Product:</Label>
                      <InputGroup className="input-colour">
                        <CustomInput
                          type="select"
                          name="Product"
                          id="Product-select"
                          data-testid="Product-select"
                          value={values.Product}
                          onChange={(e) => {
                            handleChange(e);
                            setSelectedProduct(e.target.value);
                          }}
                          onBlur={handleBlur}
                          invalid={Boolean(errors.Product) && touched.Product}
                          bsSize="lg"
                          disabled={!isOpenOrder}
                          aria-label="Product"
                          onClick={editProductClickHandler}
                        >
                          <option key="" value="" hidden>Select an option...</option>
                          {
                            getProducts(values).map(
                              (product) => (
                                <option
                                  key={product.Name}
                                  value={product.Name}
                                  data-testid={`Product-option-${product.Name}`}
                                >
                                  {product.Name}
                                </option>
                              ),
                            )
                          }
                        </CustomInput>
                        <FormFeedback type="invalid" data-testid="Product-feedback">{errors.Product}</FormFeedback>
                      </InputGroup>

                      {
                        !isOpenOrder && (
                          <FormText color="help" data-testid="qty-ambient" className="d-inline">Qty Ambient: </FormText>
                        )
}

                      {
                        !isOpenOrder && (
                          getSelectedProducts()
                            .filter((product) => product.Name === selectedProduct)
                            .map((product) => (
                              <FormText key={product} color="help" className="qty-ambient d-inline">
                                {
                                  calculateTotalDeliverd([...new Set(dropDetailsAll)])
                                    .get(selectedLoadIdAddon + product.Name)
                                    ? (product.QuantityAmbient
                                      - calculateTotalDeliverd([...new Set(dropDetailsAll)])
                                        .get(selectedLoadIdAddon
                                           + product.Name) + resetValue).toFixed(2)
                                    : product.QuantityAmbient
                                }
                              </FormText>
                            ))
                        )
                      }
                    </FormGroup>
                  </Col>

                  {
                    isOpenOrder && (
                      /* Field: QuantityAmbient */
                      <Col xs="12" md="6">
                        <FormGroup className="mb-10">
                          <Label for="QuantityAmbient">
                            Quantity Ambient:
                          </Label>
                          <InputGroup className="input-colour">
                            <Input
                              type="tel"
                              name="QuantityAmbient"
                              data-testid="QuantityAmbient-field"
                              value={values.QuantityAmbient}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={Boolean(errors.QuantityAmbient) && touched.QuantityAmbient}
                              autoComplete="off"
                            />
                          </InputGroup>
                          {
                            Boolean(errors.QuantityAmbient) && touched.QuantityAmbient
                              ? <FormText color="error" data-testid="QuantityAmbient-feedback">{errors.QuantityAmbient}</FormText>
                              : (
                                <FormText color="help" data-testid="QuantityAmbient-info">
                                  Enter the qty. ambient value of the product
                                  as mentioned in the Bill of Lading.
                                </FormText>
                              )
                          }
                        </FormGroup>
                      </Col>
                    )
                  }
                  {
                    isOpenOrder && (
                      /* Field: QuantityStandard */
                      <Col xs="12" md="6">
                        <FormGroup className="mb-10">
                          <Label for="QuantityStandard">
                            Quantity Standard (15˚C):
                          </Label>
                          <InputGroup className="input-colour">
                            <Input
                              type="tel"
                              name="QuantityStandard"
                              data-testid="QuantityStandard-field"
                              value={values.QuantityStandard}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={Boolean(errors.QuantityStandard) && touched.QuantityStandard}
                              autoComplete="off"
                            />
                          </InputGroup>
                          {
                            Boolean(errors.QuantityStandard) && touched.QuantityStandard
                              ? <FormText color="error" data-testid="QuantityStandard-feedback">{errors.QuantityStandard}</FormText>
                              : (
                                <FormText color="help" data-testid="QuantityStandard-info">
                                  Enter the qty. standard value of the product
                                  as mentioned in the Bill of Lading.
                                </FormText>
                              )
                          }
                        </FormGroup>
                      </Col>
                    )
                  }

                  {/* Field: Capacity */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="Capacity">
                        Capacity (SFL)
                      </Label>
                      <InputGroup className="input-colour">
                        <Input
                          type="number"
                          name="Capacity"
                          data-testid="Capacity-field"
                          value={values.Capacity}
                          onChange={handleChange}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value) {
                              e.target.value = parseInt(e.target.value.split('.')[0], 10);
                            }
                            handleChange(e); // eslint-disable-next-line
                            touched.Capacity = true;
                          }}
                          invalid={!!errors.Capacity && touched.Capacity}
                          autoComplete="off"
                          aria-label="Capacity"
                        />
                      </InputGroup>
                      {
                        !!errors.Capacity && touched.Capacity
                          ? <FormText color="error" data-testid="Capacity-feedback">{errors.Capacity}</FormText>
                          : <FormText color="help" data-testid="Capacity-info">Only whole numbers are accepted for this field.</FormText>
                      }
                    </FormGroup>
                  </Col>

                  {/* Field: Compartments */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="Compartments">
                        Compartment(s) -
                        <span className="optional">optional</span>
                        :
                      </Label>
                      <InputGroup className="input-colour">
                        <Input
                          type="tel"
                          name="Compartments"
                          data-testid="Compartments-field"
                          value={values.Compartments}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={Boolean(errors.Compartments)}
                          autoComplete="off"
                          aria-label="Compartments"
                        />
                      </InputGroup>
                      {
                        errors.Compartments
                          ? <FormText color="error" data-testid="Compartments-feedback">{errors.Compartments}</FormText>
                          : <FormText color="help" data-testid="Compartments-info">Only numerical values, &apos;,&apos; and &apos;-&apos; are accepted for this field. If more than 1 compartment, separate with commas.</FormText>
                      }
                    </FormGroup>
                  </Col>

                  {/* Field: DipBefore */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="DipBefore">Dip Before (B):</Label>
                      <InputGroup className="input-colour">
                        <Input
                          type="number"
                          name="DipBefore"
                          data-testid="DipBefore-field"
                          value={values.DipBefore}
                          onChange={(e) => {
                            handleChange(e);
                            updateCalculatedValue(
                              setFieldValue,
                              { ...values, DipBefore: e.target.value },
                            );
                          }}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value) {
                              e.target.value = parseInt(e.target.value.split('.')[0], 10);
                            }
                            updateCalculatedValue(
                              setFieldValue,
                              { ...values, DipBefore: e.target.value },
                            );
                            handleChange(e); // eslint-disable-next-line
                            touched.DipBefore = true;
                          }}
                          invalid={!!errors.DipBefore && touched.DipBefore}
                          autoComplete="off"
                          aria-label="DipBefore"
                        />
                      </InputGroup>
                      {
                        !!errors.DipBefore && touched.DipBefore
                          ? <FormText color="error" data-testid="DipBefore-feedback">{errors.DipBefore}</FormText>
                          : <FormText color="help" data-testid="DipBefore-info">Only whole numbers are accepted for this field.</FormText>
                      }
                    </FormGroup>
                  </Col>

                  {/* Field: DipAfter */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="DipAfter">Dip After (A):</Label>
                      <InputGroup className="input-colour">
                        <Input
                          type="number"
                          name="DipAfter"
                          data-testid="DipAfter-field"
                          value={values.DipAfter}
                          onChange={(e) => {
                            handleChange(e);
                            updateCalculatedValue(
                              setFieldValue,
                              { ...values, DipAfter: e.target.value },
                            );
                          }}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value) {
                              e.target.value = parseInt(e.target.value.split('.')[0], 10);
                            }
                            updateCalculatedValue(
                              setFieldValue,
                              { ...values, DipAfter: e.target.value },
                            );
                            handleChange(e); // eslint-disable-next-line
                            touched.DipAfter= true;
                          }}
                          invalid={!!errors.DipAfter && touched.DipAfter}
                          autoComplete="off"
                          aria-label="DipAfter"
                        />
                      </InputGroup>
                      {
                        (!!errors.DipAfter && touched.DipAfter)
                          ? <FormText color="error" data-testid="DipAfter-feedback">{errors.DipAfter}</FormText>
                          : <FormText color="help" data-testid="DipAfter-info">Only whole numbers are accepted for this field.</FormText>
                      }
                    </FormGroup>
                  </Col>

                  {/* Field: Calculated */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="Calculated">Calculated (A - B):</Label>
                      <InputGroup className="input-colour">
                        <Input
                          type="number"
                          name="Calculated"
                          data-testid="Calculated-field"
                          value={values.Calculated}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={Boolean(errors.Calculated) && touched.Calculated}
                          autoComplete="off"
                          disabled
                          aria-label="Calculated"
                        />
                      </InputGroup>
                      {
                        (Boolean(errors.Calculated) && touched.Calculated)
                          ? <FormText color="error" data-testid="Calculated-feedback">{errors.Calculated}</FormText>
                          : <FormText color="help" data-testid="Calculated-info">We will calculate this for you.</FormText>
                      }
                    </FormGroup>
                  </Col>

                  {/* Field: ActualDischargeQty */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="ActualDischargeQty">Actual Discharge Quantity:</Label>
                      <InputGroup className="input-colour">
                        <Input
                          type="number"
                          name="ActualDischargeQty"
                          data-testid="ActualDischargeQty-field"
                          value={values.ActualDischargeQty}
                          onChange={(e) => {
                            handleChange(e);
                            ambientValidationCheck(
                              { ...values, ActualDischargeQty: e.target.value },
                            );
                          }}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value && e.target.value !== 0) {
                              e.target.value = parseInt(e.target.value.split('.')[0], 10);
                            }
                            updateCalculatedValue(
                              setFieldValue,
                              { ...values, ActualDischargeQty: e.target.value },
                            );
                            handleChange(e); // eslint-disable-next-line
                            touched.ActualDischargeQty = true;
                          }}
                          invalid={
                            (!!errors.ActualDischargeQty && touched.ActualDischargeQty)
                            || !!quantityWarning
                          }
                          autoComplete="off"
                          aria-label="ActualDischargeQty"
                        />
                      </InputGroup>
                      {
                        errors.ActualDischargeQty
                          ? <FormText color="error" data-testid="ActualDischargeQty-feedback">{errors.ActualDischargeQty}</FormText>
                          : (quantityWarning
                            ? <FormText color="warn" data-testid="ActualDischargeQty-feedback">{quantityWarning}</FormText>
                            : null
                          )
                      }
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs="12">
                    <PrimaryButton
                      disabled={!isValid || ambientDisable}
                      className="submit-button"
                      data-testid="submit-button"
                      type="Submit"
                      label="Save"
                      onClick={editSaveClickHandler}
                    />
                  </Col>
                  <Col xs="12" className="mt-6">
                    <div
                      className="green-link"
                      data-testid="resetForm-link"
                      onClick={() => {
                        resetForm({
                          values: { ...initialValues, LoadIdAddon: values.LoadIdAddon },
                        });
                        setSelectedProduct('');
                        setQuantityWarning();
                        editClearFieldsClickHandler();
                      }}
                      role="button"
                    >
                      Clear all fields
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

/**
 * We use oneOfType because
 * the application uses AppSyncClient
 * the tests/jest provide ApolloClient as we use the <MockProvider/>
 */
EditDropDetails.propTypes = {
  graphClient: PropTypes.oneOfType([
    PropTypes.instanceOf(AWSAppSyncClient).isRequired,
    PropTypes.instanceOf(ApolloClient).isRequired,
  ]).isRequired,
};

// withClient will provide the client in props
export default withClient(EditDropDetails);
