import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import { } from '@bphxd/ds-core-react/icons';
import './_timeoutPage.scss';
import { useMsal } from '@azure/msal-react';
import TimeOutSadIcon from '../../icons/TimeoutIcon';
import { isBreakpoint } from '../../theme/mediaHelpers';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { signOutClickHandlerTimeoutGA } from '../../analytics/events';

const TimeoutPage = () => {
  const { instance } = useMsal();

  const signOutClickHandler = async () => {
    signOutClickHandlerTimeoutGA();
    await instance.logout({ postLogoutRedirectUri: '/' });
  };

  return (
    <Container fluid className="timeout-container m-0 p-0 h-100" data-testid="timeout-container">
      <Row className="ml-0 mr-0">
        <Col className="m-0 p-0">
          <Row className="mt-14">
            <Col>
              <div className="d-flex flex-column align-items-center">
                <TimeOutSadIcon />
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="mb-6 font-18">
              <div className="d-flex flex-column align-items-center">
                Your Session has expired
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="mb-6 font-18 ">
              {
                isBreakpoint()
                  ? (
                    <div className="d-flex timeout-text flex-column">
                      To continue using the app, please sign out
                      and if prompted to close the window, close it
                      and reopen the app to sign in.
                    </div>
                  ) : (
                    <div>
                      <div className="d-flex timeout-text flex-column align-items-center">
                        To continue using the app, please sign out
                        and if prompted
                      </div>
                      <div className="d-flex timeout-text flex-column align-items-center">
                        to close the window, close it
                        and reopen the app to sign in.
                      </div>
                    </div>
                  )
              }
            </Col>
          </Row>
          <Row className="mt-2" data-testid="sign-out-row">
            <Col className="mb-10">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <PrimaryButton
                  className="signout-btn"
                  label="Sign out"
                  data-testid="timeout-signout-btn"
                  onClick={() => signOutClickHandler()}
                />
              </div>
            </Col>
          </Row>
          <Row className="univers justify-content-center font-18 font-weight-light" />
        </Col>
      </Row>
    </Container>
  );
};

export default TimeoutPage;
