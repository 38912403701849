import React from 'react';
import {
  Col, Row, Label, Modal, ModalBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Info32 } from '@bphxd/ds-core-react/icons';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import { isMobileDevice } from '../../theme/mediaHelpers';
import { deleteTankYesClickHandler, deleteTankNoClickHandler } from '../../analytics/events';

const ConfirmActionModal = ({
  isOpen,
  confirmMsg,
  confirmSubMsg,
  handleAction,
  handleCancel,
  actionInProgress,
}) => (
  <Modal isOpen={isOpen} className="confirm-modal" data-testid="confirm-modal" centered>
    <ModalBody>
      <Row className="pt-5 pb-5">
        <Col xs={{ size: 10, offset: 1 }}>
          <Row>
            <Col>
              <Col>
                <Label
                  className={
                    confirmSubMsg
                      ? 'mb-3 univers font-23 lightweight text-center confirm-modal-title'
                      : 'mb-6 univers font-23 lightweight text-center confirm-modal-title'
                  }
                  data-testid="confirm-modal-title"
                >
                  {confirmMsg}
                </Label>
              </Col>
              {confirmSubMsg
                && (
                  <Col sm="12" className="mb-6 d-flex">
                    <Info32 className={isMobileDevice ? 'info-icon mt-n3 mr-n1' : 'info-icon mt-n3 mr-n2'} />
                    <Label className={isMobileDevice ? 'arial font-12 font-weight-normal text-left ' : 'arial font-12 font-weight-normal text-center'}>
                      {confirmSubMsg}
                    </Label>
                  </Col>
                )}
            </Col>
          </Row>

          <Row>
            <Col className="pl-0">
              <PrimaryButton
                label="Yes"
                onClick={() => { handleAction(); deleteTankYesClickHandler(); }}
                data-testid="confirm-btn"
                disabled={actionInProgress}
              />
            </Col>

            <Col className="pr-0">
              <SecondaryButton
                label="No"
                onClick={() => { handleCancel(); deleteTankNoClickHandler(); }}
                data-testid="cancel-btn"
                disabled={actionInProgress}
              />
            </Col>
          </Row>

          <Row className="justify-content-center mt-4">
            {actionInProgress
              && <Label className="univers font-14 lightweight text-center">Please wait...</Label>}
          </Row>
        </Col>
      </Row>
    </ModalBody>
  </Modal>
);

ConfirmActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  confirmMsg: PropTypes.string.isRequired,
  confirmSubMsg: PropTypes.string,
  handleAction: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  actionInProgress: PropTypes.bool,
};

export default ConfirmActionModal;
