import React, { useState, useEffect } from 'react';
import {
  Container, Col, Row, InputGroup, Input, Form, FormGroup, Alert,
  Label, FormText, CustomInput,
} from 'reactstrap';
import PropTypes from 'prop-types';
import ApolloClient from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { Formik } from 'formik';
import { Alert24 } from '@bphxd/ds-core-react/icons';
import { Link, useHistory } from 'react-router-dom';
import Toggle from 'react-toggle';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import { useAppContext } from '../../state/GlobalState';
import withClient from '../../hoc/withClient';
import { getShipmentDetails } from '../../api/graphQl/Shipment';
import { getShipToAddresses } from '../../api/graphQl/ShipTo';
import { getProducts } from '../../api/graphQl/Products';
import BackButton from '../../components/BackButton/BackButton';
import {
  initialValuesOpenOrder, FindOpenOrderSchema, nonExistingOpenOrderMsg,
  missingGetShipmentDetailsErrorPattern,
} from './validation';
import { isMobileDevice } from '../../theme/mediaHelpers';
import './_findOpenOrder.scss';
import '../../theme/toggle.scss';
import { getLoadingPlants } from '../../api/graphQl/LoadingPlant';
import { getDeliveryDropDetails } from '../../api/graphQl/DropDetails';
import { openOrderGoTracker, openOrderToggleOffTracker, openOrderBackButtonTracker } from '../../analytics/events';
import pageTitleConstant from '../../analytics/constantPageTitle';
import NetworkSpeedChecker from '../../components/NetworkSpeedChecker/NetworkSpeedChecker';

const FindOpenOrder = ({ graphClient }) => {
  const history = useHistory();
  const [loadingPlants, setLoadingPlants] = useState([]);
  const [nonExistingWarning, setNonExistingWarning] = useState();

  const {
    carrierId, handleGraphApiError, setPlantId, setAddresses, setShipmentId, setBpProducts,
  } = useAppContext();

  const fetchDropDetails = (shipmentDetails) => {
    shipmentDetails.forEach(async (shipmentDetail) => {
      getDeliveryDropDetails(graphClient, shipmentDetail);
    });
  };

  const apiGetShipTo = async (plantId) => {
    try {
      const response = await getShipToAddresses(
        graphClient,
        carrierId,
        plantId,
      );
      setAddresses(response?.data?.getShipToAddresses?.ShipTo);
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  const fetchLoadingPlants = async () => {
    try {
      const response = await getLoadingPlants(
        graphClient,
        carrierId,
      );

      if (response?.data?.getLoadingPlants?.PLANTS) {
        setLoadingPlants(response.data.getLoadingPlants.PLANTS);
      }
    } catch (error) {
      handleGraphApiError(error);
    }
  };
  const fetchProducts = async () => {
    try {
      const response = await getProducts(graphClient);
      if (response?.data?.getProducts?.Product) {
        // Carrier products schema has different field names (ProductName, ProductID),
        // so we need to map them to the field names (Name, Id) that drop details forms expect
        const products = response?.data?.getProducts?.Product.map((prod) => ({
          Name: prod.ProductName,
          Id: prod.ProductID,
        }));
        setBpProducts(products);
      }
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  useEffect(() => {
    if (carrierId) {
      fetchLoadingPlants();
    }
    fetchProducts();
  }, [carrierId]);

  const getInitialValues = () => {
    const overrideValues = {};

    if (loadingPlants.length === 1) {
      overrideValues.LoadingPlant = loadingPlants[0].PlantID;
    } else {
      overrideValues.LoadingPlant = loadingPlants.PlantID;
    }
    // Pre-select value for loading plant if only one present
    return Object.assign(initialValuesOpenOrder, overrideValues);
  };

  const onSubmit = async (values, formikBag) => {
    const {
      setStatus,
      setSubmitting,
    } = formikBag;
    const id = `${values.ReferenceNo}-${values.LoadingPlant}`;

    try {
      const openOrderDetails = await getShipmentDetails(graphClient, id, carrierId);
      if (openOrderDetails?.data?.getShipmentDetails) {
        if (openOrderDetails?.data?.getShipmentDetails.length > 0) {
        // If shipment exists - render Shipment Details page
          setShipmentId(id);
          setPlantId(openOrderDetails.data.getShipmentDetails[0].SAPPlantId);
          fetchDropDetails(openOrderDetails.data.getShipmentDetails, id);
          apiGetShipTo(openOrderDetails.data.getShipmentDetails[0].SAPPlantId);
          history.push('/shipment-details');
        } else {
        // If the shipment doesn't exist - show validation error
          setNonExistingWarning(nonExistingOpenOrderMsg);
          setStatus({ success: false });
          setSubmitting(false);
        }
      }
    } catch (error) {
      // Any error that has a statusCode means that is was a failure at
      // the server. If this is the case then pass that message to the context to handle.
      if (error?.networkError?.statusCode) {
        handleGraphApiError(error);
      }
      // Handle other network errors here
      // for example when we cant find the response for a query
      if (error.networkError.message.includes(missingGetShipmentDetailsErrorPattern)) {
        // If the shipment doesn't exist - show validation error
        setStatus({ success: false });
        setSubmitting(false);
      }
    }
  };

  return (
    <Container data-testid="find-open-order-container p-0">
      <NetworkSpeedChecker />
      <Formik
        initialValues={getInitialValues()}
        validationSchema={FindOpenOrderSchema}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
          isValid,
          values,
          errors,
          touched,
          dirty,
        }) => (
          <Form noValidate onSubmit={handleSubmit} data-testid="find-open-order-form">
            <Link className="link" to="/landing-page" onClick={() => openOrderBackButtonTracker(pageTitleConstant.lookUpOpenOrder)}>
              <Row className="back-button">
                <BackButton />
              </Row>
            </Link>

            <Row>
              <Col>

                <Row>
                  <Col>
                    <div className="form-title"><h4 className="mb-4">Look up a delivery</h4></div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label className="online-mode-help mb-8 font-14">You need to be online to complete this step.</Label>
                  </Col>
                </Row>

                <Row className="mt-3 mb-6">
                  <Toggle
                    data-testid="find-open-order-toggle"
                    aria-labelledby="biscuit-label"
                    defaultChecked
                    onChange={() => {
                      history.push('/shipment');
                      openOrderToggleOffTracker(pageTitleConstant.lookUpOpenOrder);
                    }}
                    icons={false}
                    className={isMobileDevice() ? 'ml-4' : 'ml-4'}
                  />
                  <Label className="toggle-heading ml-3 font-14">Look up an open order</Label>
                </Row>

                <Row>
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="ReferenceNo">
                        TAS Reference/BOL/Load ID:
                      </Label>
                      <InputGroup>
                        <Input
                          name="ReferenceNo"
                          data-testid="referenceNo-field"
                          value={values.ReferenceNo}
                          onChange={(e) => { handleChange(e); setNonExistingWarning(''); }}
                          onBlur={handleBlur}
                          invalid={Boolean(errors.ReferenceNo)}
                          autoComplete="off"
                          type="tel"
                          className="input-colour"
                        />
                      </InputGroup>
                      {
                        errors.ReferenceNo
                          ? <FormText color="error">{errors.ReferenceNo}</FormText>
                          : <FormText color="help">Enter any one of the IDs available on the Bill of Lading. </FormText>
                      }
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="LoadingPlant">
                        Loading Plant Name:
                      </Label>
                      <InputGroup className="input-colour">
                        <CustomInput
                          type="select"
                          name="LoadingPlant"
                          id="LoadingPlant-select"
                          data-testid="Loading-plant-name"
                          value={values.LoadingPlant}
                          onChange={(e) => { handleChange(e); setNonExistingWarning(''); }}
                          onBlur={handleBlur}
                          invalid={Boolean(errors.LoadingPlant) && touched.LoadingPlant}
                          bsSize="lg"
                          autoComplete="off"
                          disabled={loadingPlants <= 1}
                        >
                          <option key="" value="" hidden>Select an option...</option>
                          {
                          loadingPlants.map(
                            (plant) => (
                              <option
                                key={plant.PlantID}
                                value={plant.PlantID}
                                data-testid={`LoadingPlant-option-${plant.PlantID}`}
                              >
                                {plant.PlantName}
                              </option>
                            ),
                          )
                        }
                        </CustomInput>
                      </InputGroup>
                      {
                        Boolean(errors.LoadingPlant) && touched.LoadingPlant
                          && <FormText color="error">{errors.LoadingPlant}</FormText>
                      }
                    </FormGroup>
                  </Col>
                </Row>

                { nonExistingWarning
                && (
                  <Row>
                    <Col>
                      <Alert
                        key="0"
                        className="w-100 find-open-order-alert"
                        color="warning"
                        data-testid="error-message"
                      >
                        <Alert24 className="find-open-order-alert-icon" />
                        <p className="arial font-13 line-height-20">
                          This open order does not exist or has not been created yet.
                        </p>
                      </Alert>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <PrimaryButton
                      loading={isSubmitting}
                      disabled={isSubmitting || !dirty || !isValid}
                      className="go-button"
                      data-testid="open-order-submit-button"
                      type="Submit"
                      label="Go"
                      onClick={() => openOrderGoTracker(pageTitleConstant.lookUpOpenOrder)}
                    />
                  </Col>
                </Row>

              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

/**
 * We use oneOfType because
 * the application uses AppSyncClient
 * the tests/jest provide ApolloClient as we use the <MockProvider/>
 */
FindOpenOrder.propTypes = {
  graphClient: PropTypes.oneOfType([
    PropTypes.instanceOf(AWSAppSyncClient).isRequired,
    PropTypes.instanceOf(ApolloClient).isRequired,
  ]).isRequired,
};

export default withClient(FindOpenOrder);
