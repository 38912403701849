import React from 'react';
import {
  Col, Row, Label, Modal, ModalBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Remove32 } from '@bphxd/ds-core-react/icons';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import './_completeDeliveryModal.scss';
import { deliveryModalYesClickHandler, deliveryModalNoClickHandler, deliveryModalCloseClickHandler } from '../../analytics/events';

const CompleteDeliveryModal = ({
  isOpen,
  onDeliveredHandler,
  onRedirectHandler,
  onCloseHandler,
  loadingFeedback,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} className="complete-delivery-modal" data-testid="complete-delivery-modal">
      <ModalBody>
        <Row className="d-flex flex-row-reverse pr-4">
          <Remove32 onClick={() => { onCloseHandler(); deliveryModalCloseClickHandler(); }} className="add-pointer" data-testid="close-btn" />
        </Row>

        <Row className="pt-10 pb-10">
          <Col xs={{ size: 10, offset: 1 }}>
            <Row>
              <Label className="mb-8 univers font-32 complete-delivery-modal-title" data-testid="complete-delivery-modal-title">
                Has all the fuel on this delivery docket been delivered?
              </Label>
            </Row>

            <Row>
              <Col className="pl-0">
                <PrimaryButton label="Yes" onClick={() => { onDeliveredHandler(); deliveryModalYesClickHandler(); }} data-testid="complete-btn" />
              </Col>

              <Col className="pr-0">
                <SecondaryButton label="No, redirect it" onClick={() => { onRedirectHandler(); deliveryModalNoClickHandler(); }} data-testid="redirect-btn" />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center mb-2">
          <Label className="univers font-14 text-center">{loadingFeedback}</Label>
        </Row>
      </ModalBody>
    </Modal>
  );
};

CompleteDeliveryModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDeliveredHandler: PropTypes.func.isRequired,
  onRedirectHandler: PropTypes.func.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  loadingFeedback: PropTypes.string,
};

export default CompleteDeliveryModal;
