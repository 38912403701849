import React, { useState } from 'react';
import {
  Col,
  Row,
  Label,
  Modal,
  ModalBody,
  CustomInput,
  FormGroup,
  Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Remove32, Info32 } from '@bphxd/ds-core-react/icons';
import './_redirectModal.scss';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { redirectReasonDropdownHandler, redirectContinueClickHandler, closeRedirectModalClickHandler } from '../../analytics/events';
import SupportInformationModal from '../SupportInformationModal/SupportInformationModal';

const RedirectModal = ({
  isOpen,
  onCloseHandler,
  handleRedirectContinue,
  modalLoading,
  loadingFeedback,
}) => {
  const redirectReasons = [
    'Site closed',
    'Road closed',
    'No site escort',
    'Insufficient ullage',
    'Fault site equipment',
    'Urgent fuel order',
    'Other, please specify...',
  ];

  const [otherDisabled, setOtherDisabled] = useState(true);
  const [continueDisabled, setContinueDisabled] = useState(true);
  const [selectedReason, setSelectedReason] = useState();
  const [otherReasonText, setOtherReasonText] = useState('');

  const title = 'What if I cannot redirect all of the product(s) left onboard to the next destination?';
  const paraOne = 'If you need to deliver a portion of the product left on board to the next destination, select ‘No, redirect it’ after collecting signatures. This allows you to ';
  const stressedWord = 'partially redirect ';
  const restContent = 'the product(s).';
  const paraTwo = 'Please contact dispatch for the new destination details and follow the prompts on redirect screens and capture drop details of the product(s) to be delivered.';

  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  const handleReasonSelect = (e) => {
    setSelectedReason(e.target.value);

    redirectReasonDropdownHandler();

    if (e.target.value === 'Other, please specify...') {
      setOtherDisabled(false);
      setContinueDisabled(true);
    } else if (e.target.value === 'Select an option...') {
      setOtherDisabled(true);
      setContinueDisabled(true);
      setOtherReasonText('');
    } else {
      setOtherDisabled(true);
      setContinueDisabled(false);
      setOtherReasonText('');
    }
  };

  const handleOtherReasonTextChange = (e) => {
    setOtherReasonText(e.target.value);
    if (e.target.value.length > 0) {
      setContinueDisabled(false);
    } else {
      setContinueDisabled(true);
    }
  };

  const openInfoPage = () => {
    setInfoWindowOpen(true);
  };

  const closenInfoPage = () => {
    setInfoWindowOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      className="redirect-modal"
      data-testid="redirect-modal"
    >
      <ModalBody>
        <Row className="justify-content-end pr-4">
          <Remove32 onClick={() => { onCloseHandler(); closeRedirectModalClickHandler(); }} className="add-pointer" />
        </Row>

        <Row className="pt-4 pb-6">
          <Col xs={{ size: 10, offset: 1 }}>
            <Row>
              <Label className="mb-8 univers font-32 redirect-modal-title" data-testid="redirect-modal-title">
                What is the reason for the redirect?
              </Label>
            </Row>

            <Row className="font-12 mt-n6 mb-6">
              <Info32 className="ml-3 mt-n2" />
              <Col className="ml-n3">
                With full redirect you can deliver ALL of the
                products to a new destination.
                {' '}
                <br />
                {' '}
                <br />
                <span style={{ color: 'green' }} onClick={openInfoPage} role="button">Click here </span>
                to find out what you should do if
                you cannot redirect all of the product(s) left
                onboard to the next destination.
              </Col>
            </Row>

            <FormGroup>
              <Label for="redirect-reason">Reason for redirect:</Label>
              <CustomInput
                id="redirect-reason"
                type="select"
                bsSize="md"
                className="mb-3"
                value={selectedReason}
                onChange={handleReasonSelect}
              >
                <option>Select an option...</option>
                {redirectReasons.map((reason) => (
                  <option key={reason} value={reason}>
                    {reason}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>
            {
              !otherDisabled && (
                <FormGroup>
                  <Label for="other-reason-details">Other reason:</Label>
                  <Input
                    id="other-reason-details"
                    name="other-reason-deatils"
                    type="text"
                    data-testid="other-reason-input"
                    className="h-50"
                    value={otherReasonText}
                    maxLength="150"
                    onChange={handleOtherReasonTextChange}
                    autoComplete="off"
                  />
                </FormGroup>

              )
            }
          </Col>
        </Row>
        <Row className="justify-content-center mb-2">
          <Label className="univers font-14 text-center">
            {loadingFeedback}
          </Label>
        </Row>
        <Row className="justify-content-center mb-8">
          <PrimaryButton
            className="w-50"
            label="Continue"
            loading={modalLoading}
            disabled={continueDisabled}
            data-testid="continue-button"
            onClick={() => {
              handleRedirectContinue(selectedReason, otherReasonText);
              redirectContinueClickHandler();
            }}
          />
        </Row>
      </ModalBody>

      <SupportInformationModal
        isOpen={infoWindowOpen}
        title={title}
        paraOne={paraOne}
        stressedWord={stressedWord}
        restContent={restContent}
        paraTwo={paraTwo}
        handleClose={closenInfoPage}
      />
    </Modal>

  );
};

RedirectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseHandler: PropTypes.func.isRequired,
  handleRedirectContinue: PropTypes.func.isRequired,
  modalLoading: PropTypes.bool,
  loadingFeedback: PropTypes.string,
};

export default RedirectModal;
