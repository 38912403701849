import ReactGA from 'react-ga4';
import pageConstant from './constantPageTitle';

const trackingId = process.env.REACT_APP_ga_tracking_id;

const init = () => {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  ReactGA.initialize(trackingId, { debug: isDev });
};
const sendEvent = (payload) => {
  ReactGA.event(payload);
};

const getPageTitle = (path) => {
  let pageTitle;
  switch (path) {
    case '/landing-page':
      pageTitle = pageConstant.landingPage;
      break;
    case '/shipment':
      pageTitle = pageConstant.lookUpShipment;
      break;
    case '/find-open-order':
      pageTitle = pageConstant.lookUpOpenOrder;
      break;
    case '/shipment-details':
      pageTitle = pageConstant.shipmentDetailsPage;
      break;
    case '/delivery-docket':
      pageTitle = pageConstant.deliveryDocketPage;
      break;
    case '/enter-drop-details':
      pageTitle = pageConstant.dropDetailsForm;
      break;
    case '/edit-drop-details':
      pageTitle = pageConstant.editDropDetailsForm;
      break;
    case '/drop-details':
      pageTitle = pageConstant.dropDetailSummary;
      break;
    case '/add-ship-to':
      pageTitle = pageConstant.updateDestination;
      break;
    case '/open-order':
      pageTitle = pageConstant.createOpenOrder;
      break;
    case '/timeout':
      pageTitle = pageConstant.timeout;
      break;
    case '/':
      pageTitle = pageConstant.welcome;
      break;
    default:
      pageTitle = `No page title found for path ${path}`;
  }
  return pageTitle;
};

const sendPageview = (path) => {
  ReactGA.set({ page: path });
  ReactGA.set({ page_title: getPageTitle(path) });
  ReactGA.send('pageview');
};

const setGAItem = (json) => {
  ReactGA.set(json);
};

export default {
  sendEvent,
  sendPageview,
  setGAItem,
  init,
  getPageTitle,
};
