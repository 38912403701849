import gql from 'graphql-tag';

export const GetDriver = `
query GetDriver($id: ID!) {
  getDriver(id: $id) {
    id
    CarrierID
  DriverStatus
  Email
  FirstName
  LastActionType
  LastName
  SAPDriverID
  TASDriverCode
  }
}
`;

export const CreateDriver = `
  mutation NewDriverMutation(
    $id: ID
    $CarrierID: Int
    $DriverStatus: String
    $Email: String
  ) {
    createDriver(
      input: {
        id: $id
        CarrierID: $CarrierID
        DriverStatus: $DriverStatus
        Email: $Email
      }
    ) {
      CarrierID
        Email
        DriverStatus
        id
    }
  }
`;

export const DriverDetailsQuery = gql`
query GetDriverDetails($email: String!) {
  getDriverDetails(PK: $email) {
    CARRIERID
    DriverStatus
    FirstName
    LastActionDate
    LastActiontype
    LastName
    PK
    SAPPlantId
    SK
  }
}
`;

export const queryDriverDetails = async (client, email) => {
  const data = await client.query({
    query: DriverDetailsQuery,
    variables: {
      email,
    },
    fetchPolicy: 'network-only',
  });
  return data;
};
