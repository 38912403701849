import React, {
  useContext, useReducer, useEffect, useState,
} from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Container, Col, Row, Label, Alert,
} from 'reactstrap';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Date32, User32, Check32 } from '@bphxd/ds-core-react/icons';
import { useMsal } from '@azure/msal-react';
import PropTypes from 'prop-types';
import AWSAppSyncClient from 'aws-appsync';
import ApolloClient from 'apollo-client';
import flatten from 'lodash/flatten';
import withClient from '../../hoc/withClient';
import PageHeading from '../../components/PageHeading/PageHeading';
import './_deliveryDocket.scss';
import ShipmentAddress from '../../components/ShipmentAddress/ShipmentAddress';
import LinkTile from '../../components/LinkTile/LinkTile';
import ContractIcon from '../../icons/ContractIcon';
import GasIcon from '../../icons/GasIcon';
import BackButton from '../../components/BackButton/BackButton';
import ProductsCard from '../../components/ProductsCard/ProductsCard';
import { useAppContext, DeliveryContext } from '../../state/GlobalState';
import {
  deliveryDocketState, deliverDocketReducer, DeliveryDocketActions,
} from './DeliveryDocketReducer';
import { isDocketStatusDelivered, statuses } from '../../helpers/shipmentStatus';
import {
  isDocketShipmentAddressInValid, INSTRUCTION_RESET, skHasValue, isUnmappedOpenOrder,
} from '../../helpers/shipmentEngine';
import SignaturesFlow from './SignaturesFlow/SignaturesFlow';
import TruckIcon from '../../icons/TruckIcon';
import CheckListIcon from '../../icons/CheckListIcon';
import ConfirmActionModal from '../../components/ConfirmActionModal/ConfirmActionModal';
import { extractAttributeFromCollection } from '../../helpers/attributeExtraction';
import { getDeliveryDropDetails } from '../../api/graphQl/DropDetails';
import {
  ddBackButtonClickHandler, dropDetailTileClickHandler, signatureTileClickHandler,
  productDischargeAlertYesTracker, productDischargeAlertNoTracker,
} from '../../analytics/events';
import { getShipmentHeader } from '../../helpers/openOrders';
import { getShipmentDetails } from '../../api/graphQl/Shipment';
import pageTitleConstant from '../../analytics/constantPageTitle';

// Instructions should be shown only if none of the objects have value 'INSTRUCTION_RESET'
// DI is short for delivery_instruction and PI is short for permanent instruction
const showInstruction = (DI, PI) => !(DI === INSTRUCTION_RESET && PI === INSTRUCTION_RESET);

const DeliveryDocket = ({
  graphClient,
}) => {
  const history = useHistory();
  const {
    setSelectedDocket, shipmentId, handleGraphApiError, carrierId,
  } = useAppContext();
  const [deliveryId] = useContext(DeliveryContext);
  const [shipmentDetails, setShipmentDetails] = useState([]);
  const setShipmentDetailsForDelivery = (localShipmentDetails) => {
    const shipmentDetailsForDelivery = localShipmentDetails.filter(
      (sd) => skHasValue(sd, deliveryId),
    );
    setShipmentDetails(shipmentDetailsForDelivery);
  };

  if (!shipmentId || !deliveryId) {
    // If there's no shipment id or delivery id, redirect to find shipment page
    return <Redirect to="/shipment" />;
  }

  const username = useMsal().accounts[0].name;
  const [state, dispatch] = useReducer(deliverDocketReducer, deliveryDocketState);
  // store all drop details
  const [dropDetailsAll, setDropDetailsAll] = useState([]);
  const [lidStatus, setLidStatus] = useState(false);

  const {
    deliveryDocket: currentDeliveryDocket,
    dropCount,
    isShippingAddressLoaded = true,
  } = state;

  const {
    PlannedStartDate,
    ShipmentAddress: address,
    LoadingPlant,
    SAPPlantId,
    OrderNo,
    PONumber,
    DeliveryComments,
    PermanentInstruction,
    Products,
  } = currentDeliveryDocket;

  const isOpenOrder = isUnmappedOpenOrder(currentDeliveryDocket);

  // warning message details
  const lidWarningMessage = 'Product(s) loaded for this docket have not been fully delivered. Are you sure you want to continue?';
  const supportMessage = 'You will get a chance to redirect product(s) after collecting the signatures.';
  const [lidWarningStatus, setLidWarningStatus] = useState(false);
  const closeWindow = () => setLidWarningStatus(false);
  const continueSubmit = () => {
    setLidWarningStatus(false);
    dispatch({ type: DeliveryDocketActions.OPEN_SIGNATURE_MODAL });
    productDischargeAlertYesTracker();
  };

  const uniqLoadIdAddons = extractAttributeFromCollection(shipmentDetails, 'SK', 'LID#');

  // Calculate total delivered amount for selected delivery docket
  const calculateTotalDeliverd = (dropDetails) => {
    const map = new Map();
    if (dropDetails) {
      dropDetails.forEach((dropDetail) => {
        if (dropDetail.DROPS.length) {
          dropDetail.DROPS.forEach(
            (drop) => {
              if (map.has(dropDetail.SK.split('#LID#')[1] + drop.Product)) {
                map.set(
                  dropDetail.SK.split('#LID#')[1] + drop.Product, map.get(dropDetail.SK.split('#LID#')[1] + drop.Product) + drop.ActualDischargeQty,
                );
              } else { map.set(dropDetail.SK.split('#LID#')[1] + drop.Product, drop.ActualDischargeQty); }
            },
          );
        }
      });
    }
    return map;
  };

  // Judge whether a LID has been fully delivered
  const whetherFullyDelivered = (lid) => {
    const lidProducts = shipmentDetails.find((sd) => sd.SK.includes(`LID#${lid}`)).Products;
    let flag = true;
    Object.values(lidProducts).forEach((product) => {
      if ([...new Set(dropDetailsAll)]
            && calculateTotalDeliverd([...new Set(dropDetailsAll)]).get(lid + product.Name)) {
        flag = flag && parseInt(product.QuantityAmbient, 10)
          - calculateTotalDeliverd([...new Set(dropDetailsAll)]).get(lid + product.Name) === 0;
      } else {
        flag = flag && parseInt(product.QuantityAmbient, 10) === 0;
      }
    });
    return flag;
  };

  const allDelivered = () => {
    if (isOpenOrder) {
      setLidStatus(true);
    } else if (uniqLoadIdAddons.length > 0) {
      let flag = true;
      uniqLoadIdAddons.forEach((uniqLoadIdAddon) => {
        flag = flag && whetherFullyDelivered(uniqLoadIdAddon);
      });
      setLidStatus(flag);
    }
  };

  const fetchAndSetAllDropDetails = async () => {
    try {
      const shDetails = await getShipmentDetails(graphClient, shipmentId, carrierId);
      if (shDetails?.data?.getShipmentDetails) {
        setShipmentDetailsForDelivery(shDetails.data.getShipmentDetails);
        shDetails.data.getShipmentDetails.forEach(
          async (sd) => {
            // As sd.SK is always in format like: DeliveryNo#ORIGINAL#204077233#LID#820641 or
            // DeliveryNo#REDIRECT#204077232-R1#LID#820641, so:
            // 1. sd.SK.split('#')[2] is used to obatin the Delivery Docket number
            // 2. split('-')[0] is used to extract the Delivery Docket number without '-R'
            if (sd.Status !== statuses.Void && sd.SK.split('#')[2].split('-')[0] === deliveryId.split('-')[0]) {
              const response = await getDeliveryDropDetails(graphClient, sd);
              if (response?.data?.getDropDetails) {
                // We have to set the state here because drop detail fetch is async
                setDropDetailsAll(
                  (oldArray) => flatten([...oldArray, response.data.getDropDetails]),
                );
              }
            }
          },
        );
      }
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  useEffect(() => {
    // setting shipment details as well as all drop details
    fetchAndSetAllDropDetails();
    // setting initial state for shipment section
    dispatch({ type: DeliveryDocketActions.LOADING_SHIPMENT_ADDRESS });
  }, [shipmentId, deliveryId]);

  useEffect(() => {
    allDelivered();
  });
  return (
    <Container data-testid="delivery-docket">
      <Row>
        <Col>
          <Link className="link" to="/shipment-details" onClick={ddBackButtonClickHandler(pageTitleConstant.deliveryDocketPage)}>
            <Row>
              <BackButton />
            </Row>
          </Link>

          <Row>
            <PageHeading label="Delivery Docket Number" refNumber={getShipmentHeader(deliveryId)} />
          </Row>

          <Row>
            {!isShippingAddressLoaded && (
              <Col sm="12" md="6" className="p-0">
                <Skeleton height="16px" width="90%" count={1} />
              </Col>
            )}
            {isShippingAddressLoaded && (
              <Col sm="12" md="6" className="pl-0 d-flex w-100 align-items-top mb-4">
                <Date32 className="date-icon" />
                <Label className="font-12" data-testid="delivery-date">
                  Delivery date:
                  {' '}
                  {PlannedStartDate}
                </Label>
              </Col>
            )}
            <Col sm="12" md="6" className="pl-0 d-flex w-100 align-items-top mb-4">
              <User32 className="driver-icon" />
              <Label className="font-12" data-testid="driver-name">
                Driver:
                {' '}
                {username}
              </Label>
            </Col>
          </Row>

          {currentDeliveryDocket && isDocketStatusDelivered(currentDeliveryDocket) && (
            <Row>
              <Alert
                key="0"
                className="d-flex align-items-center w-100 mb-4"
              >
                <Check32 />
                <p className="univers font-16">
                  Delivered - This is a read-only version and can’t be edited.
                </p>
              </Alert>
            </Row>
          )}

          <Row>
            <Col sm="12" md="6">
              <Row>
                <ShipmentAddress
                  address={address}
                  dispatch={dispatch}
                  docketDetails={currentDeliveryDocket}
                  dropCount={dropCount}
                  isLoaded={isShippingAddressLoaded}
                />
              </Row>
            </Col>

            <Col sm="12" md="6">
              <Row>
                <Col className="details-container-LP rounded mb-4" data-testid="loading-plant">
                  <Label className="univers mt-4 font-weight-light">Loading Plant: </Label>
                  <br />
                  {!isShippingAddressLoaded && (
                    <Col sm="12" md="6" className="p-0">
                      <Skeleton height="10%" width="90%" count={1} />
                      <Skeleton height="10%" width="70%" className="mb-4" count={1} />
                    </Col>
                  )}
                  {isShippingAddressLoaded && (
                    <Row>
                      <Col>
                        <Label className="font-13 m-0">
                          Name:
                          {' '}
                          {LoadingPlant}
                        </Label>
                        <br />
                        <Label className="font-13 mb-4">
                          Plant number:
                          {' '}
                          {SAPPlantId}
                        </Label>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>

              <Row>
                <Col className="details-container-RD rounded mb-4" data-testid="ref-details">
                  <Label className="univers mt-4 font-weight-light">Reference Details: </Label>
                  {!isShippingAddressLoaded && (
                    <Col sm="12" md="6" className="p-0">
                      <Skeleton height="10%" width="90%" count={1} />
                      <Skeleton height="10%" width="70%" count={1} />
                      <Skeleton height="10%" width="50%" className="mb-4" count={1} />
                    </Col>
                  )}
                  {isShippingAddressLoaded && (
                    <Row>
                      <Col>
                        {
                          isOpenOrder && (
                            <>
                              <Label className="font-13 m-0">
                                Order Type: Open
                              </Label>
                              <br />
                            </>
                          )
                        }
                        <Label className="font-13 m-0">
                          Shipment Number:
                          {' '}
                          {getShipmentHeader(deliveryId)}
                        </Label>
                        <br />
                        <Label className="font-13 m-0">
                          SAP Order Number:
                          {' '}
                          {OrderNo}
                        </Label>
                        <br />
                        <Label className="font-13 mb-4">
                          Customer Purchase Order:
                          {' '}
                          {PONumber}
                        </Label>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            {!isShippingAddressLoaded && (
              <Col sm="12" className="instruction-box rounded pt-0 mb-6">
                <br />
                <Skeleton height="16px" width="50%" count={1} />
                <Skeleton height="16px" width="30%" className="mb-4" count={1} />
              </Col>
            )}
            {isShippingAddressLoaded && showInstruction(DeliveryComments, PermanentInstruction) && (
              <Col className="instruction-box rounded">
                <Row>
                  <Col sm="12" className="d-flex w-100 align-items-top mb-4 mt-6">
                    <TruckIcon />
                    <Label className="font-13 pl-1 mr-1">
                      Delivery Instruction:
                      {' '}
                      {DeliveryComments}
                    </Label>
                    <br />
                  </Col>
                  <Col sm="12" className="d-flex w-100 align-items-top mb-4">
                    <CheckListIcon />
                    <Label className="font-13 pl-1">
                      Permanent Instruction:
                      {' '}
                      {PermanentInstruction}
                    </Label>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>

          <Row>
            <Col className="p-0">
              <LinkTile
                icon={GasIcon}
                title="Drop details"
                disabled={isDocketShipmentAddressInValid(currentDeliveryDocket)}
                onClick={() => (dropCount === 0
                  ? (setSelectedDocket(currentDeliveryDocket) || dropDetailTileClickHandler(pageTitleConstant.deliveryDocketPage)) || history.push('/enter-drop-details')
                  : (setSelectedDocket(currentDeliveryDocket) || dropDetailTileClickHandler(pageTitleConstant.deliveryDocketPage)) || history.push('/drop-details'))}
                testId="drop-details-link-tile"
              />
              <LinkTile
                icon={ContractIcon}
                title="Signatures"
                disabled={dropCount === 0 || isDocketStatusDelivered(currentDeliveryDocket)}
                onClick={
                  () => (
                    (lidStatus || isOpenOrder)
                      ? dispatch({ type: DeliveryDocketActions.OPEN_SIGNATURE_MODAL })
                      : setLidWarningStatus(true)
                  ) || signatureTileClickHandler(pageTitleConstant.deliveryDocketPage)
                }
                testId="signatures-link-tile"
              />
              <ConfirmActionModal
                data-testid="lid-warning-window"
                isOpen={lidWarningStatus}
                confirmMsg={lidWarningMessage}
                confirmSubMsg={supportMessage}
                handleCancel={() => { closeWindow(); productDischargeAlertNoTracker(); }}
                handleAction={continueSubmit}
              />
            </Col>
          </Row>

          {
            Products && Products.length > 0 && (
              <Row>
                <ProductsCard
                  shipmentDetails={shipmentDetails}
                />
              </Row>
            )
          }
        </Col>
      </Row>

      <SignaturesFlow state={state} dispatch={dispatch} cleared={lidStatus && !isOpenOrder} />
    </Container>
  );
};

/**
 * We use oneOfType because
 * the application uses AppSyncClient
 * the tests/jest provide ApolloClient as we use the <MockProvider/>
 */
DeliveryDocket.propTypes = {
  graphClient: PropTypes.oneOfType([
    PropTypes.instanceOf(AWSAppSyncClient).isRequired,
    PropTypes.instanceOf(ApolloClient).isRequired,
  ]).isRequired,
};

// withClient will provide the client in props
export default withClient(DeliveryDocket);
