import * as Yup from 'yup';

export const invalidShipmentFormatMsg = 'A shipment number can only contain up to 10 numeric digits. Try again.';
export const nonExistingShipmentMsg = 'This shipment number doesn’t exist or has not been loaded yet. Try again.';
export const nonExistingOpenOrderMsg = 'This open order does not exist or has not been created yet.';
export const requiredShipmentMsg = 'Please enter a shipment number.';
export const offlineShipmentNotAvailableMessage = 'You must be online to look for a new shipment';
export const invalidReferenceNoMsg = 'TAS Reference/BOL/Load ID can only contain up to 7 numeric digits. Try again.';
export const missingGetShipmentDetailsErrorPattern = 'Can\'t find field getShipmentDetails';

export const initialValues = {
  ShipmentHeaderID: '',
};

export const initialValuesOpenOrder = {
  ReferenceNo: '',
  LoadingPlant: '',
};

export const FindShipmentSchema = Yup.object().shape({
  ShipmentHeaderID: Yup.string()
    .required(requiredShipmentMsg)
    .matches(/^[0-9]{1,10}$/, invalidShipmentFormatMsg),
});

export const FindOpenOrderSchema = Yup.object().shape({
  ReferenceNo: Yup.string()
    .required()
    .matches(/^[0-9]{1,7}$/, invalidReferenceNoMsg)
    .label('TAS Reference/BOL/Load ID'),
  LoadingPlant: Yup.string()
    .required()
    .label('Loading Plant'),
});
