import React from 'react';
import {
  Container, Col, Row, InputGroup, Input, Form, FormGroup, Label, FormText,
} from 'reactstrap';
import { Formik } from 'formik';
import { Info32 } from '@bphxd/ds-core-react/icons';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import ApolloClient from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import Toggle from 'react-toggle';
import { isMobileDevice } from '../../theme/mediaHelpers';
import withClient from '../../hoc/withClient';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import './_findShipment.scss';
import bpBol from '../../images/bp-bol.png';
import { getShipmentDetails } from '../../api/graphQl/Shipment';
import { getShipToAddresses } from '../../api/graphQl/ShipTo';
import { useAppContext } from '../../state/GlobalState';
import {
  initialValues, FindShipmentSchema, nonExistingShipmentMsg,
  offlineShipmentNotAvailableMessage,
  missingGetShipmentDetailsErrorPattern,
} from './validation';
import { getDeliveryDropDetails } from '../../api/graphQl/DropDetails';
import BackButton from '../../components/BackButton/BackButton';
import { goButtonClickHandler, errorHandler, findShipmentToggleOnTracker } from '../../analytics/events';
import pageTitleConstant from '../../analytics/constantPageTitle';
import NetworkSpeedChecker from '../../components/NetworkSpeedChecker/NetworkSpeedChecker';

const FindShipment = ({ graphClient }) => {
  const history = useHistory();
  const {
    carrierId, setPlantId, handleGraphApiError, setAddresses, setShipmentId,
  } = useAppContext();

  // To enable offline capabilities and limit the pages where the user needs to be offline,
  // we are pre-fetching drop details that are already associated with this shipment.
  // As data flow in this app is one-directional, in theory, this step is not required,
  // however, if the user clears their local storage, pre-fetch of drop details will make sure
  // that server DB state is correctly represented.
  // that server DB state is correctly represented.
  const fetchDropDetails = (shipmentDetails) => {
    shipmentDetails.forEach(async (shipmentDetail) => {
      getDeliveryDropDetails(graphClient, shipmentDetail);
    });
  };

  const apiGetShipTo = async (plantId) => {
    try {
      const response = await getShipToAddresses(
        graphClient,
        carrierId,
        plantId,
      );
      setAddresses(response?.data?.getShipToAddresses?.ShipTo);
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  const onSubmit = async (values, formikBag) => {
    const {
      setFieldError,
      setStatus,
      setSubmitting,
    } = formikBag;
    const shipmentHeaderID = values.ShipmentHeaderID;

    // Call API to validate that the shipment exists
    try {
      goButtonClickHandler(pageTitleConstant.lookUpShipment);

      const shDetails = await getShipmentDetails(graphClient, shipmentHeaderID, carrierId);

      if (shDetails?.data?.getShipmentDetails) {
        if (shDetails?.data?.getShipmentDetails.length > 0) {
        // If shipment exists - render Shipment Details page
          setShipmentId(shipmentHeaderID);
          setPlantId(shDetails.data.getShipmentDetails[0].SAPPlantId);
          fetchDropDetails(shDetails.data.getShipmentDetails, shipmentHeaderID);
          await apiGetShipTo(shDetails.data.getShipmentDetails[0].SAPPlantId);
          history.push('/shipment-details');
        } else {
        // If the shipment doesn't exist - show validation error
          setFieldError('ShipmentHeaderID', nonExistingShipmentMsg);
          setStatus({ success: false });
          setSubmitting(false);

          errorHandler(pageTitleConstant.lookUpShipment);
        }
      }
    } catch (error) {
      // Any error that has a statusCode means that is was a failure at
      // the server. If this is the case then pass that message to the context to handle.
      if (error?.networkError?.statusCode) {
        handleGraphApiError(error);
      }
      // Handle other network errors here
      // for example when we cant find the response for a query
      if (error.networkError.message.includes(missingGetShipmentDetailsErrorPattern)) {
        // If the shipment doesn't exist - show validation error
        setFieldError('ShipmentHeaderID', offlineShipmentNotAvailableMessage);
        setStatus({ success: false });
        setSubmitting(false);

        errorHandler(pageTitleConstant.lookUpShipment);
      }
    }
  };

  return (
    <Container className="content" data-testid="find-shipment-container">
      <NetworkSpeedChecker />
      <Formik
        initialValues={initialValues}
        validationSchema={FindShipmentSchema}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
          values,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit} data-testid="find-shipment-form">
            <Link className="link" to="/landing-page">
              <Row>
                <BackButton />
              </Row>
            </Link>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <div className="form-title"><Label className="mb-4">Look up a delivery</Label></div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label className="online-mode-help mb-8 font-14">You need to be online to complete this step.</Label>
                  </Col>
                </Row>

                <Row className="mt-3 mb-6">
                  <Toggle
                    data-testid="find-shipment-toggle"
                    aria-labelledby="biscuit-label"
                    defaultChecked={false}
                    onChange={() => {
                      history.push('/find-open-order');
                      findShipmentToggleOnTracker(pageTitleConstant.lookUpShipment);
                    }}
                    icons={false}
                    className={isMobileDevice() ? 'ml-4' : 'ml-4'}
                  />
                  <Label className="toggle-heading ml-3 font-14">Look up an open order</Label>
                </Row>

                {/* 'input-colour' - this css class is used globally for all input fields which is
                  defined in _enterDropDetails.scss */}
                <Row>
                  <Col sm="12" md="6">
                    {/* Field: ShipmentHeaderID */}
                    <FormGroup className="input-container">
                      <Label className="shipment-header" for="ShipmentHeaderID">Enter Shipment Number</Label>
                      <InputGroup className="input-field">
                        <Input
                          name="ShipmentHeaderID"
                          data-testid="shipment-header-id-field"
                          value={values.ShipmentHeaderID}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={Boolean(errors.ShipmentHeaderID)}
                          autoComplete="off"
                          type="tel"
                          className="input-colour"
                        />
                      </InputGroup>
                      {
                        errors.ShipmentHeaderID
                          ? <FormText color="error">{errors.ShipmentHeaderID}</FormText>
                          : <FormText color="help">You can find this number on the Bill of Lading.</FormText>
                      }
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6">
                    <PrimaryButton
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      className="go-button"
                      data-testid="submit-button"
                      type="Submit"
                      label="Go"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm="12">
                    <div className="line" />
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <Label className="graphic-help univers">Where to find shipment number</Label>
                  </Col>
                  <Col className="text-col mb-5 mt-4 d-flex align-items-center">
                    <span>
                      <Info32 className="info-icon" />
                    </span>
                    <span className="text">
                      The shipment number is listed right below the Load ID Addon
                      on the Bill of Lading.
                    </span>
                  </Col>
                  <Col sm="12">
                    <img className="graphic img-fluid" alt="BOL Graphic" src={bpBol} />
                  </Col>
                </Row>

              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

/**
 * We use oneOfType because
 * the application uses AppSyncClient
 * the tests/jest provide ApolloClient as we use the <MockProvider/>
 */
FindShipment.propTypes = {
  graphClient: PropTypes.oneOfType([
    PropTypes.instanceOf(AWSAppSyncClient).isRequired,
    PropTypes.instanceOf(ApolloClient).isRequired,
  ]).isRequired,
};

// withClient will provide the client in props
export default withClient(FindShipment);
