import React, { useState, useEffect } from 'react';
import {
  Row, Col, Container, Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import ApolloClient from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import { Link, Redirect } from 'react-router-dom';
import SecondaryButton from '../../components/SecondaryButton/SecondaryButton';
import PageHeading from '../../components/PageHeading/PageHeading';
import './_shipmentDetails.scss';
import { useAppContext } from '../../state/GlobalState';
import { getShipmentDetails } from '../../api/graphQl/Shipment';
import LoadIdAddons from '../../components/LoadIdAddons/LoadIdAddons';
import DeliveryDockets from '../../components/DeliveryDockets/DeliveryDockets';
import withClient from '../../hoc/withClient';
import { lookUpDeliveryClickHandler } from '../../analytics/events';
import { isUnmappedOpenOrder } from '../../helpers/shipmentEngine';
import { getShipmentHeader } from '../../helpers/openOrders';
import pageTitleConstant from '../../analytics/constantPageTitle';

const ShipmentDetails = ({
  graphClient,
}) => {
  const {
    carrierId, shipmentId, handleGraphApiError,
  } = useAppContext();
  const [shipmentDetails, setShipmentDetails] = useState([]);

  if (!shipmentId) {
    // If there's no shipment id, redirect to find shipment page
    return <Redirect to="/shipment" />;
  }

  useEffect(() => {
    const fetchAndSetShipmentDetails = async () => {
      const shDetails = await getShipmentDetails(graphClient, shipmentId, carrierId);
      if (shDetails?.data?.getShipmentDetails) {
        setShipmentDetails(shDetails.data.getShipmentDetails);
      }
    };

    fetchAndSetShipmentDetails()
      .catch((error) => handleGraphApiError(error));
  }, [shipmentId]);

  lookUpDeliveryClickHandler(pageTitleConstant.shipmentDetailsPage);

  const isOpenOrder = shipmentDetails
    && shipmentDetails[0]
    && isUnmappedOpenOrder(shipmentDetails[0]);

  return (
    <Container data-testid="shipment-details-container" className="mt-10">
      <PageHeading label="Shipment number" refNumber={getShipmentHeader(shipmentId)} />

      {
        isOpenOrder && (
          <Label
            className="univers font-16 lightweight mb-6 order-type-label"
            data-testid="order-type-subheading"
          >
            Order Type: Open
          </Label>
        )
      }

      {
        !isOpenOrder && (
          <Row data-testid="load-id-addons-container">
            <Col sm="12">
              <LoadIdAddons shipmentDetails={shipmentDetails} />
            </Col>
          </Row>
        )
}

      <Row data-testid="delivery-dockets-container">
        <Col sm="12" data-testid="delivery-docket">
          <DeliveryDockets shipmentDetails={shipmentDetails} />
        </Col>
      </Row>

      <Row className="mt-13">
        <Col sm="12">
          <Link to="/landing-page">
            <SecondaryButton
              data-testid="another-delivery-button"
              label="Start another delivery"
              onClick={() => lookUpDeliveryClickHandler(pageTitleConstant.shipmentDetailsPage)}
            />
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

/**
 * We use oneOfType because
 * the application uses AppSyncClient
 * the tests/jest provide ApolloClient as we use the <MockProvider/>
 */
ShipmentDetails.propTypes = {
  graphClient: PropTypes.oneOfType([
    PropTypes.instanceOf(AWSAppSyncClient).isRequired,
    PropTypes.instanceOf(ApolloClient).isRequired,
  ]).isRequired,
};

// withClient will provide the client in props
export default withClient(ShipmentDetails);
