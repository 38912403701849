import React, {
  useContext, useState, useEffect, useRef, useReducer,
} from 'react';
import {
  Alert, Container, Col, Row, Spinner, Label,
} from 'reactstrap';
import ApolloClient from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import PropTypes from 'prop-types';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Info24 } from '@bphxd/ds-core-react/icons';
import { orderBy } from 'lodash';
import flatten from 'lodash/flatten';
import { useAppContext, DeliveryContext } from '../../state/GlobalState';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import DropDetailCard from '../../components/DropDetailCard/DropDetailCard';
import PageHeading from '../../components/PageHeading/PageHeading';
import BackButton from '../../components/BackButton/BackButton';
import { getDeliveryDropDetails, deleteDropDetails } from '../../api/graphQl/DropDetails';
import { extractAttributeFromKey } from '../../helpers/attributeExtraction';
import withClient from '../../hoc/withClient';
import './_dropDetails.scss';
import { isDocketStatusDelivered, statuses } from '../../helpers/shipmentStatus';
import ContractIcon from '../../icons/ContractIcon';
import LinkTile from '../../components/LinkTile/LinkTile';
import SignaturesFlow from '../DeliveryDocket/SignaturesFlow/SignaturesFlow';
import {
  deliveryDocketState, deliverDocketReducer, DeliveryDocketActions,
} from '../DeliveryDocket/DeliveryDocketReducer';
import { getShipmentDetails } from '../../api/graphQl/Shipment';
import { skHasValue, isUnmappedOpenOrder } from '../../helpers/shipmentEngine';
import LidDetail from '../../components/LidDetail/LidDetail';
import ConfirmActionModal from '../../components/ConfirmActionModal/ConfirmActionModal';
import {
  addAnotherTankClickHandler, dropBackButtonClickHandler, collectSignaturesClickHandler,
  productDischargeAlertYesTracker, productDischargeAlertNoTracker,
} from '../../analytics/events';
import { getShipmentHeader } from '../../helpers/openOrders';
import pageTitleConstant from '../../analytics/constantPageTitle';
import NetworkSpeedChecker from '../../components/NetworkSpeedChecker/NetworkSpeedChecker';

const DropDetailsPage = ({
  graphClient,
}) => {
  const history = useHistory();
  const {
    carrierId, selectedDocket, shipmentId, handleGraphApiError,
  } = useAppContext();
  const [deliveryId] = useContext(DeliveryContext);
  const [dropDetails, setDropDetails] = useState();
  const [dropDetailsAll, setDropDetailsAll] = useState([]);
  const [deleteResult, setDeleteResult] = useState();
  const deleteResultAlertRef = useRef(null);
  const [shipmentDetails, setShipmentDetails] = useState([]);

  const [state, dispatch] = useReducer(deliverDocketReducer, deliveryDocketState);

  // get lid status from its child component - LidDetails
  const [lidStatus, setLidStatus] = useState(false);
  const sendDataBackToParent = (valueBack) => {
    setLidStatus(valueBack);
  };

  // remove duplicate records
  const removeDuplicate = (arrayToProcess) => {
    const map = {};
    // eslint-disable-next-line
    for (const element of arrayToProcess) {
      map[element.SK] = element;
    }
    return Object.values(map);
  };

  // warning message details
  const lidWarningMessage = 'Product(s) loaded for this docket have not been fully delivered. Are you sure you want to continue?';
  const supportMessage = 'You will get a chance to redirect product(s) after collecting the signatures.';
  const [lidWarningStatus, setLidWarningStatus] = useState(false);
  const closeWindow = () => setLidWarningStatus(false);
  const continueSubmit = () => {
    setLidWarningStatus(false);
    dispatch({ type: DeliveryDocketActions.OPEN_SIGNATURE_MODAL });
    productDischargeAlertYesTracker();
  };

  if (!carrierId || !shipmentId || !deliveryId) {
    // If there's no shipment id or delivery id, redirect to find shipment page
    return <Redirect to="/shipment" />;
  }

  const isDocketCompleted = isDocketStatusDelivered(selectedDocket);

  const setShipmentDetailsForDelivery = (localShipmentDetails) => {
    const shipmentDetailsForDelivery = localShipmentDetails.filter(
      (sd) => skHasValue(sd, deliveryId),
    );
    setShipmentDetails(shipmentDetailsForDelivery);
  };

  const isOpenOrder = isUnmappedOpenOrder(selectedDocket);

  // The following lines will extract data from the response
  // and will populate "dropDetails" with all drops regardless of LID.
  // LID will be added in "LoadIdAddon" field.
  const extractAllDrops = (dDetails) => {
    const allDrops = [];

    dDetails.forEach((dropDetail) => {
      const loadIdAddon = extractAttributeFromKey(dropDetail.SK, 'LID#');
      if (dropDetail.DROPS) {
        const modifiedDrops = dropDetail.DROPS.map(
          (drop) => ({
            PK: dropDetail.PK,
            SK: dropDetail.SK,
            LoadIdAddon: loadIdAddon,
            ...drop,
          }),
        );
        allDrops.push(...modifiedDrops);
      }
    });

    // Drops on this page should be ordered by DropId
    // Server returns drops ordered by drop id,
    // however manual cache update doesn't preserve order
    setDropDetails(orderBy(allDrops, 'DropId'));
  };

  const fetchAndSetDropDetails = async () => {
    try {
      const response = await getDeliveryDropDetails(graphClient, selectedDocket);
      if (response?.data?.getDropDetails) {
        extractAllDrops(response.data.getDropDetails);
      }
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  const fetchAndSetAllDropDetails = async () => {
    try {
      const shDetails = await getShipmentDetails(graphClient, shipmentId, carrierId);
      if (shDetails?.data?.getShipmentDetails) {
        setShipmentDetailsForDelivery(shDetails.data.getShipmentDetails);
        shDetails.data.getShipmentDetails.forEach(
          async (sd) => {
            // As sd.SK is always in format like: DeliveryNo#ORIGINAL#204077233#LID#820641 or
            // DeliveryNo#REDIRECT#204077232-R1#LID#820641, so:
            // 1. sd.SK.split('#')[2] is used to obatin the Delivery Docket number
            // 2. split('-')[0] is used to extract the Delivery Docket number without '-R'
            if (sd.Status !== statuses.Void && sd.SK.split('#')[2].split('-')[0] === deliveryId.split('-')[0]) {
              const response = await getDeliveryDropDetails(graphClient, sd);
              if (response?.data?.getDropDetails) {
                // We have to set the state here because drop detail fetch is async
                setDropDetailsAll(
                  (oldArray) => flatten([...oldArray, response.data.getDropDetails]),
                );
              }
            }
          },
        );
      }
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  const deleteDropCallback = async (resultMsg) => {
    await fetchAndSetDropDetails();
    // reset the dopdetails all after deleting the card and retrive again
    setDropDetailsAll([]);
    // setting shipment details as well as all drop details
    await fetchAndSetAllDropDetails();
    setDeleteResult(resultMsg);
    deleteResultAlertRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const deleteDropCallbackForAggregate = async () => {
    await fetchAndSetDropDetails();
    /* eslint-disable-next-line */
    for (const toBeDeleted of dropDetails) {
      /* eslint-disable no-await-in-loop */
      await deleteDropDetails(
        graphClient,
        { PK: toBeDeleted.PK, SK: toBeDeleted.SK },
        { DropId: toBeDeleted.DropId },
      ).catch((error) => handleGraphApiError(error));
    }
    await fetchAndSetDropDetails();
    setDropDetailsAll([]);
    await fetchAndSetAllDropDetails();
  };

  const [aggregateCreated, setAggregateCreated] = useState(false);

  // Check whether Aggregate Drop Detail Card has been created, if so,
  // only show the Aggregate Drop Detail Card
  const aggregateDropDetailsCreated = () => {
    removeDuplicate(dropDetailsAll).forEach((dropCheck) => {
      dropCheck.DROPS?.forEach((eachDropCheck) => {
        if (eachDropCheck.Aggregate === true) {
          setAggregateCreated(true);
        }
      });
    });
  };

  useEffect(() => {
    fetchAndSetAllDropDetails();
    fetchAndSetDropDetails();
  }, [deliveryId]);

  useEffect(() => {
    // aggregateFlagCondition();
    aggregateDropDetailsCreated();
  }, [shipmentDetails, dropDetailsAll]);

  const dropDetailsPresent = dropDetails && dropDetails.length > 0;

  return (
    <Container className="drop-details-container pl-0 pr-0" data-testid="drop-details-container">
      <NetworkSpeedChecker />
      <Link className="link" to="/delivery-docket" onClick={() => dropBackButtonClickHandler(pageTitleConstant.dropDetailSummary)}>
        <BackButton />
      </Link>

      <PageHeading label="Delivery docket number" refNumber={getShipmentHeader(deliveryId)} />

      {selectedDocket && !isOpenOrder && (
        <Row>
          <LidDetail
            shipmentDetails={shipmentDetails}
            dropDetailsAll={removeDuplicate(dropDetailsAll)}
            sendDataBackToParent={sendDataBackToParent}
          />
        </Row>
      )}

      {!isDocketCompleted && (
        <Row className="mb-4">
          <div ref={deleteResultAlertRef}>
            <Col xs="12">
              <div className="drop-details-form-title"><h5>Enter drop details</h5></div>
            </Col>

            <Col xs="12">
              <div className="drop-details-form-subtitle">Enter details for each drop, one at a time.</div>
            </Col>
          </div>
        </Row>
      )}

      <Row>
        <Col xs="12">
          <Alert color="info" isOpen={!!deleteResult} toggle={() => setDeleteResult()} className="delete-alert">
            <Info24 className="info-icon" />
            <Label className="mb-0 mt-2">{deleteResult}</Label>
          </Alert>
        </Col>
      </Row>

      <Row className="mb-6 mt-2">
        <Col xs="12">
          {!isDocketCompleted && (
            <PrimaryButton
              data-testid="add-another-drop-button"
              label="Add another drop"
              onClick={() => { history.replace('/enter-drop-details'); addAnotherTankClickHandler(pageTitleConstant.dropDetailSummary); }}
              disabled={lidStatus}
            />
          )}
        </Col>
      </Row>

      <Row data-testid="drop-details-page">
        {
          dropDetails
            ? (
              dropDetails.length > 0
                ? (
                  dropDetails.map((dropDetail, index) => (
                    (
                      (!!aggregateCreated && !!dropDetail.Aggregate === true)
                      || (!aggregateCreated && !dropDetail.Aggregate === true)
                    )
                    && (
                      // eslint-disable-next-line react/no-array-index-key
                      <Col xs="12" md="6" className="mb-6" key={`DropDetail-${index}`}>
                        <DropDetailCard
                          dropDetail={dropDetail}
                          deleteCallback={
                            dropDetail.Aggregate === true
                              ? deleteDropCallbackForAggregate
                              : deleteDropCallback
                          }
                          displayLoadId={!isOpenOrder}
                          hideEdit={!!aggregateCreated && !!dropDetail.Aggregate === true}
                        />
                      </Col>
                    )
                  ))
                )
                : (
                  <Col className="text-center">
                    <p>No drop details to show</p>
                  </Col>
                )
            ) : (
              <Col className="text-center">
                <p data-testid="authenticated-message">Loading ...</p>
                <Spinner id="welcome-spinner" className="mx-auto" />
              </Col>
            )
        }
      </Row>

      {!isDocketCompleted && (
        <Row className="mt-9">
          <Col xs="12">
            <Label className="font-12 mb-0">Please collect signatures after adding all the drop details.</Label>
          </Col>
          <Col xs="12">
            <LinkTile
              icon={ContractIcon}
              title="Signatures"
              disabled={!dropDetailsPresent}
              onClick={
                () => (
                  (lidStatus || isOpenOrder)
                    ? dispatch({ type: DeliveryDocketActions.OPEN_SIGNATURE_MODAL })
                    : setLidWarningStatus(true)
                ) || collectSignaturesClickHandler(pageTitleConstant.dropDetailSummary)
              }
            />

            <ConfirmActionModal
              data-testid="lid-warning-window"
              isOpen={lidWarningStatus}
              confirmMsg={lidWarningMessage}
              confirmSubMsg={supportMessage}
              handleCancel={() => { closeWindow(); productDischargeAlertNoTracker(); }}
              handleAction={continueSubmit}
            />
          </Col>
        </Row>
      )}

      <SignaturesFlow state={state} dispatch={dispatch} cleared={lidStatus && !isOpenOrder} />
    </Container>
  );
};

/**
 * We use oneOfType because
 * the application uses AppSyncClient
 * the tests/jest provide ApolloClient as we use the <MockProvider/>
 */
DropDetailsPage.propTypes = {
  graphClient: PropTypes.oneOfType([
    PropTypes.instanceOf(AWSAppSyncClient).isRequired,
    PropTypes.instanceOf(ApolloClient).isRequired,
  ]).isRequired,
};

// withClient will provide the client in props
export default withClient(DropDetailsPage);
