import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import './_secondaryButton.scss';

const SecondaryButton = ({ label, ...buttonProps }) => (
  <Button
    {...buttonProps}
    className={`${buttonProps.className} secondary-button`}
  >
    {label}
  </Button>

);

SecondaryButton.propTypes = {
  label: PropTypes.string.isRequired,
};

export default SecondaryButton;
