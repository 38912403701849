import React, { useState } from 'react';
import {
  Container, Row, Col, CustomInput, Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { extractAttributeFromCollection } from '../../helpers/attributeExtraction';
import './_productsCard.scss';

const ProductsCard = ({
  shipmentDetails,
}) => {
  const [loadIdAddon, setLoadIdAddon] = useState();

  const uniqLoadIdAddons = extractAttributeFromCollection(shipmentDetails, 'SK', 'LID#');
  const selectedShipmentDetail = shipmentDetails.find((sd) => sd.SK.includes(`LID#${loadIdAddon}`));

  const getSelectedProducts = (selectedShipmentDetail && selectedShipmentDetail.Products)
    ? selectedShipmentDetail.Products
    : [];
  const getSelectedVehicle = (selectedShipmentDetail && selectedShipmentDetail.TASVehicleID)
    ? selectedShipmentDetail.TASVehicleID
    : '';

  const handleLoadIdChange = (e) => {
    setLoadIdAddon(e.target.value);
  };

  if (!loadIdAddon && uniqLoadIdAddons.length > 0) {
    setLoadIdAddon(uniqLoadIdAddons[0]);
  }

  return (
    <Container
      className="products-container mt-4 p-4 p-md-6"
      data-testid="products-container"
    >
      <Row>
        <Col sm="12">
          <Label className="univers font-weight-light">Product(s) Loaded at Terminal</Label>
        </Col>

        <Col sm="12">
          <div className="divider" />
        </Col>
      </Row>

      <Row className="mb-5">
        <Col sm="12">
          <Label className="load-id-addon-label">Load ID Addon</Label>
        </Col>
        <Col sm="12" md="6">
          <CustomInput
            id="load-id-addon-select"
            data-testid="load-id-addon-select"
            type="select"
            onChange={handleLoadIdChange}
            value={loadIdAddon}
            className="mb-3"
            bsSize="lg"
            disabled={uniqLoadIdAddons.length <= 1}
          >
            {
              uniqLoadIdAddons.map(
                (addon) => (
                  <option
                    key={addon}
                    data-testid={`load-id-option-${addon}`}
                  >
                    {addon}
                  </option>
                ),
              )
            }
          </CustomInput>
        </Col>
      </Row>

      <Row className="mb-8">
        <Col xs="5" md="6">
          <Label className="product-label-name">Vehicle:</Label>
        </Col>
        <Col xs="7" md="6">
          <Label className="product-label-value">{getSelectedVehicle}</Label>
        </Col>
      </Row>

      {
        getSelectedProducts.map((product) => (
          <Row key={product.Name} className="mb-8" data-testid="product-details">
            <Col xs="5" md="6">
              <Label className="product-label-name">Product:</Label>
            </Col>
            <Col xs="7" md="6">
              <Label className="product-label-value">{product.Name}</Label>
            </Col>

            <Col xs="5" md="6">
              <Label className="product-label-name">Qty Ambient:</Label>
            </Col>
            <Col xs="7" md="6">
              <Label className="product-label-value">{product.QuantityAmbient}</Label>
            </Col>

            <Col xs="5" md="6">
              <Label className="product-label-name">Compartments:</Label>
            </Col>
            <Col xs="7" md="6">
              <Label className="product-label-value">{product.Compartments}</Label>
            </Col>
          </Row>
        ))
      }
    </Container>
  );
};

ProductsCard.propTypes = {
  shipmentDetails: PropTypes.arrayOf(PropTypes.shape({
    SK: PropTypes.string.isRequired,
    Products: PropTypes.arrayOf(PropTypes.shape({
      Name: PropTypes.string.isRequired,
      QuantityAmbient: PropTypes.string,
      Compartments: PropTypes.string,
    })),
  })).isRequired,
};

export default ProductsCard;
