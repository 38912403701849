import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import './_pageHeading.scss';

const PageHeading = ({ label, refNumber }) => (
  <Container className="page-heading-container p-0" data-testid="page-heading-container">
    <Row>
      <Col>
        <h6>{label}</h6>
      </Col>
    </Row>
    <Row>
      <Col>
        <h3 data-testid="page-heading-refnumber">{refNumber}</h3>
      </Col>
    </Row>
  </Container>
);

PageHeading.propTypes = {
  label: PropTypes.string.isRequired,
  refNumber: PropTypes.string.isRequired,
};

export default PageHeading;
