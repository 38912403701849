import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Info32, Search48, FileAdd48 } from '@bphxd/ds-core-react/icons';
import { useMsal } from '@azure/msal-react';
import './_landingPage.scss';
import { lookUpDeliveryClickTracker, createOpenOrderClickTracker } from '../../analytics/events';
import pageTitleConstant from '../../analytics/constantPageTitle';

const LandingPage = () => {
  const driverName = useMsal().accounts[0].name;

  return (
    <Container fluid className="landing-container m-0 p-0 h-100 d-flex justify-content-center" data-testid="landing-container">
      <Row className="ml-0 mr-0 ">
        <Col className="p-0">
          <Row className="ml-0 mr-0">
            <Col className="p-0">
              <div className="w-100 page-heading-row">
                <div className="welcome" data-testid="welcome">
                  <div>
                    Welcome
                    {' '}
                    {driverName}
                  </div>
                </div>
                <div className="page-heading" data-testid="page-heading">
                  <h4>Select an option below to begin your delivery.</h4>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="boxes-col p-0 d-flex align-items-center justify-content-center">
              <Row>
                <Link to="/shipment" onClick={() => lookUpDeliveryClickTracker(pageTitleConstant.landingPage)}>
                  <Col className="box-col p-0" data-testid="lookup-box">
                    <div className="box">
                      <div className="d-flex align-items-center justify-content-center">
                        <Search48 className="search-icon" />
                      </div>
                      <div className="box-text">
                        Look up a delivery
                      </div>
                    </div>
                  </Col>
                </Link>
                <Link to="/open-order" onClick={() => createOpenOrderClickTracker(pageTitleConstant.landingPage)}>
                  <Col className="box-col p-0" data-testid="open-order-box">
                    <div className="box">
                      <div className="d-flex align-items-center justify-content-center">
                        <FileAdd48 className="file-icon" />
                      </div>
                      <div className="box-text">
                        Create an open order
                      </div>
                    </div>
                  </Col>
                </Link>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="p-0">
              <Row className="sub-heading ml-4 mr-0 w-100" data-testid="sub-heading">
                <Col className="p-0">
                  <h5>When to create an open order</h5>
                </Col>
              </Row>

              <Row className="ml-4 mr-0">
                <Col className="d-flex align-items-center p-0">
                  <span>
                    <Info32 className="info-icon" />
                  </span>
                  <span className="help-text" data-testid="help-text">
                    Open Orders should only be created when you have approval from BP.
                    {' '}
                  </span>
                </Col>
              </Row>

            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingPage;
