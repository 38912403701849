import { extractAttributeFromKey, extractAttributesFromCollection } from './attributeExtraction';
import { statuses, orderTypes } from './shipmentStatus';

export const REDIRECT = 'REDIRECT';
export const ORIGINAL = 'ORIGINAL';
export const LID_KEY_VALUE = 'LID#';
export const INSTRUCTION_RESET = 'INSTRUCTIONS_RESET';

// Helper method to remove __typename from graphQl objects
// https://github.com/apollographql/apollo-feature-requests/issues/6
export const removeTypename = (value) => {
  if (value === null || value === undefined) {
    return value;
  } if (Array.isArray(value)) {
    return value.map((v) => removeTypename(v));
  } if (typeof value === 'object') {
    const newObj = {};
    Object.keys(value).forEach((key) => {
      if (key !== '__typename') {
        newObj[key] = removeTypename(value[key]);
      }
    });
    return newObj;
  }
  return value;
};

/**
 * Check if they key SK of an object has a value
 *
 * Example
 *
 * Check if 'DeliveryNo#REDIRECT#204076360-R1#LID#848749' contains 'REDIRECT'
 * @param {*} object must contain the key SK
 * @param {*} value value to check for
 */
// eslint-disable-next-line max-len
export const skHasValue = (object, value) => object.SK && object.SK.indexOf(value) > -1;

export const isUnmappedOpenOrder = (object) => object && object.OrderType
  && object.OrderType === orderTypes.Unmapped;

/**
 * Check if an object is redirected
 *
 * The object can be any schema that contains the SK key.
 *
 * DeliveryDocket, Signature, DropDetail etc.
 * @param {*} object must contain the SK key
 * @returns True if the SK is present and contains 'REDIRECT'
 */
export const isRedirected = (object) => {
  // A docket is redirect if the SK has the keyword REDIRECT
  const result = object.SK && (skHasValue(object, REDIRECT) || object.SK.match(/R\d+/));
  return !!result;
};

/**
 * Replace a value in a key
 *
 * Example
 *
 * Replace the LID value in the SK DROPDETAILS#DeliveryNo#ORIGINAL#8131085422#LID#334065
 * @param {*} key
 * @param {*} value
 */
export const replaceValueInKey = (object, key, value) => {
  // Find the index of the LID
  const indexOfLid = object[key].indexOf('LID#');
  const newSk = [object[key].substring(0, indexOfLid + 3), value].join('#');
  return newSk;
};

/**
 * Gets the delivery value from an object
 *
 * This method will return the number with any R postfix
 *
 * Valid object types are DeliveryDocket, Signature, DropDetail
 *
 * Example
 *
 * DeliveryNo#REDIRECT#204076360-R1#LID#848749 returns 204076360-R1
 * @param {*} object A Valid DeliveryDocket
 * @returns The delivery value
 */
export const getDeliveryValue = (object) => extractAttributesFromCollection([object], 'SK', ['DeliveryNo#ORIGINAL#', 'DeliveryNo#REDIRECT#', 'DeliveryNo#'])[0] || '';

/**
  * Get the delivery number from the object.
  *
  * Will return only the number component for the delivery value
  *
  * Valid object types are DeliveryDocket, Signature, DropDetail
  *
  * Example
  *
  * DeliveryNo#REDIRECT#204076360-R1#LID#848749 returns 204076360
  * @param {*} object Valid DeliveryDocket
  * @returns The delivery number only
  */
export const getDeliveryNumber = (object) => {
  const deliveryValue = getDeliveryValue(object);
  if (deliveryValue.indexOf('-') > -1) {
    return deliveryValue.split('-')[0];
  }
  return deliveryValue;
};

/**
 * Get the LID number from any object whose SK contains the LID
 * @param {*} object must contain the key SK
 * @returns the Lid number in the SK
 */
export const getLidNumber = (object) => {
  const lidNumber = extractAttributeFromKey(object.SK, LID_KEY_VALUE);
  return lidNumber;
};

/**
 * Generate a redirect delivery value
 *
 * Example
 *
 * 'DeliveryNo#ORIGINAL#204076360#LID#848749' would return 204076360-R1
 * @param {*} object Must contain the SK key
 * @returns Redirect delivery value
 */
export const generateRedirectDeliveryValue = (object) => {
  let redirectIndex = 1;
  if (isRedirected(object)) {
    // Get the R index number
    const docketDeliveryValue = getDeliveryValue(object);
    // Split the docket value with the '-'
    redirectIndex = parseInt(docketDeliveryValue.split('-')[1].slice(1), 10);
    // increment by 1
    redirectIndex += 1;
  }
  return `${getDeliveryNumber(object)}-R${redirectIndex}`;
};

export const generateDropDetailsSK = (docket, loadId) => (loadId ? `DROPDETAILS#${replaceValueInKey(docket, 'SK', loadId)}` : `DROPDETAILS#${docket.SK}`);

/**
 * Used to generated a redirected docket.
 *
 * Redirected Dockets have a 'REDIRECT' as part of their SK
 * @param {*} deliveryDocket Valid delivery docket
 * @param {*} redirectReason Redirect Reason
 * @param {*} otherText Redirect free text, only if reason is other
 * @returns Docket with updated SK and empty Shipment details
 */
export const generateRedirectedDocket = (deliveryDocket, redirectReason, otherText) => {
  let redirectIndex = 1;

  if (isRedirected(deliveryDocket)) {
    // Get the R index number
    const docketDeliveryValue = getDeliveryValue(deliveryDocket);
    // Split the docket value with the '-'
    redirectIndex = parseInt(docketDeliveryValue.split('-')[1].slice(1), 10);
    // increment by 1
    redirectIndex += 1;
  }

  const redirectedDocket = {
    ...deliveryDocket,
    SK: `DeliveryNo#REDIRECT#${getDeliveryNumber(deliveryDocket)}-R${redirectIndex}#LID#${getLidNumber(deliveryDocket)}`,
    Status: statuses.Loaded,
    ShipmentAddress: {
      ...deliveryDocket.ShipmentAddress,
      ShipToName: '',
      ShipToAddressLine1: '',
      ShipToAddressLine2: '',
      ShipToAddressLine3: '',
      ShipToCity: '',
      ShipToPostCode: '',
      ShipToNumber: '',
    },
    ReasonRedirect: redirectReason,
    ReasonOther: otherText,
  };
  return redirectedDocket;
};

/**
 * Used to update a docket in a collection of dockets to be 'REDIRECT'
 * @param {*} shipmentDetails Shipment details contain multiple dockets
 * @param {*} shipmentSK The SK of a docket that needs to be updated.
 * @param {*} redirectReason Redirect reason
 * @param {*} otherText Redirect free text, only populate if reason is other
 * @returns Shipment details which are a collection of dockets
 * with the correct docket updated to 'REDIRECT'
 */
export const generateRedirectedShipment = (
  shipmentDetails,
  shipmentSK,
  redirectReason,
  otherText,
) => {
  const newShipmentDetails = [];
  // If the SK contains original then start the new SK series with R1
  shipmentDetails.forEach((deliveryDocket) => {
    if (deliveryDocket.SK === shipmentSK) {
      newShipmentDetails.push(generateRedirectedDocket(deliveryDocket, redirectReason, otherText));
    }
  });
  return newShipmentDetails;
};

export const setDocketStatus = (docketDetails, status) => ({
  ...docketDetails,
  Status: status,
});

/**
 * Use this method to void a shipment.
 * @param {*} deliveryDocket DeliveryDocket that you want to void
 * @returns A DeliveryDocket with status set to void
 */
export const voidShipment = (deliveryDocket) => setDocketStatus(deliveryDocket, statuses.Void);

/**
 * Use this method to clone a shipment and remove the __typename as well as instructions
 * Because we are using the shipment that we fetched from the API it comes with __typename attached
 * to the object. We cannot use this object for a mutation because the __typename dont match
 * @param {ShipmentDetails} deliveryDocket ShipmentDetails that you want to clone
 * @returns A new ShipmentDetails object but without __typename
 */
export const cloneDocketWithoutTypeAndResetInstructionFields = (deliveryDocket) => removeTypename({
  PK: deliveryDocket.PK,
  SK: deliveryDocket.SK,
  Status: deliveryDocket.Status,
  BOLName: deliveryDocket.BOLName,
  BOLReference: deliveryDocket.BOLReference,
  DateUpdated: deliveryDocket.DateUpdated,
  DeliveryComments: INSTRUCTION_RESET,
  OrderNo: deliveryDocket.OrderNo,
  OrderType: deliveryDocket.OrderType,
  PlannedStartDate: deliveryDocket.PlannedStartDate,
  PONumber: deliveryDocket.PONumber,
  ShipmentAddress: deliveryDocket.ShipmentAddress,
  CustomerName: deliveryDocket.CustomerName,
  Products: deliveryDocket.Products,
  SAPPlantId: deliveryDocket.SAPPlantId,
  TASVehicleID: deliveryDocket.TASVehicleID,
  PermanentInstruction: INSTRUCTION_RESET,
  CustomerEmail: deliveryDocket.CustomerEmail,
  CarrierEmail: deliveryDocket.CarrierEmail,
  BPEmail: deliveryDocket.BPEmail,
  RedirectEmail: deliveryDocket.RedirectEmail,
  PlannedDriver: deliveryDocket.PlannedDriver,
  LoadingPlant: deliveryDocket.LoadingPlant,
  ReasonRedirect: deliveryDocket.ReasonRedirect,
  ReasonOther: deliveryDocket.ReasonOther,
});

/**
 * Use this method to clone a shipment and remove the __typename but keep instructions
 * Because we are using the shipment that we fetched from the API it comes with __typename attached
 * to the object. We cannot use this object for a mutation because the __typename dont match
 * @param {ShipmentDetails} deliveryDocket ShipmentDetails that you want to clone
 * @returns A new ShipmentDetails object but without __typename
 */
export const cloneDocketWithoutType = (deliveryDocket) => removeTypename({
  PK: deliveryDocket.PK,
  SK: deliveryDocket.SK,
  Status: deliveryDocket.Status,
  BOLName: deliveryDocket.BOLName,
  BOLReference: deliveryDocket.BOLReference,
  DateUpdated: deliveryDocket.DateUpdated,
  DeliveryComments: deliveryDocket.DeliveryComments,
  OrderNo: deliveryDocket.OrderNo,
  OrderType: deliveryDocket.OrderType, // cloneDocketWithoutType refers to typename, not OrderType
  PlannedStartDate: deliveryDocket.PlannedStartDate,
  PONumber: deliveryDocket.PONumber,
  ShipmentAddress: deliveryDocket.ShipmentAddress,
  CustomerName: deliveryDocket.CustomerName,
  Products: deliveryDocket.Products,
  SAPPlantId: deliveryDocket.SAPPlantId,
  TASVehicleID: deliveryDocket.TASVehicleID,
  PermanentInstruction: deliveryDocket.PermanentInstruction,
  CustomerEmail: deliveryDocket.CustomerEmail,
  CarrierEmail: deliveryDocket.CarrierEmail,
  BPEmail: deliveryDocket.BPEmail,
  RedirectEmail: deliveryDocket.RedirectEmail,
  PlannedDriver: deliveryDocket.PlannedDriver,
  LoadingPlant: deliveryDocket.LoadingPlant,
  ReasonRedirect: deliveryDocket.ReasonRedirect,
  ReasonOther: deliveryDocket.ReasonOther,
});

/**
 * Get the DeliveryNo value from the docket SK
 * Example REDIRECT#1234-1 | ORIGINAL#123
 * @param {*} deliveryDocket Valid delivery docket
 */
export const getDocketSkDeliveryValue = (deliveryDocket) => {
  const deliveryValue = getDeliveryValue(deliveryDocket);
  if (isRedirected(deliveryDocket)) {
    // This value contains and R and is therefore a redirect
    return `${REDIRECT}#${deliveryValue}`;
  }
  return `${ORIGINAL}#${deliveryValue}`;
};

/**
 * Check if the status value of a docket is voided.
 * You must pass just the Status value to this method.
 * If you want to pass the entire docket use isDocketVoid instead
 * @param {*} status Docket status value
 * @returns True is docket is voided
 */
export const isDocketStatusVoid = (status) => status.toLowerCase() === statuses.Void.toLowerCase();

/**
 * Check if a docket is voided.
 * @param {*} deliveryDocket Valid Delivery Docket
 * @returns True if the docket is void, vice-versa
 */
// eslint-disable-next-line max-len
export const isDocketVoid = (deliveryDocket) => isDocketStatusVoid(deliveryDocket.Status);

/**
 * Check the value of the docket status to be completed.
 * @param {*} status Docket status value
 * @returns True is docket is completed
 */
export const isDocketStatusComplete = (status) => {
  const lowerCaseStatus = status.toLowerCase();
  return lowerCaseStatus === statuses.Delivered.toLowerCase()
  || lowerCaseStatus === statuses.EmailSent.toLowerCase()
  || lowerCaseStatus === statuses.PDFGenerated.toLowerCase();
};

/**
 * Check if the ShipmentAddress of a docket is valid
 * Validity currently checks just if all values in the object are empty or not.
 * @param {*} deliveryDocket Valid delivery docket
 * @returns false if all values in ShipmentAddress are empty
 */
export const isDocketShipmentAddressInValid = (deliveryDocket) => {
  // ShipmentAddress fields must have some value
  const allValueEmpty = deliveryDocket.ShipmentAddress
    ? Object.values(deliveryDocket.ShipmentAddress).filter((value) => value !== 'ShipmentAddress').every((value) => value === '')
    : true;

  return allValueEmpty;
};
