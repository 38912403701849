import gql from 'graphql-tag';
import {
  cloneDocketWithoutType, cloneDocketWithoutTypeAndResetInstructionFields, removeTypename,
} from '../../helpers/shipmentEngine';

// Example PK: "ShipmentNo#660889#CARRIERID#605882"
export const GetShipmentDetails = gql`
  query GetShipmentDetails($pk: String!) {
    getShipmentDetails(PK: $pk) {
      PK
      SK
      Status
      BOLName
      BOLReference
      DateUpdated
      DeliveryComments
      OrderNo
      OrderType
      PlannedStartDate
      PONumber
      ShipmentAddress {
        ShipToName
        ShipToAddressLine1
        ShipToAddressLine2
        ShipToAddressLine3
        ShipToCity
        ShipToPostCode
        ShipToNumber
      }
      CustomerName
      Products {
        Name
        QuantityAmbient
        Compartments
      }
      SAPPlantId
      TASVehicleID
      PermanentInstruction
      CustomerEmail
      CarrierEmail
      BPEmail
      RedirectEmail
      PlannedDriver
      LoadingPlant
      ReasonRedirect
      ReasonOther
    }
  }
`;

export const PostShipmentDetails = gql`
mutation PostShipmentDetails($pk: String!, $sk: String!, $input: ShipmentDetailsInput!) {
  postShipmentDetails(PK: $pk,
    SK: $sk,
    input: $input
  )
}
`;

export const UpdateShipmentEmails = gql`
  mutation UpdateShipmentEmails($pk: String!, $sk: String!, $input: ShipmentEmailsInput) {
    updateShipmentEmails(PK: $pk, SK: $sk, input: $input){
      CarrierEmail
      CustomerEmail
    }
  }
`;

// Example PK: ShipmentNo#305107799#CARRIERID#6901478
// Example SK: DeliveryNo#ORIGINAL#8130284365#LID#838484
export const UpdateDocketStatus = gql`
  mutation UpdateShipmentStatus($pk: String!, $sk: String!, $status: String!) {
    updateShipmentStatus(PK: $pk, SK: $sk, Status: $status)
  }
`;

export const UpdateShipmentAddress = gql`
  mutation UpdateShipmentAddress($pk: String!, $sk: String!, $address: ShipmentAddressInput) {
    updateShipmentAddress(PK: $pk, SK: $sk, input: $address) {
      ShipmentAddress {
        ShipToName
        ShipToAddressLine1
        ShipToAddressLine2
        ShipToAddressLine3
        ShipToCity
        ShipToPostCode
        ShipToNumber
      }
    }
  }
`;

export const UpdateShipmentProducts = gql`
  mutation UpdateShipmentProducts($pk: String!, $sk: String!, $input: [ProductInput]) {
    updateShipmentProducts(PK: $pk, SK: $sk, input: $input) {
      Products {
        Name
        QuantityAmbient
        QuantityStandard
        Compartments
      }
    }
  }
`;

/**
 * Use this method after any operation that inserts or updates a docket
 * See {@link updateDocketStatus} for an example
 * @param {*} cache Graph client cache
 * @param {*} updatedDocket Updated docket that should be in the cache
 */
export const updateDocketInLocalCache = (cache, updatedDocket) => {
  const shipmentDetailsCache = cache.readQuery({
    query: GetShipmentDetails,
    variables: {
      pk: updatedDocket.PK,
    },
  });

  shipmentDetailsCache.getShipmentDetails = [
    ...shipmentDetailsCache.getShipmentDetails.filter((docket) => docket.SK !== updatedDocket.SK),
    { ...updatedDocket, __typename: 'ShipmentDetails' },
  ];

  cache.writeQuery({
    query: GetShipmentDetails,
    variables: {
      pk: updatedDocket.PK,
    },
    data: shipmentDetailsCache,
  });
};

/**
 * Use this method to fetch shipment details in any component.
 * See DeliverDocket.js for a good example of how this is used.
 * To successfully use this method its best to also use the withClient HOC
 * @param {Client} graphClient A GraphClient
 * @param {Number} shipmentId Shipment  Number
 * @param {Number} carrierId Carrier Number
 * @returns Shipment details for a shipment and carrier
 */
export const getShipmentDetails = async (graphClient, shipmentId, carrierId) => {
  const data = await graphClient.query({
    query: GetShipmentDetails,
    variables: {
      pk: `ShipmentNo#${shipmentId}#CARRIERID#${carrierId}`,
    },
    fetchPolicy: 'network-only',
  });
  return data;
};

/**
 * Use this method to create new docket details and remove instructions,
 * when a redirection is requested
 * This works for all redirections except for R1 genareated from partial redirect
 * as its delivery address does not change
 * This is most useful when we need to create new docket details when we redirect
 * @param {*} graphClient
 * @param {*} docketDetails
 */
export const postDocketDetails = async (graphClient, docketDetails, isOpenOrder) => {
  const clonedDocketWithoutType = cloneDocketWithoutTypeAndResetInstructionFields(docketDetails);
  const pk = docketDetails.PK;
  const sk = docketDetails.SK;

  delete clonedDocketWithoutType.PK;
  delete clonedDocketWithoutType.SK;

  await graphClient.mutate({
    mutation: PostShipmentDetails,
    variables: {
      pk,
      sk,
      input: clonedDocketWithoutType,
    },
    optimisticResponse: () => ({
      postShipmentDetails: {
        ...docketDetails,
        DeliveryComments: 'INSTRUCTIONS_RESET',
        PermanentInstruction: 'INSTRUCTIONS_RESET',
        __typename: 'ShipmentDetails',
      },
    }),
    update: ((cache, response) => {
      if (!isOpenOrder && typeof (response.data.postShipmentDetails) === 'object') {
        // Update the existing cache with a new object
        updateDocketInLocalCache(cache, response.data.postShipmentDetails);
      }
    }),
  });
};

/**
 * Use this method to create new docket details
 * but keep the instructions only for partial redirect R1.
 * This is most useful when we need to create new docket details when we redirect
 * @param {*} graphClient
 * @param {*} docketDetails
 */
export const postDocketDetailsKeepInstruction = async (graphClient, docketDetails) => {
  const clonedDocketWithoutType = cloneDocketWithoutType(docketDetails);
  const pk = docketDetails.PK;
  const sk = docketDetails.SK;

  delete clonedDocketWithoutType.PK;
  delete clonedDocketWithoutType.SK;

  await graphClient.mutate({
    mutation: PostShipmentDetails,
    variables: {
      pk,
      sk,
      input: clonedDocketWithoutType,
    },
    optimisticResponse: () => ({
      postShipmentDetails: { ...docketDetails, __typename: 'ShipmentDetails' },
    }),
    update: ((cache, response) => {
      if (typeof (response.data.postShipmentDetails) === 'object') {
        // Update the existing cache with a new object
        updateDocketInLocalCache(cache, response.data.postShipmentDetails);
      }
    }),
  });
};

/**
 * Use this method to update the status of a docket.
 * For example when we void a single docket we need to update its status
 * @param {*} graphClient
 * @param {*} docketDetails
 * @param {*} docketStatus
 */
export const updateDocketStatus = async (graphClient, docketDetails, docketStatus) => {
  await graphClient.mutate({
    mutation: UpdateDocketStatus,
    variables: {
      pk: docketDetails.PK,
      sk: docketDetails.SK,
      status: docketStatus,
    },
    optimisticResponse: () => ({
      updateShipmentStatus: {
        ...docketDetails,
        Status: docketStatus,
        __typename: 'ShipmentDetails',
      },
    }),
    update: (cache, response) => {
      if (typeof (response.data.updateShipmentStatus) === 'object') {
        // Update the existing cache with a new object
        updateDocketInLocalCache(cache, response.data.updateShipmentStatus);
      }
    },
  });
};

/**
 * Use this method to update docket item.
 * For example when we need to add carrier email to the docket
 * @param {*} graphClient
 * @param {*} docketDetails
 * @param {*} newShipToAddress
 */
export const updateShipmentEmails = async (
  graphClient, docketDetails, carrierEmail, shipToEmail,
) => {
  await graphClient.mutate({
    mutation: UpdateShipmentEmails,
    variables: {
      pk: docketDetails.PK,
      sk: docketDetails.SK,
      input: { CarrierEmail: carrierEmail, CustomerEmail: shipToEmail },
    },
    optimisticResponse: () => ({
      updateShipmentEmails: {
        CarrierEmail: carrierEmail,
        CustomerEmail: shipToEmail,
        __typename: 'ShipmentDetails',
      },
    }),
    update: (cache, response) => {
      updateDocketInLocalCache(cache,
        {
          ...docketDetails,
          ...response.data.updateShipmentEmails,
        });
    },
  });
};

export const updateShipmentAddress = async (graphClient, deliveryDocket, newShipToAddress) => {
  const shipmentAddressWithoutTypename = removeTypename(newShipToAddress);
  await graphClient.mutate({
    mutation: UpdateShipmentAddress,
    variables: {
      pk: deliveryDocket.PK,
      sk: deliveryDocket.SK,
      address: shipmentAddressWithoutTypename,
    },
    optimisticResponse: () => ({
      updateShipmentAddress: {
        ShipmentAddress: newShipToAddress,
        __typename: 'ShipmentDetails',
      },
    }),
    update: ((cache, response) => {
      updateDocketInLocalCache(cache,
        {
          ...deliveryDocket,
          ...response.data.updateShipmentAddress,
        });
    }),
  });
};

export const updateShipmentProducts = async (graphClient, deliveryDocket, products) => {
  const productsWithType = products.map((prod) => ({ ...prod, ...{ __typename: 'Product' } }));

  await graphClient.mutate({
    mutation: UpdateShipmentProducts,
    variables: {
      pk: deliveryDocket.PK,
      sk: deliveryDocket.SK,
      input: products,
    },
    optimisticResponse: () => ({
      updateShipmentProducts: {
        Products: productsWithType,
        __typename: 'ShipmentDetails',
      },
    }),
    update: ((cache, response) => {
      updateDocketInLocalCache(cache,
        {
          ...deliveryDocket,
          ...response.data.updateShipmentProducts,
        });
    }),
  });
};
