import React, {
  useContext, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Container, Col, Row, Label,
  Input, Dropdown, DropdownItem,
  DropdownToggle, DropdownMenu, InputGroup, InputGroupAddon, InputGroupText,
} from 'reactstrap';
import AWSAppSyncClient from 'aws-appsync';
import ApolloClient from 'apollo-client';
import { Link, useHistory } from 'react-router-dom';
import { Search32 } from '@bphxd/ds-core-react/icons';
import {
  DeliveryContext, useAppContext,
} from '../../state/GlobalState';
import withClient from '../../hoc/withClient';
import PageHeading from '../../components/PageHeading/PageHeading';
import BackButton from '../../components/BackButton/BackButton';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import {
  getShipmentDetails, updateShipmentAddress, updateShipmentEmails,
} from '../../api/graphQl/Shipment';
import './_addShipTo.scss';
import { skHasValue } from '../../helpers/shipmentEngine';
import {
  redirectBackClickHandler, locationDropdownClickHandler, updateDestinationClearClickHandler,
  updateDestinationSaveClickHandler, updateDestinationBackTracker,
} from '../../analytics/events';
import pageTitleConstant from '../../analytics/constantPageTitle';

const AddShipTo = ({ graphClient }) => {
  const history = useHistory();
  const [deliveryId] = useContext(DeliveryContext);

  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    carrierId, addresses, newShipToAddress, setNewShipToAddress, handleGraphApiError, shipmentId,
  } = useAppContext();
  const [isDDOpen, setIsDDOpen] = useState(false);

  const toggleDropdown = (val) => {
    setIsDDOpen(!val);
  };

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleItemSelect = (address) => () => {
    setNewShipToAddress(address);

    locationDropdownClickHandler(pageTitleConstant.updateDestination);
  };

  const clearShipToAddress = () => {
    setNewShipToAddress({});

    updateDestinationClearClickHandler(pageTitleConstant.updateDestination);
  };

  const updateShipToAddress = async () => {
    try {
      const details = await getShipmentDetails(
        graphClient,
        shipmentId,
        carrierId,
      );

      if (details?.data?.getShipmentDetails) {
        const fetchedShipmentDetails = details.data.getShipmentDetails;
        //added updateShipmentEmails by najpbr 19/02/24
        await Promise.all(
          fetchedShipmentDetails
            .filter((deliveryDocket) => skHasValue(deliveryDocket, deliveryId))
            .map((deliveryDocket) => {
              if (deliveryDocket.OrderType && deliveryDocket.OrderType === 'U') {
                updateShipmentEmails(graphClient, deliveryDocket, newShipToAddress.CarrierEmail || '', newShipToAddress.ShipToEmail || '');
              }
              updateShipmentEmails(graphClient, deliveryDocket, newShipToAddress.CarrierEmail || '', newShipToAddress.ShipToEmail || '');
              updateShipmentAddress(graphClient, deliveryDocket, newShipToAddress);
              return deliveryDocket;
            }),
        );
        // Clear the address for other shipments once its saved
        setNewShipToAddress({});
        history.push('/delivery-docket');
      }
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  const handleSaveAddress = async () => {
    setLoading(true);
    await updateShipToAddress();
    setLoading(false);

    updateDestinationSaveClickHandler(pageTitleConstant.updateDestination);
  };

  const handleBackButton = () => {
    setNewShipToAddress({});

    redirectBackClickHandler();
  };

  const isAddressValid = (newAddress) => (newAddress && Object.keys(newAddress).length === 0)
    || (newAddress
      && Object.values(newAddress).filter((val) => val !== 'ShipmentAddress').every((val) => val === '')
    );

  const newAddressBox = () => {
    if (isAddressValid(newShipToAddress)) {
      return (
        <Row className="new-address align-items-center justify-content-center">
          <Label className="univers font-weight-light noAddressLabel" data-testid="no-address-msg">No address selected</Label>
        </Row>
      );
    }

    return (
      <Row>
        <Col className="new-address p-4">
          <Row className="address-content" data-testid="address-content">
            <Col>
              <Label className="ship-to-details-name" data-testid="ship-to-details-name">{newShipToAddress?.ShipToName}</Label>

              { newShipToAddress?.ShipToNumber
              && (
              <Label className="ship-to-details" data-testid="ship-to-number">
                {` (${newShipToAddress?.ShipToNumber}) `}
              </Label>
              ) }

              { newShipToAddress?.ShipToAddressLine1
              && <Label className="ship-to-details" data-testid="ship-to-address1">{newShipToAddress.ShipToAddressLine1}</Label> }

              { newShipToAddress?.ShipToAddressLine2
              && <Label className="ship-to-details" data-testid="ship-to-address2">{newShipToAddress.ShipToAddressLine2}</Label> }

              { newShipToAddress?.ShipToAddressLine3
              && <Label className="ship-to-details" data-testid="ship-to-address3">{newShipToAddress.ShipToAddressLine3}</Label> }

            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Label className="univers clear-address" data-testid="clear-address" onClick={clearShipToAddress}>Clear address </Label>
          </Row>
        </Col>
      </Row>
    );
  };

  const dropDownList = () => {
    if (!addresses) return <DropdownItem>No results found</DropdownItem>;
    const filtered = addresses?.filter((address) => address.ShipToName.toLowerCase()
      .includes(searchText.toLowerCase())
    || address.ShipToNumber.includes(searchText));
    if (filtered.length === 0) {
      return (<DropdownItem data-testid="no-results">No results found</DropdownItem>);
    }

    return (filtered.map((address) => (
      <DropdownItem key={address.ShipToNumber} onClick={handleItemSelect(address)} data-testid={`address-id-${address.ShipToNumber}`}>
        {`${address.ShipToName} (${address.ShipToNumber})`}
      </DropdownItem>
    )));
  };
  return (
    <Container data-testid="AddShipTo">
      <Row>
        <Col>
          <Row>
            <Link className="link" to="/delivery-docket" onClick={() => updateDestinationBackTracker(pageTitleConstant.updateDestination)}>
              <BackButton onClick={handleBackButton} />
            </Link>
          </Row>
          <Row>
            <PageHeading label="Delivery Docket Number" refNumber={deliveryId} />
          </Row>
          <Row>
            <div className="univers font-weight-light" data-testid="tile-title">Add new destination</div>
          </Row>
          <Row>
            <Label className="univers font-weight-light pt-4" data-testid="tile-title">Find a location:</Label>
          </Row>
          <Row>
            <Dropdown className="w-100" toggle={() => toggleDropdown(isDDOpen)} isOpen={isDDOpen}>
              <DropdownToggle
                data-toggle="dropdown"
                tag="span"
              >
                <InputGroup className="input-group-merge mb-4">
                  <Input
                    placeholder="Search..."
                    className="form-control-appended"
                    value={searchText}
                    onChange={handleInputChange}
                    data-testid="input-shipto"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <Search32 />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </DropdownToggle>
              <Label className="univers font-weight-light shipToSearchLabel">
                Search by &apos;Ship To number&apos; or a location&apos;s name
                (e.g. BP Concord) to see options.
              </Label>
              <DropdownMenu data-testid="dropdown-menu" className="w-100 dropDownResults" style={{ transform: 'translate3d(0px, 3.5em, 0px)' }}>
                { dropDownList() }
              </DropdownMenu>
            </Dropdown>
          </Row>
          { newAddressBox() }
          <Row>
            <Col xs="12">
              <Row>
                <PrimaryButton
                  disabled={isAddressValid(newShipToAddress)}
                  className="submit-button"
                  data-testid="save-add-ship-to"
                  type="Submit"
                  label="Save"
                  loading={loading}
                  onClick={handleSaveAddress}
                />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

/**
 * We use oneOfType because
 * the application uses AppSyncClient
 * the tests/jest provide ApolloClient as we use the <MockProvider/>
 */
AddShipTo.propTypes = {
  graphClient: PropTypes.oneOfType([
    PropTypes.instanceOf(AWSAppSyncClient).isRequired,
    PropTypes.instanceOf(ApolloClient).isRequired,
  ]).isRequired,
};
// withClient will provide the client in props
export default withClient(AddShipTo);
