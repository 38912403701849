import React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import DefaultLayout from '../layouts/Default/DefaultLayout';
import { useAppContext } from '../state/GlobalState';

/**
 * Allows a route to be rendered if a user is authenticated.
 * If there are no authenticated users then redirect to root.
 */
const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const isAuthenticated = useIsAuthenticated();
  const { driverAuthError } = useAppContext();
  return (
    <Route
      {...rest}
      render={() => (isAuthenticated && !driverAuthError ? <Layout><Component /></Layout> : <Redirect to="/" />)}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  layout: PropTypes.elementType,
};

PrivateRoute.defaultProps = {
  layout: DefaultLayout,
};

export default PrivateRoute;
