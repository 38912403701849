import AWSAppSyncClient, { createAppSyncLink } from 'aws-appsync';
import { RetryLink } from 'apollo-link-retry';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { PublicClientApplication } from '@azure/msal-browser';

import AppSyncConfig from './aws-exports';
import { readToken, writeToken, writeNetworkSpeedToken } from '../helpers/localStorage';
import { acquireTokenRequest, msalConfig } from '../auth/msal-config';

// Configuration object constructed for MSAL.
const config = msalConfig;
const publicClientApplication = new PublicClientApplication(config);

// local Variable
const refreshTokenLimit = 1;
const maxRetry = 3;
const delayHttpRequest = 1000;
let retryCountCurrent = 0;
const auth = {
  type: AppSyncConfig.awsAppsyncAuthenticationType,
};

const acquireAccessToken = (msalInstance) => {
// return a non-null value if you have logic somewhere else that calls the setActiveAcretryCount API
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();
  if ((!activeAccount && accounts.length === 0) || retryCountCurrent >= maxRetry) {
    console.log('calling logout at acquire token');
    // logoutSession(msalInstance, retryCountCurrent);
    return new Promise((resolve, reject) => reject(new Error('signout_user_2000')));
  }
  acquireTokenRequest.account = accounts[0];
  acquireTokenRequest.forceRefresh = true;

  const refreshPromise = new Promise(
    (resolve, reject) => resolve(msalInstance.acquireTokenSilent(acquireTokenRequest))
      .catch(reject(Error({
        message: 'signout_user_2000',
      }))),
  );
  return refreshPromise;
};

const loggerLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: new Date() });
  return forward(operation).map((response, error) => {
    const responseTime = new Date() - operation.getContext().start;
    console.log(`GraphQL Response for ${operation.operationName} took : ${responseTime}`);
    writeNetworkSpeedToken(responseTime);
    if (!error) {
      retryCountCurrent = 0;
    }
    return response;
  });
});

const handleRetry = (error) => {
  let requiresRetry = false;
  const token = readToken() || null;
  // create new token if its returns 401 and no old request is done
  if (error.statusCode === 401 || (!token || token === '')) {
    requiresRetry = true;
    retryCountCurrent += 1;
    // fetch new token on 1st fail
    if ((retryCountCurrent && retryCountCurrent === refreshTokenLimit) || (!token || token === '')) {
      console.log('regenarting new token now for retryCount=', retryCountCurrent);
      acquireAccessToken(publicClientApplication).then((authResult) => {
        writeToken(authResult.idToken);
      }).catch((err) => {
        console.log('got err at acquiring token', err?.message);
        requiresRetry = false;
        throw err;
      });
    }
  }

  // on last retry bring user to signout page
  if (retryCountCurrent >= maxRetry) {
    throw (Error({
      message: 'signout_user_2000',
    }));
  }
  return requiresRetry;
};

const retryLink = new RetryLink({
  // Attempts are retry http call in case of HTTP errors.
  // Delay is number of MS to wait before next retry http call.
  delay: {
    initial: delayHttpRequest,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: maxRetry,
    retryIf: (error, operation) => handleRetry(error, operation),
  },
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      // eslint-disable-next-line no-param-reassign
      message = 'signout_user_2000';
    });
  }
  /* Commented for now as it will log us out when doing redirections in offline mode */
  /* As we has a network error existed in our production code tagged 3.0.o */
  /* This is left in case we need to handle the network error in the future */
  // if (networkError) {
  //   console.log(`[Network error]: ${networkError}`);
  //   // eslint-disable-next-line no-param-reassign
  //   networkError.message = 'signout_user_2000';
  // }
});

const cache = new InMemoryCache();

const appSyncLink = createAppSyncLink({
  url: AppSyncConfig.awsAppsyncGraphqlEndpoint,
  region: AppSyncConfig.awsAppsyncRegion,
  disableOffline: false,
  auth,
});

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: readToken() || null,
    },
  });
  return forward(operation);
});

// concat section
const httpAuthLink = middlewareLink.concat(appSyncLink);

const link = ApolloLink.from([loggerLink, errorLink, retryLink, httpAuthLink]);

const appSyncClient = new AWSAppSyncClient({ disableOffline: false }, { link, cache });

export { appSyncClient, publicClientApplication };
