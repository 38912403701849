export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_aad_client_id,
    authority:
        `https://login.microsoftonline.com/${process.env.REACT_APP_aad_tenant_id}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const selectAccountRequest = {
  scopes: ['user.read'],
  prompt: 'select_account',
};

export const acquireTokenRequest = {
  scopes: ['User.Read'],
  account: {},
};
