import React, { useContext } from 'react';
import { Container } from 'reactstrap';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './_deliveryDockets.scss';
import sortBy from 'lodash/sortBy';
import FileIcon from '../../icons/FileIcon';
import LinkTile from '../LinkTile/LinkTile';
import { DeliveryContext } from '../../state/GlobalState';
import { getDeliveryValue } from '../../helpers/shipmentEngine';
import { newDocketClickHandler, roDocketClickHandler } from '../../analytics/events';
import { statuses, statusesSortRank } from '../../helpers/shipmentStatus';
import { getShipmentHeader } from '../../helpers/openOrders';
import pageTitleConstant from '../../analytics/constantPageTitle';

const DeliveryDockets = ({ shipmentDetails }) => {
  const history = useHistory();
  const [, setDeliveryId] = useContext(DeliveryContext);

  // In case with multiple LIDs (Scen. 3) we're getting multiple shipments for different LIDs
  // So we have to order by the "higher" status of shipment with the same DeliveryNo
  const shipmentDetailsSortedByStatus = sortBy(
    shipmentDetails,
    (element) => statusesSortRank[element.Status],
  );

  const uniqueDocketsMappedByDeliveryNo = new Map();
  shipmentDetailsSortedByStatus.forEach((docket) => {
    uniqueDocketsMappedByDeliveryNo.set(getDeliveryValue(docket), docket);
  });

  const uniqueDocketNumbers = sortBy(
    Array.from(uniqueDocketsMappedByDeliveryNo.values()),
    (element) => getDeliveryValue(element),
  );

  const goToDeliveryDockets = (deliveryNo, status) => {
    setDeliveryId(deliveryNo);
    history.push('/delivery-docket');

    if (status === statuses.Loaded) {
      newDocketClickHandler(pageTitleConstant.shipmentDetailsPage);
    } else if (status === statuses.Delivered
      || status === statuses.PDFGenerated
      || status === statuses.EmailSent
    ) {
      roDocketClickHandler(pageTitleConstant.shipmentDetailsPage);
    }
  };

  return (
    <Container className="delivery-dockets-container p-0">
      {
        uniqueDocketNumbers.map((deliveryDocket) => (
          <LinkTile
            icon={FileIcon}
            title="Delivery Docket"
            line1={deliveryDocket?.ShipmentAddress?.ShipToName}
            line2={getShipmentHeader(getDeliveryValue(deliveryDocket))}
            key={deliveryDocket.SK}
            docketStatus={deliveryDocket.Status}
            onClick={() => goToDeliveryDockets(getDeliveryValue(deliveryDocket),
              deliveryDocket.Status)}
          />
        ))
      }
    </Container>
  );
};

DeliveryDockets.propTypes = {
  shipmentDetails: PropTypes.arrayOf(PropTypes.shape({
    SK: PropTypes.string.isRequired,
  })).isRequired,
};

export default DeliveryDockets;
