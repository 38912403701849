import React, { useState } from 'react';
import {
  Container, Col, Row, Table,
} from 'reactstrap';
import gql from 'graphql-tag';
import { ApolloConsumer } from 'react-apollo';
import { GetDriver, CreateDriver } from '../../api/graphQl/Driver';

const DriverPage = () => {
  const [driverData, setDriverData] = useState({});
  const [newId, setNewId] = useState();
  const [newDriverStatus, setNewDriverStatus] = useState();
  const [newEmail, setNewEmail] = useState();
  const [newCarrier, setNewCarrier] = useState();

  const handleID = (event) => {
    setNewId(event.target.value);
  };

  const handleDriverStatus = (event) => {
    setNewDriverStatus(event.target.value);
  };

  const handleEmail = (event) => {
    setNewEmail(event.target.value);
  };

  const handleCarrier = (event) => {
    setNewCarrier(event.target.value);
  };

  const createDriverButton = (client) => {
    const newDriver = {
      id: newId,
      CarrierID: newCarrier,
      DriverStatus: newDriverStatus,
      Email: newEmail,
    };
    console.log('Trying to create a new driver with the following values');
    console.log(newDriver);
    client.mutate({
      mutation: gql(CreateDriver),
      variables: {
        ...newDriver,
      },
    }).then((data) => {
      console.log('Successful mutation');
      const newDriverData = { ...data.data.getDriver };
      setDriverData(newDriverData);
    }).catch((err) => console.log(err));
  };

  const updateDriverId = (event, client) => {
    if (event.key === 'Enter') {
      // Temporarily attaching load id to fetch TODOS as an example
      // Will replace this with Shipment data soon
      const driverId = event.target.value;
      // setDriverData({id: driverId});
      // console.log(driverData);
      console.log('YOU HIT ENTER');
      console.log('Looking for a driver...');
      client.query({
        query: gql(GetDriver),
        variables: {
          id: driverId,
        },
      }).then((data) => {
        console.log('Success');
        const updatedDriverData = { ...data.data.getDriver };
        setDriverData(updatedDriverData);
      }).catch((err) => console.log(err));
    }
  };

  return (
    <ApolloConsumer>
      {
        (client) => (
          <Container>
            <Row>
              <Col>
                <h2>Driver API</h2>
                <div>Driver id: </div>
                <input type="text" onKeyDown={(e) => updateDriverId(e, client)} placeholder="Enter driver id here" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>DriverStatus</th>
                      <th>Email</th>
                      <th>FirstName</th>
                      <th>LastActionType</th>
                      <th>LastName</th>
                      <th>SAPDriverID</th>
                      <th>TASDriverCode</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{driverData.id}</td>
                      <td>{driverData.DriverStatus}</td>
                      <td>{driverData.Email}</td>
                      <td>{driverData.FirstName}</td>
                      <td>{driverData.LastActionType}</td>
                      <td>{driverData.LastName}</td>
                      <td>{driverData.SAPDriverID}</td>
                      <td>{driverData.TASDriverCode}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>DriverStatus</th>
                      <th>Email</th>
                      <th>CarrierID</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><input type="number" onChange={handleID} /></td>
                      <td><input type="text" onChange={handleDriverStatus} /></td>
                      <td><input type="text" onChange={handleEmail} /></td>
                      <td><input type="number" onChange={handleCarrier} /></td>
                    </tr>
                  </tbody>
                </Table>
                <button type="button" onClick={() => createDriverButton(client)}>CREATE</button>
              </Col>
            </Row>
          </Container>
        )
      }
    </ApolloConsumer>
  );
};

export default DriverPage;
