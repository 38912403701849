import * as Yup from 'yup';

export const invalidReferenceNoMsg = 'TAS Reference/BOL/Load ID can only contain up to 7 numeric digits. Try again.';

export const initialValues = {
  ReferenceNo: '',
  LoadingPlant: '',
  Vehicle: '',
  Photo: '',
};

export const CreateOpenOrderSchema = Yup.object().shape({
  ReferenceNo: Yup.string()
    .required()
    .matches(/^[0-9]{1,7}$/, invalidReferenceNoMsg)
    .label('TAS Reference/BOL/Load ID'),
  LoadingPlant: Yup.string()
    .required()
    .label('Loading Plant'),
  Vehicle: Yup.string()
    .required()
    .label('Vehicle Number'),
  // Photo field is not validated with Yup
  // Listing it here to represent the open order structure
  Photo: Yup.mixed()
    .label('BOL Photo'),
});
