import React from 'react';

const TimeOutSadIcon = () => (
  <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M48.7224 31.1385C50.3408 31.1385 51.6528 29.8265 51.6528 28.2081C51.6528 26.5897 50.3408 25.2777 48.7224 25.2777C47.104 25.2777 45.792 26.5897 45.792 28.2081C45.792 29.8265 47.104 31.1385 48.7224 31.1385Z" fill="#009900" />
    <path d="M25.2771 31.1385C26.8955 31.1385 28.2075 29.8265 28.2075 28.2081C28.2075 26.5897 26.8955 25.2777 25.2771 25.2777C23.6587 25.2777 22.3467 26.5897 22.3467 28.2081C22.3467 29.8265 23.6587 31.1385 25.2771 31.1385Z" fill="#009900" />
    <path d="M47.9518 46.324C44.7923 43.9573 40.951 42.678 37.0034 42.678C33.0559 42.678 29.2146 43.9573 26.0551 46.324C22.9717 48.6463 20.6963 51.879 19.5505 55.5652C19.4432 55.9761 19.4987 56.4127 19.7054 56.7838C19.9121 57.1549 20.254 57.4319 20.6599 57.5571C21.0658 57.6823 21.5043 57.646 21.8841 57.4558C22.2639 57.2655 22.5557 56.9361 22.6985 56.536C23.6373 53.5142 25.5028 50.8643 28.0309 48.9614C30.6193 47.0231 33.766 45.9755 36.9997 45.9755C40.2335 45.9755 43.3801 47.0231 45.9686 48.9614C48.4967 50.8643 50.3622 53.5142 51.301 56.536C51.4338 56.9488 51.7237 57.2926 52.1081 57.493C52.4926 57.6935 52.9405 57.7345 53.3549 57.6071C53.7693 57.4798 54.1169 57.1943 54.3223 56.8125C54.5278 56.4307 54.5746 55.9834 54.4527 55.5674C53.3083 51.8809 51.0342 48.6474 47.9518 46.324Z" fill="#009900" />
    <path d="M63.0333 10.9668C56.0795 4.01303 46.8339 0.183533 37.0001 0.183533C27.1662 0.183533 17.9207 4.01303 10.9669 10.9668C4.01309 17.9206 0.183594 27.1662 0.183594 37C0.183594 46.8339 4.01309 56.0794 10.9669 63.0332C17.9207 69.987 27.1662 73.8165 37.0001 73.8165C46.8339 73.8165 56.0795 69.987 63.0333 63.0332C69.9871 56.0794 73.8166 46.8339 73.8166 37C73.8166 27.1662 69.9871 17.9206 63.0333 10.9668ZM60.7023 60.7022C54.3708 67.0329 45.9541 70.522 37.0001 70.522C28.0461 70.522 19.6293 67.0329 13.2979 60.7022C6.96643 54.3715 3.47807 45.954 3.47807 37C3.47807 28.046 6.96717 19.6293 13.2979 13.2978C19.6286 6.96637 28.0461 3.47801 37.0001 3.47801C45.9541 3.47801 54.3708 6.96711 60.7023 13.2978C67.0337 19.6285 70.5221 28.046 70.5221 37C70.5221 45.954 67.033 54.3708 60.7023 60.7022Z" fill="#009900" />
  </svg>
);

export default TimeOutSadIcon;
