import gql from 'graphql-tag';

export const GetS3PreSignedUrlQuery = gql`
  query GetS3PreSignedUrl($fileName: String!, $fileExtension: String!, $mimeType: String!) {
    getS3PreSignedUrl(FileName: $fileName, FileExtension: $fileExtension, MimeType: $mimeType)
  }
`;

/**
 *
 * @param {Client} graphClient graphql client
 * @param {String} fileName string
 * @param {String} fileExtension string
 * @param {String} mimeType string
 * @returns Presigned S3 url
 */
export const getS3PreSignedUrl = async (graphClient, fileName, fileExtension, mimeType) => {
  const data = await graphClient.query({
    query: GetS3PreSignedUrlQuery,
    variables: {
      fileName,
      fileExtension,
      mimeType,
    },
    fetchPolicy: 'network-only', // User has to be online
  });
  return data;
};
