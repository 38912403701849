import gql from 'graphql-tag';

export const GetLoadingPlantsQuery = gql`
query GetLoadingPlants($pk: String!) {
  getLoadingPlants(PK: $pk) {
    PK
    SK
    PLANTS {
      PlantID
      PlantName
    }
  }
}
`;

/**
 *
 * @param {Client} graphClient graphql client
 * @param {Number} carrierId Carrier number
 * @returns Loading Plants for a carrier
 */
export const getLoadingPlants = async (graphClient, carrierId) => {
  const data = await graphClient.query({
    query: GetLoadingPlantsQuery,
    variables: {
      pk: `CARRIERID#${carrierId}`,
    },
    fetchPolicy: 'network-only', // User has to be online
  });
  return data;
};
