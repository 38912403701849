/* eslint-disable no-param-reassign */
import {
  updateDocketStatus, postDocketDetails, postDocketDetailsKeepInstruction,
  updateShipmentAddress, updateShipmentProducts,
} from '../api/graphQl/Shipment';
import { DeliveryDocketActions, RedirectMode } from '../pages/DeliveryDocket/DeliveryDocketReducer';
import {
  getDriverSignature, getCustomerSignature, submitCustomerSignature, submitDriverSignature,
} from '../api/graphQl/Signature';
import {
  generateRedirectedDocket,
  generateRedirectDeliveryValue,
  generateDropDetailsSK,
  skHasValue,
  getLidNumber,
  removeTypename,
  isRedirected,
  isUnmappedOpenOrder,
} from './shipmentEngine';
import { statuses } from './shipmentStatus';
import { getRedirectDeliveryDropDetails, createDropDetails } from '../api/graphQl/DropDetails';
import { sumProducts, withProductTypename } from './openOrders';

export const getStatusForDD = async (graphClient, deliveryDocket) => {
  const dropDetailsResponse = await getRedirectDeliveryDropDetails(
    graphClient, deliveryDocket,
  );
  return dropDetailsResponse.length > 0 ? statuses.Delivered : statuses.Void;
};
export const redirectShipment = async (
  graphClient,
  dispatch,
  deliveryDocket,
  redirectReason,
  otherText,
  redirectMode,
  driverId,
) => {
  dispatch({
    type: DeliveryDocketActions.UPDATE_MODAL_LOADING_MESSAGE,
    payload: 'Please wait while we redirect this shipment, this could take a few mins',
  });

  // This is for the regular (non-open order) shipments.
  // GraphQl returns null instead of empty string if there is no value,
  // and backend does type check that expects a string value, not null,
  // so we have to convert null to empy string here.
  if (!deliveryDocket.OrderType) {
    deliveryDocket.OrderType = '';
  }

  // Follow the the comments to understand the flow better
  // We always generate a redirect docket from the current
  const redirectedDocket = generateRedirectedDocket(
    deliveryDocket,
    redirectReason,
    otherText,
  );

  if (redirectMode === RedirectMode.FULL) {
    dispatch({
      type: DeliveryDocketActions.UPDATE_MODAL_LOADING_MESSAGE,
      payload: 'Updating docket status',
    });

    await updateDocketStatus(graphClient, deliveryDocket, statuses.Void);

    dispatch({
      type: DeliveryDocketActions.UPDATE_MODAL_LOADING_MESSAGE,
      payload: 'Creating new docket',
    });
    // Create the redirect docket, This could be R1, R2, R[n] as Loaded
    await postDocketDetails(graphClient, redirectedDocket);
    // For R1 generated from full redirect, both delivery&permanent instructions should be removed.
    // So we called postDocketDetails() function to generate a new docket with instructions reset.
    return redirectedDocket;
  }

  if (redirectMode === RedirectMode.PARTIAL) {
    // Before a new docket is generated after a partial redirect, we need to judge whether it is R1
    // As R1 comes with the same deails as the original docket so instuctions should not be reset
    // We called postDocketDetailsKeepInstruction() for R1 and postDocketDetails() for the rest
    if (isRedirected(deliveryDocket)) {
      await postDocketDetails(graphClient, redirectedDocket);
    } else {
      await postDocketDetailsKeepInstruction(graphClient, redirectedDocket);
    }
    if (isRedirected(deliveryDocket)) {
      dispatch({
        type: DeliveryDocketActions.UPDATE_MODAL_LOADING_MESSAGE,
        payload: 'Updating docket status',
      });

      const status = await getStatusForDD(graphClient, deliveryDocket);
      deliveryDocket.Status = status;

      await updateDocketStatus(
        graphClient,
        deliveryDocket,
        status,
      );

      if (isUnmappedOpenOrder(deliveryDocket)) {
        dispatch({
          type: DeliveryDocketActions.UPDATE_MODAL_LOADING_MESSAGE,
          payload: 'Saving products',
        });

        const dropDetailsForRedirect = await getRedirectDeliveryDropDetails(
          graphClient, deliveryDocket,
        );

        const products = sumProducts(dropDetailsForRedirect);
        await updateShipmentProducts(graphClient, deliveryDocket, products);
        deliveryDocket.Products = withProductTypename(products);
      }

      dispatch({
        type: DeliveryDocketActions.UPDATE_MODAL_LOADING_MESSAGE,
        payload: 'Creating new docket',
      });
    } else {
      await updateShipmentAddress(graphClient, redirectedDocket, deliveryDocket.ShipmentAddress);
      redirectedDocket.ShipmentAddress = deliveryDocket.ShipmentAddress;

      // Get the signature for R[n]
      const signatureData = await getCustomerSignature(graphClient, deliveryDocket);

      // Get the new SK as R[n+1]
      const redirectDeliveryId = generateRedirectDeliveryValue(
        signatureData?.getCustomerSignature,
      );

      dispatch({
        type: DeliveryDocketActions.UPDATE_MODAL_LOADING_MESSAGE,
        payload: 'Saving customer signature data',
      });

      // Save the signature
      await submitCustomerSignature(
        graphClient,
        deliveryDocket,
        redirectDeliveryId,
        signatureData?.getCustomerSignature.CustomerSignature,
        signatureData?.getCustomerSignature.CustomerName,
        signatureData?.getCustomerSignature.NoSignature,
        driverId,
        signatureData?.getCustomerSignature.Longitude,
        signatureData?.getCustomerSignature.Latitude,
      );

      // Get the driver signature R[n]
      const driverSignatureData = await getDriverSignature(
        graphClient, deliveryDocket,
      );

      // Get the new SK as R[n+1]
      const redirectedDriverSignatureDeliveryId = generateRedirectDeliveryValue(
        driverSignatureData?.getDriverSignature,
      );

      dispatch({
        type: DeliveryDocketActions.UPDATE_MODAL_LOADING_MESSAGE,
        payload: 'Saving driver signature data',
      });

      // Save the driver signature
      await submitDriverSignature(graphClient,
        deliveryDocket,
        redirectedDriverSignatureDeliveryId,
        driverSignatureData?.getDriverSignature.DriverSignature,
        driverSignatureData?.getDriverSignature.CreatedBy);

      dispatch({
        type: DeliveryDocketActions.UPDATE_MODAL_LOADING_MESSAGE,
        payload: 'Getting drop details',
      });

      // Get the drop details R[n]
      const dropDetailsResponse = await getRedirectDeliveryDropDetails(
        graphClient, deliveryDocket,
      );

      // Get the new drop detail SK for docket as R[n+1]
      const redirectedDropDetailsSK = generateDropDetailsSK(redirectedDocket, null, true);

      dispatch({
        type: DeliveryDocketActions.UPDATE_MODAL_LOADING_MESSAGE,
        payload: 'Saving drop details',
      });

      // This is by design from the API that we dont upload the Drop in one payload
      // Must be done one by one.
      const dropDetailsForDocketLid = dropDetailsResponse && dropDetailsResponse
        .find((dDetail) => skHasValue(dDetail, getLidNumber(deliveryDocket)));
      if (dropDetailsForDocketLid && dropDetailsForDocketLid.DROPS) {
        // We have used a for loop because async await is more reliable
        // Using a forEach does not execute sequentially
        // There are ways to achieve sequential access using stream methods
        // But better left for optimisation at a later date
        // https://advancedweb.hu/how-to-use-async-functions-with-array-foreach-in-javascript/
        for (let index = 0; index < dropDetailsForDocketLid.DROPS.length; index += 1) {
          let element = dropDetailsForDocketLid.DROPS[index];
          element = removeTypename(element);
          // eslint-disable-next-line no-await-in-loop
          await createDropDetails(
            graphClient,
            { ...dropDetailsForDocketLid, SK: redirectedDropDetailsSK },
            element,
          );
        }
      }

      if (isUnmappedOpenOrder(deliveryDocket)) {
        dispatch({
          type: DeliveryDocketActions.UPDATE_MODAL_LOADING_MESSAGE,
          payload: 'Saving products',
        });

        const products = sumProducts([dropDetailsForDocketLid]);
        await updateShipmentProducts(graphClient, deliveryDocket, products);
        deliveryDocket.Products = withProductTypename(products);

        await updateShipmentProducts(graphClient, redirectedDocket, products);
        redirectedDocket.Products = withProductTypename(products);
      }

      // Populate products for the new
      // Create the R[next] and set to loaded
      const redirectForwardDocket = generateRedirectedDocket(
        redirectedDocket,
        redirectReason,
        otherText,
      );

      dispatch({
        type: DeliveryDocketActions.UPDATE_MODAL_LOADING_MESSAGE,
        payload: 'Creating redirect docket',
      });

      await postDocketDetails(graphClient, redirectForwardDocket);

      // Save the R[n] with the ShipmentAddress details of deliveryDocket.
      // generateRedirectedDocket does not copy over the ShipmentAddress details
      dispatch({
        type: DeliveryDocketActions.UPDATE_MODAL_LOADING_MESSAGE,
        payload: 'Updating docket status',
      });

      await updateDocketStatus(
        graphClient,
        deliveryDocket,
        statuses.Void,
      );
      const status = await getStatusForDD(graphClient, redirectedDocket);

      await updateDocketStatus(graphClient, redirectedDocket, status);

      return redirectForwardDocket;
    }
  }
  return redirectedDocket;
};
/* eslint-enable no-param-reassign */
