import React from 'react';
import PropTypes from 'prop-types';
import './_linkTile.scss';
import { Right32, Check32 } from '@bphxd/ds-core-react/icons';
import { isDocketStatusVoid, isDocketStatusComplete } from '../../helpers/shipmentEngine';

const LinkTile = ({
  icon: Icon, title, line1, line2, docketStatus, onClick, disabled, testId,
}) => {
  let statusClass = '';
  if (docketStatus && isDocketStatusVoid(docketStatus)) {
    statusClass = 'void';
  }
  if (docketStatus && isDocketStatusComplete(docketStatus)) {
    statusClass = 'completed';
  }
  if (disabled) {
    statusClass = 'disabled';
  }

  const dataTestId = testId || 'delivery-docket-link-tile';

  return (
    <div role="button" data-testid={dataTestId} onClick={() => !(disabled || statusClass === 'void') && onClick()} className={`d-flex justify-content-between align-items-center rounded my-5 p-5 link-tile-container ${statusClass}`}>
      <div className="d-flex flex-column align-items-center flex-shrink-1 mr-4">
        <Icon className="icon" />
        {statusClass === 'void' && <p className="m-0 d-md-none">VOID</p>}
      </div>
      <div className="d-flex flex-column justify-content-center flex-grow-1">
        <h5 className="my-2 green">
          {title}
          {' '}
          {statusClass === 'completed' ? <Check32 className="check" /> : '' }
        </h5>
        <p className="lt-line univers m-0 font-12">{line1}</p>
        <p className="lt-line univers m-0 font-12">{line2}</p>
      </div>
      <div>
        {statusClass !== 'void' ? <Right32 /> : <p className="m-0 d-none d-md-block">VOID</p>}
      </div>
    </div>
  );
};

LinkTile.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  line1: PropTypes.string,
  line2: PropTypes.string,
  docketStatus: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

export default LinkTile;
