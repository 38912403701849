import React from 'react';
import {
  Container, Row, Col, Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { extractAttributeFromCollection } from '../../helpers/attributeExtraction';
import './_loadIdAddons.scss';

const LoadIdAddons = ({ shipmentDetails }) => {
  const loadIdAddons = extractAttributeFromCollection(shipmentDetails, 'SK', 'LID#');

  return (
    <Container className="load-id-addons-container">
      <Row className="title-container">
        <Col sm="12" className="p-0">
          <Label className="univers font-weight-light">Load ID Addons</Label>
        </Col>
      </Row>

      <Row>
        {
          loadIdAddons.map((loadIdAddon, index) => (
            <Col sm="12" key={loadIdAddon} className="load-id-addon-row p-0">
              <div className="load-id-addon-index-container">
                <span className="load-id-index">{index + 1}</span>
              </div>
              <h1 className="load-id-addon" data-testid="load-id-addon">
                {loadIdAddon}
              </h1>
            </Col>
          ))
        }
      </Row>
    </Container>
  );
};

LoadIdAddons.propTypes = {
  shipmentDetails: PropTypes.arrayOf(PropTypes.shape({
    SK: PropTypes.string.isRequired,
  })).isRequired,
};

export default LoadIdAddons;
