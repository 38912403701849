import React, { useContext, useEffect, createRef } from 'react';
import { Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import ApolloClient from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import CompleteDeliveryModal from '../../../components/CompleteDeliveryModal/CompleteDeliveryModal';
import RedirectModal from '../../../components/RedirectModal/RedirectModal';
import SignatureModal from '../../../components/SignatureModal/SignatureModal';
import { DeliveryDocketActions, SignCaptureMode, RedirectMode } from '../DeliveryDocketReducer';
import { submitDriverSignature, submitCustomerSignature } from '../../../api/graphQl/Signature';
import { statuses, statusesSortRank } from '../../../helpers/shipmentStatus';
import {
  skHasValue, getDeliveryValue, isRedirected, isUnmappedOpenOrder,
} from '../../../helpers/shipmentEngine';
import withClient from '../../../hoc/withClient';
import { redirectShipment, getStatusForDD } from '../../../helpers/redirectHelper';
import { useAppContext, DeliveryContext } from '../../../state/GlobalState';
import { getShipmentDetails, updateDocketStatus, updateShipmentProducts } from '../../../api/graphQl/Shipment';
import { getDeliveryDropDetails } from '../../../api/graphQl/DropDetails';
import { sumProducts } from '../../../helpers/openOrders';
import ConfirmActionModal from '../../../components/ConfirmActionModal/ConfirmActionModal';
import RedirectCheckModal from '../../../components/RedirectCheckModal/RedirectCheckModal';

export const SignaturesFlow = ({
  graphClient,
  state,
  dispatch,
  cleared,
}) => {
  const history = useHistory();
  const {
    carrierId, driverId, handleGraphApiError, shipmentId,
  } = useAppContext();
  const [deliveryId, setDeliveryId] = useContext(DeliveryContext);
  const username = useMsal().accounts[0].name;

  const lidWarningMessage = 'Great job! Product(s) loaded for this docket have been fully delivered. Do you want to close off this delivery?';
  const supportMessage = '';

  const canvas = createRef();

  const {
    signatureOpen,
    deliveryDocket: currentDeliveryDocket,
    shipmentDetails,
    nextDisabled,
    nameInputDisabled,
    nameCaptureInput,
    canvasClass,
    captureMode,
    canCaptureCustomerSign,
    clearSignature,
    completeDeliveryOpen,
    redirectOpen,
    modalLoading,
    redirectMode,
    modalLoadingMessage,
    toggleState,
    geoTag,
    geoNextBtn,
    geoWarningMessage,
    latitude,
    longitude,
  } = state;

  const isOpenOrder = isUnmappedOpenOrder(currentDeliveryDocket);

  /**
   * Update the status of a shipment.
   * @param {*} status Status value to set
   */
  const updateShipmentStatus = async () => {
    try {
      const details = await getShipmentDetails(
        graphClient,
        shipmentId,
        carrierId,
      );

      if (details?.data?.getShipmentDetails) {
        const fetchedShipmentDetails = details.data.getShipmentDetails;

        await Promise.all(
          fetchedShipmentDetails
            .filter((deliveryDocket) => skHasValue(deliveryDocket, deliveryId))
            .map(async (deliveryDocket) => {
              const currStatus = await getStatusForDD(graphClient, deliveryDocket);
              await updateDocketStatus(graphClient, deliveryDocket, currStatus);
            }),
        );

        history.push('/shipment-details');
      }
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  /**
 * Update products of a shipment.
 * @param {*} products Products value to set
 */
  const updateDocketProducts = async (products) => {
    try {
      await updateShipmentProducts(graphClient, currentDeliveryDocket, products);
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  /**
   * Dispatch action to capture driver signature
   */
  const captureDriverSignature = () => dispatch({
    type: DeliveryDocketActions.CAPTURE_DRIVER_SIGNATURE,
    payload: {
      driverName: username,
      canvas: canvas.current,
    },
  });

  /**
   * Handle what happens when the next button is clicked in the signature modal
   */
  const handleSignatureNextClick = async () => {
    dispatch({ type: DeliveryDocketActions.MODAL_LOADING_TRUE });
    const sigPadRef = canvas.current;

    // If the capture mode is customer
    if (captureMode === SignCaptureMode.CUSTOMER) {
      // if we are able to capture sign
      if (canCaptureCustomerSign && sigPadRef) {
        // Check signpad is not empty
        if (!sigPadRef.isEmpty()) {
          try {
            // We can now submit the signature to the DB
            await submitCustomerSignature(
              graphClient,
              currentDeliveryDocket,
              deliveryId,
              sigPadRef.getTrimmedCanvas().toDataURL(),
              nameCaptureInput,
              false,
              driverId,
              '',
              '',
            );
            captureDriverSignature();
          } catch (error) {
            handleGraphApiError(error);
          }
        }
        // disable click button to prevent double click
        dispatch({
          type: DeliveryDocketActions.DISABLE_DOUBLECLICK_MODAL,
          payload: sigPadRef,
        });
      } else {
        try {
          // We could not capture the customer sign
          await submitCustomerSignature(graphClient, currentDeliveryDocket, deliveryId, '', '', true, driverId, longitude, latitude);
          captureDriverSignature();
          // disable click button to prevent double click
          if (sigPadRef) {
            dispatch({
              type: DeliveryDocketActions.DISABLE_DOUBLECLICK_MODAL,
              payload: sigPadRef,
            });
          }
        } catch (error) {
          handleGraphApiError(error);
        }
      }
    }

    // Post driver signature
    if (captureMode === SignCaptureMode.DRIVER) {
      if (sigPadRef && !sigPadRef.isEmpty()) {
        try {
          await submitDriverSignature(
            graphClient,
            currentDeliveryDocket,
            deliveryId,
            sigPadRef.getTrimmedCanvas().toDataURL(), driverId,
          );
        } catch (error) {
          handleGraphApiError(error);
        }
        // Dismiss the dialog
        dispatch({ type: DeliveryDocketActions.CLOSE_SIGNATURE_MODAL });
        // Open delivery complete dialog
        dispatch({ type: DeliveryDocketActions.OPEN_DELIVERY_COMPLETE_MODAL });
      }
    }

    dispatch({ type: DeliveryDocketActions.MODAL_LOADING_FALSE });
  };

  /**
   * Handle clicking redirect continue after selecting the reason and
   * entering any other text if applicable
   *
   * @param {*} redirectReason Redirect reason selected
   * @param {*} otherText Free text entered by user
   */
  const handleRedirectContinue = async (
    redirectReason,
    otherText,
    customRedirectMode = undefined,
  ) => {
    dispatch({ type: DeliveryDocketActions.MODAL_LOADING_TRUE });
    const responses = await Promise.all(
      shipmentDetails
        .filter((deliveryDocket) => skHasValue(deliveryDocket, deliveryId))
        .map((deliveryDocket) => {
          try {
            return redirectShipment(
              graphClient,
              dispatch,
              deliveryDocket,
              redirectReason,
              otherText,
              customRedirectMode || redirectMode,
              driverId,
            );
          } catch (error) {
            handleGraphApiError(error);
            return null;
          }
        }),
    );

    if (responses.length > 0) {
      // Dispatch to close the modal
      dispatch(
        {
          type: DeliveryDocketActions.COMPLETE_REDIRECT_REASON_CAPTURE,
          payload: responses[0],
        },
      );
      // Show the user the newly created docket R[n+1]
      setDeliveryId(getDeliveryValue(responses[0]));

      // This is needed if signatures are captured from drop details page
      history.push('/delivery-docket');
    }
    dispatch({ type: DeliveryDocketActions.MODAL_LOADING_FALSE });
  };

  /**
   * Get drop details for delivery docket and count how many drops are present
   * @param {*} deliveryDocket
   */
  const getDropDetailsCount = async (deliveryDocket) => {
    try {
      if (deliveryDocket.PK && deliveryDocket.SK) {
        const dropDetails = await getDeliveryDropDetails(graphClient, deliveryDocket);
        if (dropDetails?.data?.getDropDetails) {
          const count = dropDetails.data.getDropDetails.reduce(
            (prev, curr) => prev + curr.DROPS.length, 0,
          );
          dispatch({ type: DeliveryDocketActions.UPDATE_DROP_COUNT, payload: count });
        }
      }
    } catch (error) {
      handleGraphApiError(error);
      dispatch({ type: DeliveryDocketActions.UPDATE_DROP_COUNT, payload: 0 });
    }
  };

  /**
   * Get drop details for delivery docket
   * @param {*} deliveryDocket
   */
  const getDropDetails = async (deliveryDocket) => {
    try {
      if (deliveryDocket.PK && deliveryDocket.SK) {
        const dropDetails = await getDeliveryDropDetails(graphClient, deliveryDocket);
        if (dropDetails?.data?.getDropDetails) {
          return dropDetails.data.getDropDetails;
        }
      }
    } catch (error) {
      handleGraphApiError(error);
    }
    return null;
  };

  /**
     * Fetch shipment details
     */
  const fetchShipmentDetails = async () => {
    try {
      const response = await getShipmentDetails(
        graphClient,
        shipmentId,
        carrierId,
      );

      if (response?.data?.getShipmentDetails) {
        const responseShipmentDetails = response.data.getShipmentDetails;

        const docketsMatchingDeliveryId = responseShipmentDetails.filter(
          (docket) => skHasValue(docket, deliveryId),
        );

        // In case with multiple LIDs (Scen. 3) we're getting multiple shipments for different LIDs
        // So we have to order by the "higher" status of shipment with the same DeliveryNo
        // Last DD has the highest status
        const docketMatchingDeliveryId = last(
          sortBy(
            docketsMatchingDeliveryId,
            (element) => statusesSortRank[element.Status],
          ),
        );

        dispatch({
          type: DeliveryDocketActions.SET_SHIPMENT_DETAILS,
          payload: {
            // Set the delivery detail from the current shipment.
            currentDeliveryDetail: docketMatchingDeliveryId,
            // Returns all dockets excluding the docket we just saved above
            allDeliveries: responseShipmentDetails.filter(
              (docket) => skHasValue(docket, deliveryId),
            ),
          },
        });

        getDropDetailsCount(docketMatchingDeliveryId);
      }
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  const closeWindow = () => {
    dispatch(
      { type: DeliveryDocketActions.CLOSE_DELIVERY_COMPLETE_MODAL },
    );
  };

  const completeShipment = () => {
    updateShipmentStatus(
      statuses.Delivered,
      DeliveryDocketActions.SET_DELIVERED_STATUS,
    );
  };

  useEffect(() => {
    fetchShipmentDetails();
  }, [shipmentId, deliveryId]);

  return (
    <Container>
      {/* Signature modal */}
      <SignatureModal
        canvasRef={canvas}
        signatureOpen={signatureOpen}
        dispatch={dispatch}
        captureMode={captureMode}
        canvasClass={canvasClass}
        nameCaptureInput={nameCaptureInput}
        nameInputDisabled={nameInputDisabled}
        clearSignature={clearSignature}
        nextDisabled={nextDisabled}
        handleNextClick={handleSignatureNextClick}
        toggleState={toggleState}
        geoTag={geoTag}
        geoNextBtn={geoNextBtn}
        geoWarningMessage={geoWarningMessage}
        longitude={longitude}
        latitude={latitude}
        actionInProgress={modalLoading}
      />

      <ConfirmActionModal
        isOpen={cleared && completeDeliveryOpen}
        confirmMsg={lidWarningMessage}
        confirmSubMsg={supportMessage}
        handleCancel={closeWindow}
        handleAction={completeShipment}
      />

      {/* Open orders - Complete delivery modal */}
      {/* Planned shipment - Redirect check modal */}
      {
        isOpenOrder
          ? (
            /* Open orders - Complete delivery modal */
            <CompleteDeliveryModal
              loadingFeedback={modalLoadingMessage}
              isOpen={!cleared && completeDeliveryOpen}
              onDeliveredHandler={async () => {
                if (isOpenOrder) {
                  const dropDetails = await getDropDetails(currentDeliveryDocket);
                  const products = sumProducts(dropDetails);
                  await updateDocketProducts(products);
                }

                updateShipmentStatus(
                  statuses.Delivered,
                  DeliveryDocketActions.SET_DELIVERED_STATUS,
                );
              }}
              onRedirectHandler={() => {
                if (isRedirected(currentDeliveryDocket)) {
                  // When partially redirecting a redirect
                  // we dont need the reasons to be captured
                  handleRedirectContinue('', '', RedirectMode.PARTIAL);
                  dispatch({ type: DeliveryDocketActions.CLOSE_DELIVERY_COMPLETE_MODAL });
                } else {
                  dispatch({ type: DeliveryDocketActions.PARTIAL_REDIRECT });
                }
              }}
              onCloseHandler={() => dispatch(
                { type: DeliveryDocketActions.CLOSE_DELIVERY_COMPLETE_MODAL },
              )}
            />
          )
          : (
        /* Planned shipment - Redirect check modal */
            <RedirectCheckModal
              loadingFeedback={modalLoadingMessage}
              isOpen={!cleared && completeDeliveryOpen}
              onRedirectHandler={() => {
                if (isRedirected(currentDeliveryDocket)) {
                // When partially redirecting a redirect
                // we dont need the reasons to be captured
                  handleRedirectContinue('', '', RedirectMode.PARTIAL);
                  dispatch({ type: DeliveryDocketActions.CLOSE_DELIVERY_COMPLETE_MODAL });
                } else {
                  dispatch({ type: DeliveryDocketActions.PARTIAL_REDIRECT });
                }
              }}
              onCloseHandler={() => dispatch(
                { type: DeliveryDocketActions.CLOSE_DELIVERY_COMPLETE_MODAL },
              )}
            />
          )
      }

      {/* Redirect modal */}
      <RedirectModal
        loadingFeedback={modalLoadingMessage}
        modalLoading={modalLoading}
        isOpen={redirectOpen}
        onCloseHandler={() => dispatch({ type: DeliveryDocketActions.CLOSE_REDIRECT_MODAL })}
        handleRedirectContinue={handleRedirectContinue}
      />
    </Container>
  );
};

/**
 * We use oneOfType because
 * the application uses AppSyncClient
 * the tests/jest provide ApolloClient as we use the <MockProvider/>
 */
SignaturesFlow.propTypes = {
  graphClient: PropTypes.oneOfType([
    PropTypes.instanceOf(AWSAppSyncClient).isRequired,
    PropTypes.instanceOf(ApolloClient).isRequired,
  ]).isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  cleared: PropTypes.bool.isRequired,
};

// withClient will provide the client in props
export default withClient(SignaturesFlow);
