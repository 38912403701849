import React, { useEffect } from 'react';
import {
  Container, Col, Row, Navbar, NavbarBrand,
} from 'reactstrap';
import './_timeoutLayout.scss';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';

const CustomNavbar = () => (
  <Navbar color="faded" light fixed="top" className="navbar m-0" data-testid="navbar">
    <NavbarBrand href="/">
      <div className="title univers">bp eDocket</div>
    </NavbarBrand>

    <div className="logo-container">
      <div className="navbar-logo" role="img" alt="BP Logo" />
    </div>
  </Navbar>
);

// Hide all links apart for 'Privacy' for Realease 1
const CustomFooter = () => (
  <Row className="footer m-0" data-testid="footer">
    {/* <Col sm="12" md="4" lg="4" className="footer-link pt-lg-4">
      Copyright &copy; BP
      {` ${new Date().getFullYear()}`}
    </Col>
    <Col sm="12" md="4" lg="2" className="footer-link pt-lg-4">Contact</Col>
    <Col sm="12" md="4" lg="2" className="footer-link pt-lg-4">Legal Notice</Col>
    <Col md="4" className="d-none d-md-block d-lg-none" /> */}
    <Col sm="12" md="4" lg="2" className="footer-link pt-lg-4">
      <a href="https://www.bp.com/en_au/australia/home/privacy-statement.html" target="blank" rel="noopener noreferrer">Privacy</a>
    </Col>
    {/* <Col sm="12" md="4" lg="2" className="footer-link pt-lg-4">
      <Link to="/data-collection">Data Collection</Link>
    </Col> */}
  </Row>
);

const TimeoutLayout = ({ children }) => {
  const rootRef = React.useRef();
  const location = useLocation();
  useEffect(() => {
    rootRef.current.scrollIntoView();
  }, [location]);
  return (
    <div ref={rootRef}>
      <Container fluid className="layout-container p-0 m-0 h-100" data-testid="layout-container">
        <CustomNavbar />
        <Row className="main-content mr-0 ml-0 flex-grow-1" data-testid="main-content">
          <Col
            xs={{ size: 10, offset: 1 }}
            lg={{ size: 8, offset: 2 }}
            xl={{ size: 6, offset: 3 }}
          >
            {children}
          </Col>
        </Row>
        <CustomFooter />
      </Container>
    </div>
  );
};

TimeoutLayout.defaultProps = {
  children: PropTypes.node,
};

TimeoutLayout.propTypes = {
  children: PropTypes.node,
};

export default TimeoutLayout;
