import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AWSAppSyncClient from 'aws-appsync';
import ApolloClient from 'apollo-client';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { queryDriverDetails } from '../api/graphQl/Driver';
import { readToken, writeToken } from '../helpers/localStorage';
import { extractAttributeFromKey } from '../helpers/attributeExtraction';
import { acquireTokenRequest, selectAccountRequest } from '../auth/msal-config';

export const DeliveryContext = React.createContext(null);
export const DropContext = React.createContext(null);

export const AppContext = React.createContext();

export const AppProvider = ({ children, graphClient }) => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [shipmentId, setShipmentId] = useState();
  const [carrierId, setCarrierId] = useState();
  const [driverId, setDriverId] = useState();
  const [plantId, setPlantId] = useState();
  const [newShipToAddress, setNewShipToAddress] = useState({});
  const [addresses, setAddresses] = useState();
  const [bpProducts, setBpProducts] = useState();
  const [selectedDocket, setSelectedDocket] = useState();
  const [driverAuthError, setDriverAuthError] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [wifiSpeed, setwifiSpeed] = useState();

  const acquireToken = async () => {
    try {
      acquireTokenRequest.account = accounts[0];
      // Try to acquire the token here and set it in the localstorage
      const response = await instance.acquireTokenSilent(acquireTokenRequest);
      writeToken(response.idToken);
    } catch (error) {
      // Any attempt to acquire a token fails, kick the user out to a login flow
      instance.loginRedirect(selectAccountRequest);
    }
  };

  const logoutRedirect = () => {
    window.location.pathname = '/timeout';
  };

  const handleGraphApiError = (error) => {
    switch (error?.networkError?.statusCode) {
      case 401:
        acquireToken();
        break;
      // handle network errors here
      // You can write more cases here
      default:
        break;
    }
    // if its driver error then show only that msg and dont log out
    // in all valid case for any global error logout user
    if (error.message.includes('UNAUTHORIZED')) {
      if (!driverAuthError) {
        setDriverAuthError('Unfortunately, you no longer have access to bp eDocket. Please contact your carrier\'s dispatch office.');
      }
    } else if (error.message.includes('signout_user_2000') || error.message.includes('401')) {
      console.log('error at global', error);
      logoutRedirect();
    }
  };

  const authoriseUser = async () => {
    try {
      if (instance && accounts.length > 0 && isAuthenticated && !carrierId && readToken()) {
        const {
          data,
        } = await queryDriverDetails(graphClient, `Email#${accounts[0].username}`);
        if (data?.getDriverDetails && data.getDriverDetails.CARRIERID) {
          const sapDriverId = extractAttributeFromKey(data.getDriverDetails.SK, 'SAPDriverID#');
          setDriverId(sapDriverId);
          setCarrierId(data.getDriverDetails.CARRIERID);
        } else {
          throw Error({
            message: 'UNAUTHORIZED',
          });
        }
      }
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  useEffect(() => {
    authoriseUser();
  }, [instance, accounts, isAuthenticated, carrierId]);

  const appContext = {
    carrierId,
    setCarrierId,
    driverId,
    setDriverId,
    plantId,
    setPlantId,
    newShipToAddress,
    setNewShipToAddress,
    addresses,
    setAddresses,
    bpProducts,
    setBpProducts,
    selectedDocket,
    setSelectedDocket,
    handleGraphApiError,
    acquireToken,
    authoriseUser,
    driverAuthError,
    menuOpen,
    setMenuOpen,
    shipmentId,
    setShipmentId,
    wifiSpeed,
    setwifiSpeed,
  };

  return (
    <AppContext.Provider value={appContext}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (!context) {
    throw new Error('UseAppContext must be used inside AppContext.Provider');
  }
  return context;
};

AppProvider.propTypes = {
  children: PropTypes.node,
  graphClient: PropTypes.oneOfType([
    PropTypes.instanceOf(AWSAppSyncClient).isRequired,
    PropTypes.instanceOf(ApolloClient).isRequired,
  ]).isRequired,
};

const GlobalState = ({ children }) => {
  const [deliveryId, setDeliveryId] = useState();
  const [drop, setDrop] = useState();

  return (
    <DeliveryContext.Provider value={[deliveryId, setDeliveryId]}>
      <DropContext.Provider value={[drop, setDrop]}>
        {children}
      </DropContext.Provider>
    </DeliveryContext.Provider>
  );
};

GlobalState.defaultProps = {
  children: PropTypes.node,
};

GlobalState.propTypes = {
  children: PropTypes.node,
};

export default GlobalState;
