import findIndex from 'lodash/findIndex';

// ShipmentId for open orders is: referenceNo-LID
// This function will remove the LID from ShipmentId.
export const getShipmentHeader = (shipmentId) => {
  const delimiter = '-';

  if (shipmentId.includes(delimiter)) {
    // either redirect or open order
    const parts = shipmentId.split(delimiter);
    // redirects start with R
    if (parts[parts.length - 1].startsWith('R')) {
      return shipmentId;
    }
    parts.pop(); // remove last element (LID)
    return parts.join(delimiter);
  }
  return shipmentId;
};

export const sumProducts = (dropDetails) => {
  const products = [];

  dropDetails.forEach((dropDetail) => {
    dropDetail.DROPS.forEach((drop) => {
      const index = findIndex(products, ['Name', drop.Product]);
      if (index === -1) {
        // Add a new value
        products.push({
          Name: drop.Product,
          QuantityAmbient: parseInt(drop.QuantityAmbient, 10),
          QuantityStandard: parseInt(drop.QuantityStandard, 10),
          Compartments: '',
        });
      } else {
        // Sum with existing value
        products[index].QuantityAmbient += parseInt(drop.QuantityAmbient, 10);
        products[index].QuantityStandard += parseInt(drop.QuantityStandard, 10);
      }
    });
  });

  return products;
};

export const withProductTypename = (products) => products.map(
  (prod) => ({ ...prod, ...{ __typename: 'Product' } }),
);
