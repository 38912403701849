/* Implement ENV values read functionality
 * which are probably shared with cloudformation stack
 */

let AppSyncConfig = null; // eslint-disable-line import/no-mutable-exports

if (process.env.NODE_ENV === 'development') {
  // Load env values
  AppSyncConfig = {
    awsProjectRegion: process.env.REACT_APP_aws_project_region,
    awsAppsyncGraphqlEndpoint:
      process.env.REACT_APP_aws_appsync_graphqlEndpoint,
    awsAppsyncRegion: process.env.REACT_APP_aws_appsync_region,
    awsAppsyncAuthenticationType:
      process.env.REACT_APP_aws_appsync_authenticationType,
    awsAppsyncApiKey: process.env.REACT_APP_aws_appsync_apiKey,
  };
} else {
  // Add production configuration here
  AppSyncConfig = {
    awsProjectRegion: process.env.REACT_APP_aws_project_region,
    awsAppsyncGraphqlEndpoint:
      process.env.REACT_APP_aws_appsync_graphqlEndpoint,
    awsAppsyncRegion: process.env.REACT_APP_aws_appsync_region,
    awsAppsyncAuthenticationType:
      process.env.REACT_APP_aws_appsync_authenticationType,
    awsAppsyncApiKey: process.env.REACT_APP_aws_appsync_apiKey,
  };
}

export default AppSyncConfig;
