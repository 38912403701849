import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Col,
  Row,
  InputGroup,
  Input,
  CustomInput,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  FormText,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Link, Redirect, useHistory } from 'react-router-dom';
import ApolloClient from 'apollo-client';
import AWSAppSyncClient from 'aws-appsync';
import flatten from 'lodash/flatten';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import {
  useAppContext, DeliveryContext,
} from '../../state/GlobalState';
import {
  initialValues, DropDetailsSchema, quantityWarningMsg, ambientWarningMsg,
} from './validation';
import PageHeading from '../../components/PageHeading/PageHeading';
import BackButton from '../../components/BackButton/BackButton';
import { extractAttributeFromCollection } from '../../helpers/attributeExtraction';
import { getShipmentDetails } from '../../api/graphQl/Shipment';
import {
  createDropDetails, getDeliveryDropDetails,
} from '../../api/graphQl/DropDetails';
import withClient from '../../hoc/withClient';
import './_enterDropDetails.scss';
import { skHasValue, generateDropDetailsSK, isUnmappedOpenOrder } from '../../helpers/shipmentEngine';
import LidDetail from '../../components/LidDetail/LidDetail';
import {
  lidAddonClickHandler, productClickHandler, saveClickHandler,
  clearFieldsClickHandler, backButtonClickHandler,
} from '../../analytics/events';
import { getShipmentHeader } from '../../helpers/openOrders';
import { statuses } from '../../helpers/shipmentStatus';
import pageTitleConstant from '../../analytics/constantPageTitle';
import NetworkSpeedChecker from '../../components/NetworkSpeedChecker/NetworkSpeedChecker';

const EnterDropDetails = ({
  graphClient,
}) => {
  const history = useHistory();
  const {
    carrierId, selectedDocket, shipmentId, handleGraphApiError, bpProducts,
  } = useAppContext();
  const [deliveryId] = useContext(DeliveryContext);
  const [ambientDisable, setambientDisable] = useState(false);
  const [quantityWarning, setQuantityWarning] = useState();
  const [shipmentDetails, setShipmentDetails] = useState([]);
  const [dropDetails, setDropDetails] = useState();
  const [dropDetailsAll, setDropDetailsAll] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedLoadIdAddon, setSelectedLoadIdAddon] = useState();
  const [calculatedresult, setCalculatedresult] = useState();

  // useState for Aggregate Drop Details Card
  const [aggregateFlag, setAggregateFlag] = useState(true);
  const [aggregateProduct, setAggregateProduct] = useState('');
  const [aggregateQtyAmbient, setAggregateQtyAmbient] = useState(0);
  const [allLidSelected, setAllLidSelected] = useState(true);
  const [productAmbient, SetProductAmbient] = useState({});

  // get lid status from its child component - LidDetails
  const [, setLidStatus] = useState(false);
  const sendDataBackToParent = (valueBack) => {
    setLidStatus(valueBack);
  };

  const selectedShipmentDetail = shipmentDetails.find((sd) => sd.SK.includes(`LID#${selectedLoadIdAddon}`));

  const isOpenOrder = isUnmappedOpenOrder(selectedDocket);

  const getSelectedProducts = () => {
    if (!selectedShipmentDetail) return [];

    if (isOpenOrder) return bpProducts;
    return selectedShipmentDetail.Products;
  };

  const getSelectedVehicle = (selectedShipmentDetail && selectedShipmentDetail.TASVehicleID)
    ? selectedShipmentDetail.TASVehicleID
    : '';

  if (!shipmentId || !deliveryId) {
    // If there's no shipment id or delivery id, redirect to find shipment page
    return <Redirect to="/shipment" />;
  }

  const setShipmentDetailsForDelivery = (localShipmentDetails) => {
    const shipmentDetailsForDelivery = localShipmentDetails.filter(
      (docket) => skHasValue(docket, deliveryId),
    );
    setShipmentDetails(shipmentDetailsForDelivery);
  };

  const getProducts = (values) => {
    if (isOpenOrder) return bpProducts;

    if (values.LoadIdAddon) {
      const selectedDetail = shipmentDetails.find((sd) => sd.SK.includes(`LID#${values.LoadIdAddon}`));
      return selectedDetail ? selectedDetail.Products : [];
    }
    return [];
  };

  const getLoadIdAddons = () => (shipmentDetails ? extractAttributeFromCollection(shipmentDetails, 'SK', 'LID#') : []);

  const getLoadIdAddonsWithAggregate = () => {
    let lidOptions = [];
    const allLids = [];
    if (shipmentDetails) {
      lidOptions = extractAttributeFromCollection(shipmentDetails, 'SK', 'LID#');
      lidOptions?.forEach((lidOption) => {
        allLids.push(lidOption);
      });
      if (lidOptions?.length > 1) {
        lidOptions.push(allLids);
      }
    }
    return lidOptions;
  };

  if (!selectedLoadIdAddon && getLoadIdAddons().length > 0) {
    setSelectedLoadIdAddon(getLoadIdAddons([0]));
  }

  // unix timestamp in seconds will give a unique value for this use case
  const getDropId = () => Math.floor(Date.now() / 1000);

  // Calculate total delivered amount
  const calculateTotalDeliverd = (dropDetailsAl) => {
    const map = new Map();
    if (dropDetailsAl) {
      dropDetailsAl.forEach((dropDetail) => {
        if (dropDetail.DROPS.length) {
          dropDetail.DROPS.forEach(
            (drop) => {
              if (map.has(dropDetail.SK.split('#LID#')[1] + drop.Product)) {
                map.set(
                  dropDetail.SK.split('#LID#')[1] + drop.Product, map.get(dropDetail.SK.split('#LID#')[1] + drop.Product) + drop.ActualDischargeQty,
                );
              } else { map.set(dropDetail.SK.split('#LID#')[1] + drop.Product, drop.ActualDischargeQty); }
            },
          );
        }
      });
    }
    return map;
  };

  const calculateSelectedProduct = (dropsDelivered) => {
    if (dropsDelivered && selectedProduct) {
      getSelectedProducts().filter((product) => product.Name === selectedProduct)
        .map((product) => (
          calculateTotalDeliverd([...new Set(dropDetailsAll)])
            .get(selectedLoadIdAddon + product.Name)
            ? setCalculatedresult((product.QuantityAmbient
              - calculateTotalDeliverd([...new Set(dropDetailsAll)])
                .get(selectedLoadIdAddon + product.Name)).toFixed(2))
            : setCalculatedresult(product.QuantityAmbient)
        ));
    } else if (getProducts({ LoadIdAddon: selectedLoadIdAddon }).length === 1) {
      getSelectedProducts().filter(
        (product) => product.Name === getProducts({ LoadIdAddon: selectedLoadIdAddon })[0].Name,
      )
        .map((product) => (
          calculateTotalDeliverd([...new Set(dropDetailsAll)])
            .get(selectedLoadIdAddon + product.Name)
            ? setCalculatedresult((product.QuantityAmbient
                - calculateTotalDeliverd([...new Set(dropDetailsAll)])
                  .get(selectedLoadIdAddon + product.Name)).toFixed(2))
            : setCalculatedresult(product.QuantityAmbient)
        ));
    } else {
      setCalculatedresult(0);
    }
    return calculatedresult;
  };

  const ambientValidationCheck = (values) => {
    if (values.OpenOrder && values.QuantityAmbient && values.ActualDischargeQty
      && parseInt(values.QuantityAmbient, 10) < parseInt(values.ActualDischargeQty, 10)) {
      setQuantityWarning(`Discharge quantity cannot be greater than the quantity ambient (${values.QuantityAmbient}).`);
      setambientDisable(true);
    } else if (values.DipBefore?.length !== 0
      && values.DipAfter?.length !== 0
      && dropDetailsAll
    ) {
      calculateSelectedProduct([...new Set(dropDetailsAll)]);
      if (
        values.Calculated && values.ActualDischargeQty
        && [...new Set(dropDetailsAll)]
        && parseInt(values.ActualDischargeQty, 10) > parseInt(calculatedresult, 10)
      ) {
        setQuantityWarning(`${ambientWarningMsg}(${calculatedresult}) .`);
        setambientDisable(true);
      } else if (
        values.ActualDischargeQty
        && parseInt(values.Calculated, 10) !== parseInt(values.ActualDischargeQty, 10)
      ) {
        setQuantityWarning(quantityWarningMsg);
        setambientDisable(false);
      } else {
        setQuantityWarning();
        setambientDisable(false);
      }
    }
  };

  const updateCalculatedValue = (setFieldValue, values) => {
    if (values.DipBefore?.length === 0 || values.DipAfter?.length === 0) {
      setFieldValue('Calculated', 0);
      ambientValidationCheck({ Calculated: 0, ...values });
    } else {
      const newCalculated = values.DipAfter - values.DipBefore;
      setFieldValue('Calculated', newCalculated);
      ambientValidationCheck({ ...values, Calculated: newCalculated });
    }
  };

  const onSubmit = async (values) => {
    const inputValues = {
      DropId: getDropId(),
      Product: values.Product,
      Tank: values.Tank,
      Capacity: parseInt(values.Capacity, 10),
      Compartments: values.Compartments,
      ActualDischargeQty: parseInt(values.ActualDischargeQty, 10),
      DipAfter: parseInt(values.DipAfter, 10),
      DipBefore: parseInt(values.DipBefore, 10),
      Calculated: parseInt(values.Calculated, 10),
      TASVehicleID: getSelectedVehicle,
      QuantityAmbient: values.QuantityAmbient,
      QuantityStandard: values.QuantityStandard,
      Aggregate: false,
    };
    const idArray = values.LoadIdAddon.split(',');
    const skArray = [];
    idArray.push(values.LoadIdAddon);
    idArray.forEach((myID) => {
      skArray.push(generateDropDetailsSK(selectedDocket, myID));
    });

    if (aggregateFlag && allLidSelected) {
      /* eslint-disable-next-line */
      for (const mySK of skArray) {
        if (mySK.split(',').length <= 1) {
          /* eslint-disable no-await-in-loop */
          await createDropDetails(graphClient, {
            PK: selectedDocket.PK,
            SK: mySK,
          }, {
            ...inputValues,
            ActualDischargeQty: productAmbient[mySK.split('DROPDETAILS#')[1]],
          });
        } else {
          /* eslint-disable no-await-in-loop */
          await createDropDetails(graphClient, {
            PK: selectedDocket.PK,
            SK: mySK,
          }, {
            ...inputValues,
            Aggregate: true,
          });
        }
      }
    } else {
      await createDropDetails(graphClient, {
        PK: selectedDocket.PK,
        SK: generateDropDetailsSK(selectedDocket, selectedLoadIdAddon),
      }, inputValues);
    }
    history.push('/drop-details');
  };

  const getInitialValues = () => {
    const overrideValues = {};

    // Open order has additional required fields in the validation schema
    overrideValues.OpenOrder = isOpenOrder;

    const loadIdAddons = getLoadIdAddons();

    if (loadIdAddons.length === 1) {
      overrideValues.LoadIdAddon = loadIdAddons[0];
    }
    const products = getProducts({ LoadIdAddon: loadIdAddons[0] });
    if (products && products.length === 1) {
      overrideValues.Product = products[0].Name;
    }

    // !!! Need to be improved: only need to reset thoes values when there are certain values
    // pre-populated into
    overrideValues.DipAfter = '';
    overrideValues.Calculated = '';
    overrideValues.ActualDischargeQty = '';

    // Pre-select values for load id addon and product if only one value is present
    return Object.assign(initialValues, overrideValues);
  };

  const handleFormReset = (resetForm, values) => {
    const loadIdReset = getLoadIdAddons().length <= 1 ? values.LoadIdAddon : '';
    const dipAfterReset = '';
    const dipBeforeReset = '';
    const calculatedReset = '';
    const actualDischargeQty = '';

    resetForm({
      values: {
        ...initialValues,
        LoadIdAddon: loadIdReset,
        DipAfter: dipAfterReset,
        DipBefore: dipBeforeReset,
        Calculated: calculatedReset,
        ActualDischargeQty: actualDischargeQty,
      },
    });
    setSelectedProduct('');
    setQuantityWarning();
  };

  // handle form reset single select for aggregate drop
  const handleFormResetAggregateSingle = (resetForm) => {
    const dipAfterReset = '';
    const dipBeforeReset = '';
    const calculatedReset = 0;
    const actualDischargeQty = '';
    const loadIdReset = selectedLoadIdAddon;
    resetForm({
      values: {
        ...initialValues,
        LoadIdAddon: loadIdReset,
        DipAfter: dipAfterReset,
        DipBefore: dipBeforeReset,
        Calculated: calculatedReset,
        ActualDischargeQty: actualDischargeQty,
      },
    });
  };

  // handle form reset single select for aggregate drop
  const handleFormResetAggregateMulti = (resetForm, values) => {
    const dipAfterReset = 0;
    const dipBeforeReset = '';
    const calculatedReset = aggregateQtyAmbient;
    const actualDischargeQty = aggregateQtyAmbient;
    const loadIdReset = values.loadIdAddons;
    resetForm({
      values: {
        ...initialValues,
        LoadIdAddon: loadIdReset,
        DipAfter: dipAfterReset,
        DipBefore: dipBeforeReset,
        Calculated: calculatedReset,
        ActualDischargeQty: actualDischargeQty,
      },
    });
  };

  const fetchAndSetAllDropDetails = async () => {
    try {
      const shDetails = await getShipmentDetails(graphClient, shipmentId, carrierId);
      if (shDetails?.data?.getShipmentDetails) {
        setShipmentDetailsForDelivery(shDetails.data.getShipmentDetails);
        shDetails.data.getShipmentDetails.forEach(
          async (sd) => {
            // As sd.SK is always in format like: DeliveryNo#ORIGINAL#204077233#LID#820641 or
            // DeliveryNo#REDIRECT#204077232-R1#LID#820641, so:
            // 1. sd.SK.split('#')[2] is used to obatin the Delivery Docket number
            // 2. split('-')[0] is used to extract the Delivery Docket number without '-R'
            if (sd.Status !== statuses.Void && sd.SK.split('#')[2].split('-')[0] === deliveryId.split('-')[0]) {
              const response = await getDeliveryDropDetails(graphClient, sd);
              if (response?.data?.getDropDetails) {
                // We have to set the state here because drop detail fetch is async
                setDropDetailsAll(
                  (oldArray) => flatten([...oldArray, response.data.getDropDetails]),
                );
              }
            }
          },
        );
      }
    } catch (error) {
      handleGraphApiError(error);
    }
  };

  // This func() is to judge whether Aggregate Drop Details should be enabled
  const aggregateFlagCondition = () => {
    // The aggregate drop details should be disabled when there
    // is only one lid
    const loadIdAddons = getLoadIdAddonsWithAggregate();
    if (loadIdAddons?.length === 1) {
      setAggregateFlag(false);
    }
    // If 1 or more drop details already existed
    // Then aggregate drop details should be disabled
    if (dropDetailsAll) {
      [...new Set(dropDetailsAll)].forEach((record) => {
        if (record.DROPS?.length > 0) {
          setAggregateFlag(false);
        }
      });
    }
    // If there are more than 1 product per lid or different products across
    // each lid, aggregate drop details should be disabled
    shipmentDetails?.forEach((test) => {
      if (test.Products && test.Products.length > 1) {
        setAggregateFlag(false);
      } else if (test.Products && aggregateProduct === '') {
        setAggregateProduct(test.Products[0].Name);
      } else if (test.Products && test.Products[0].Name !== aggregateProduct) {
        setAggregateFlag(false);
      }
    });
  };

  // This func() is to sum qty amb. across lids
  const aggregateSumAmbient = () => {
    const loadIdAddons = getLoadIdAddonsWithAggregate();
    let temp = 0;
    const productList = {};
    if (loadIdAddons) {
      loadIdAddons?.forEach((lidtest) => {
        const selectedDetail = shipmentDetails.find((sd) => sd.SK.includes(`LID#${lidtest}`));
        if (selectedDetail) {
          productList[selectedDetail.SK] = parseInt(selectedDetail.Products[0].QuantityAmbient, 10);
          temp += parseInt(selectedDetail.Products[0].QuantityAmbient, 10);
        }
      });
      setAggregateQtyAmbient(temp);
      SetProductAmbient(productList);
    }
  };

  // This func() is used to pre-populate data into enterDropDetails page
  const setAggregateInitals = () => {
    const aggregateInitials = {};
    const loadIdAddons = getLoadIdAddonsWithAggregate();

    if (loadIdAddons.length > 1) {
      aggregateInitials.LoadIdAddon = JSON.stringify(loadIdAddons[loadIdAddons.length - 1]);
      aggregateInitials.LoadIdAddon = aggregateInitials.LoadIdAddon.replace('[', '');
      aggregateInitials.LoadIdAddon = aggregateInitials.LoadIdAddon.replace(']', '');
      aggregateInitials.LoadIdAddon = aggregateInitials.LoadIdAddon.replaceAll('"', '');
      const products = getProducts({ LoadIdAddon: loadIdAddons[0] });
      if (products && products.length === 1) {
        aggregateInitials.Product = products[0].Name;
      }
    }
    aggregateInitials.Calculated = aggregateQtyAmbient;
    aggregateInitials.ActualDischargeQty = aggregateQtyAmbient;
    aggregateInitials.DipAfter = 0;
    return Object.assign(initialValues, aggregateInitials);
  };

  useEffect(() => {
    const getDataForComponent = async () => {
      const dDetails = await getDeliveryDropDetails(graphClient, selectedDocket);
      if (dDetails?.data?.getDropDetails) {
        setDropDetails(dDetails.data.getDropDetails);
      }
    };
    getDataForComponent()
      .catch((error) => handleGraphApiError(error));
    fetchAndSetAllDropDetails();
  }, [deliveryId]);

  // This useEffect is for judge aggregate drop details
  useEffect(() => {
    if (!isOpenOrder) {
      aggregateFlagCondition();
      aggregateSumAmbient();
    } else {
      setAggregateFlag(false); // The aggregate drop details should not be applied to open-orders
    }
  }, [shipmentDetails, dropDetailsAll]);

  return (
    <Container className="enter-drop-details-container pl-0 pr-0" data-testid="enter-drop-details-container">
      <NetworkSpeedChecker />
      {/* If there is no drop details linked to a docket, */}
      {/* the back button points jumps to Page /delivery-docket. */}
      {/* Use flatMap() so that we can go through each dropDeatil of a docket */}
      {/* Disable the back button when page is still loading */}
      {
        dropDetails
          ? (
            dropDetails.length > 0 && dropDetails.flatMap((d) => d.DROPS).length > 0
              ? (
                <Link className="link" to="/drop-details" onClick={() => backButtonClickHandler(pageTitleConstant.dropDetailsForm)}>
                  <BackButton />
                </Link>
              )
              : (
                <Link className="link" to="/delivery-docket" onClick={() => backButtonClickHandler(pageTitleConstant.dropDetailsForm)}>
                  <BackButton />
                </Link>
              )
          ) : (
            <Link className="link" to="/delivery-docket" onClick={(event) => event.preventDefault()}>
              <BackButton />
            </Link>
          )
      }

      <PageHeading label="Delivery docket number" refNumber={getShipmentHeader(deliveryId)} />

      <Formik
        initialValues={aggregateFlag ? setAggregateInitals() : getInitialValues()}
        validationSchema={DropDetailsSchema}
        onSubmit={(values, formikBag) => onSubmit(values, formikBag)}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
          isValid,
          values,
          errors,
          touched,
          dirty,
          resetForm,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit} data-testid="enter-drop-details-form">
            <Row>
              <Col>

                {!isOpenOrder && (
                  <Row>
                    <LidDetail
                      shipmentDetails={shipmentDetails}
                      dropDetailsAll={[...new Set(dropDetailsAll)]}
                      sendDataBackToParent={sendDataBackToParent}
                    />
                  </Row>
                )}

                <Row className="mb-8">
                  <Col xs="12">
                    <div className="drop-details-form-title"><h5>Enter drop details</h5></div>
                  </Col>

                  <Col xs="12">
                    <div className="drop-details-form-subtitle">Enter details for each drop, one at a time.</div>
                  </Col>
                </Row>

                {/* 'input-colour' - this css class is used globally for all input fields which is
                  defined in _enterDropDetails.scss */}
                <Row>
                  {!isOpenOrder && (
                    /* Field: LoadIdAddon */
                    <Col xs="12" md="6">
                      {!aggregateFlag ? (
                        <FormGroup className="mb-10 mb-md-8">
                          <Label for="LoadIdAddon">Load ID Addon:</Label>
                          <InputGroup>
                            <CustomInput
                              type="select"
                              name="LoadIdAddon"
                              id="LoadIdAddon-select"
                              data-testid="LoadIdAddon-select"
                              value={values.LoadIdAddon}
                              onChange={(e) => {
                                handleChange(e);
                                setSelectedLoadIdAddon(e.target.value);
                                setFieldValue('Product', '');
                              }}
                              onBlur={handleBlur}
                              invalid={Boolean(errors.LoadIdAddon) && touched.LoadIdAddon}
                              bsSize="lg"
                              disabled={getLoadIdAddons().length <= 1}
                              onClick={
                                () => lidAddonClickHandler(pageTitleConstant.dropDetailsForm)
                              }
                              className="input-colour"
                            >
                              <option key="" value="" hidden>Select an option...</option>
                              {
                                getLoadIdAddons().map(
                                  (addon) => (
                                    <option
                                      key={addon}
                                      value={addon}
                                      data-testid={`LoadIdAddon-option-${addon}`}
                                    >
                                      {addon}
                                    </option>
                                  ),
                                )
                              }

                            </CustomInput>
                            <FormFeedback type="invalid" data-testid="LoadIdAddon-feedback">{errors.LoadIdAddon}</FormFeedback>
                          </InputGroup>
                        </FormGroup>
                      ) : (
                        // If it is a aggregate drop detail
                        <FormGroup className="mb-10 mb-md-8">
                          <Label for="LoadIdAddon">Load ID Addon:</Label>
                          <InputGroup>
                            <CustomInput
                              type="select"
                              name="LoadIdAddon"
                              id="LoadIdAddon-select"
                              data-testid="LoadIdAddon-select"
                              value={values.LoadIdAddon}
                              onChange={(e) => {
                                if (e.target.value.split(',').length > 1) {
                                  handleChange(e);
                                  console.log('Chainging');
                                  setSelectedLoadIdAddon(e.target.value.split(',')[0]);
                                  setAllLidSelected(true);
                                  handleFormResetAggregateMulti(resetForm, values);
                                } else {
                                  handleChange(e);
                                  setSelectedLoadIdAddon(e.target.value);
                                  setAllLidSelected(false);
                                  handleFormResetAggregateSingle(resetForm, values);
                                }
                                setFieldValue('Product', aggregateProduct);
                                setFieldValue('LoadIdAddon', e.target.value);
                              }}
                              onBlur={handleBlur}
                              invalid={Boolean(errors.LoadIdAddon) && touched.LoadIdAddon}
                              bsSize="lg"
                              disabled={getLoadIdAddons().length <= 1}
                              onClick={
                                () => lidAddonClickHandler(pageTitleConstant.dropDetailsForm)
                              }
                              className="input-colour"
                            >
                              <option key="" value="" hidden>Select an option...</option>

                              {
                                getLoadIdAddonsWithAggregate().map(
                                  (addon) => (
                                    <option
                                      key={addon}
                                      value={addon}
                                      data-testid={`LoadIdAddon-option-${addon}`}
                                    >
                                      {(typeof (addon) === 'string') ? addon : `ALL(${addon})`}
                                    </option>
                                  ),
                                )
                              }
                            </CustomInput>
                          </InputGroup>
                          {
                            !!errors.LoadIdAddon && touched.LoadIdAddon
                              ? <FormText color="error" data-testid="LoadIdAddon-feedback">{errors.LoadIdAddon}</FormText>
                              : <FormText color="help" data-testid="LoadIdAddon-info">Select option ‘ALL’ to drop all the fuel in the same tank. This option is only available when no other drop detail has been added.</FormText>
                          }
                        </FormGroup>
                      )}

                    </Col>
                  )}

                  {(!aggregateFlag || (aggregateFlag && !allLidSelected)) && (
                    <Col>
                      <Label for="TASVehicleID" className="vehicle-row mb-10 mt-md-8 mb-md-8">Vehicle:&nbsp;</Label>
                      <Label
                        name="TASVehicleID"
                        data-testid="TASVehicleID"
                        className="vehicle-no"
                        value={getSelectedVehicle}
                      >
                        <b>{getSelectedVehicle}</b>
                      </Label>
                    </Col>
                  )}

                  <Col xs="12">
                    <div className="tablet-divider" />
                  </Col>

                </Row>

                <Row>
                  {/* Field: Tank */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="Tank">
                        Tank Number / Name -
                        {' '}
                        <span className="optional">optional</span>
                        :
                      </Label>
                      <InputGroup className="input-colour">
                        <Input
                          type="text"
                          name="Tank"
                          data-testid="Tank-field"
                          value={values.Tank}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={!!errors.Tank && touched.Tank}
                          autoComplete="off"

                        />

                        <FormFeedback type="invalid" data-testid="Tank-feedback">{errors.Tank}</FormFeedback>
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  {/* Field: Product */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="product">Product:</Label>
                      {
                        !aggregateFlag ? (
                          <InputGroup>
                            <CustomInput
                              type="select"
                              name="Product"
                              id="Product-select"
                              data-testid="Product-select"
                              value={values.Product}
                              onChange={(e) => {
                                handleChange(e);
                                setSelectedProduct(e.target.value);
                              }}
                              onBlur={handleBlur}
                              invalid={!!errors.Product && touched.Product}
                              bsSize="lg"
                              disabled={getProducts(values).length < 1}
                              onClick={() => productClickHandler(pageTitleConstant.dropDetailsForm)}
                              className="input-colour"
                            >
                              <option key="" value="" hidden>Select an option...</option>
                              {
                                getProducts(values).map(
                                  (product) => (
                                    <option
                                      key={product.Name}
                                      value={product.Name}
                                      data-testid={`Product-option-${product.Name}`}
                                    >
                                      {product.Name}
                                    </option>
                                  ),
                                )
                              }
                            </CustomInput>
                            <FormFeedback type="invalid" data-testid="Product-feedback">{errors.Product}</FormFeedback>
                          </InputGroup>
                        ) : (
                          <InputGroup>
                            <Input
                              type="string"
                              name="Product"
                              id="Product-select"
                              data-testid="Product-select"
                              value={values.Product}
                              autoComplete="off"
                              readOnly
                            />
                          </InputGroup>
                        )
                      }

                      {
                        !isOpenOrder && (
                          <FormText color="help" className="d-inline" data-testid="qty-ambient">Qty ambient remaining: </FormText>
                        )
                      }

                      {
                        !isOpenOrder && (
                          (!aggregateFlag || (aggregateFlag && !allLidSelected)) ? (
                            getSelectedProducts()
                              .filter((product) => product.Name === values.Product)
                              .map((product) => (
                                <FormText key={product} color="help" className="qty-ambient d-inline">
                                  {
                                    calculateTotalDeliverd([...new Set(dropDetailsAll)])
                                      .get(selectedLoadIdAddon + product.Name)
                                      ? (product.QuantityAmbient
                                        - calculateTotalDeliverd([...new Set(dropDetailsAll)])
                                          .get(selectedLoadIdAddon + product.Name)).toFixed(2)
                                      : product.QuantityAmbient
                                  }
                                </FormText>
                              ))
                          ) : (
                            <FormText color="help" className="qty-ambient d-inline">{aggregateQtyAmbient.toFixed(2)}</FormText>
                          )

                        )
                      }

                    </FormGroup>
                  </Col>

                  {
                    isOpenOrder && (
                      /* Field: QuantityAmbient */
                      <Col xs="12" md="6">
                        <FormGroup className="mb-10">
                          <Label for="QuantityAmbient">
                            Quantity Ambient:
                          </Label>
                          <InputGroup className="input-colour">
                            <Input
                              type="number"
                              name="QuantityAmbient"
                              data-testid="QuantityAmbient-field"
                              value={values.QuantityAmbient}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={!!errors.QuantityAmbient && touched.QuantityAmbient}
                              autoComplete="off"
                            />
                          </InputGroup>
                          {
                            !!errors.QuantityAmbient && touched.QuantityAmbient
                              ? <FormText color="error" data-testid="QuantityAmbient-feedback">{errors.QuantityAmbient}</FormText>
                              : (
                                <FormText color="help" data-testid="QuantityAmbient-info">
                                  Enter the qty. ambient value of the product
                                  as mentioned in the Bill of Lading.
                                </FormText>
                              )
                          }
                        </FormGroup>
                      </Col>
                    )
                  }

                  {
                    isOpenOrder && (
                      /* Field: QuantityStandard */
                      <Col xs="12" md="6">
                        <FormGroup className="mb-10">
                          <Label for="QuantityStandard">
                            Quantity Standard (15˚C):
                          </Label>
                          <InputGroup className="input-colour">
                            <Input
                              type="number"
                              name="QuantityStandard"
                              data-testid="QuantityStandard-field"
                              value={values.QuantityStandard}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              invalid={!!errors.QuantityStandard && touched.QuantityStandard}
                              autoComplete="off"
                            />
                          </InputGroup>
                          {
                            !!errors.QuantityStandard && touched.QuantityStandard
                              ? <FormText color="error" data-testid="QuantityStandard-feedback">{errors.QuantityStandard}</FormText>
                              : (
                                <FormText color="help" data-testid="QuantityStandard-info">
                                  Enter the qty. standard value of the product
                                  as mentioned in the Bill of Lading.
                                </FormText>
                              )
                          }
                        </FormGroup>
                      </Col>
                    )
                  }

                  {/* Field: Capacity */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="Capacity">
                        Capacity (SFL)
                      </Label>
                      <InputGroup className="input-colour">
                        <Input
                          type="number"
                          name="Capacity"
                          data-testid="Capacity-field"
                          value={values.Capacity}
                          onChange={handleChange}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          invalid={!!errors.Capacity && touched.Capacity}
                          onBlur={(e) => {
                            if (e.target.value) {
                              e.target.value = parseInt(e.target.value.split('.')[0], 10);
                            }
                            handleChange(e); // eslint-disable-next-line
                            touched.Capacity = true;
                          }}
                          autoComplete="off"
                        />
                      </InputGroup>
                      {
                        !!errors.Capacity && touched.Capacity
                          ? <FormText color="error" data-testid="Capacity-feedback">{errors.Capacity}</FormText>
                          : <FormText color="help" data-testid="Capacity-info">Only whole numbers are accepted for this field.</FormText>
                      }
                    </FormGroup>
                  </Col>

                  {/* Field: Compartments */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="Compartments">
                        Compartment(s) -
                        <span className="optional">optional</span>
                        :
                      </Label>
                      <InputGroup className="input-colour">
                        <Input
                          type="tel"
                          name="Compartments"
                          data-testid="Compartments-field"
                          value={values.Compartments}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={!!errors.Compartments}
                          autoComplete="off"
                        />
                      </InputGroup>
                      {
                        errors.Compartments
                          ? <FormText color="error" data-testid="Compartments-feedback">{errors.Compartments}</FormText>
                          : <FormText color="help" data-testid="Compartments-info">Only numerical values, &apos;,&apos; and &apos;-&apos; are accepted for this field. If more than 1 compartment, separate with commas.</FormText>
                      }
                    </FormGroup>
                  </Col>

                  {/* Field: DipBefore */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="DipBefore">Dip Before (B):</Label>
                      <InputGroup className="input-colour">
                        <Input
                          type="number"
                          name="DipBefore"
                          data-testid="DipBefore-field"
                          value={values.DipBefore}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            if (!!aggregateFlag && !!allLidSelected) {
                              setFieldValue('DipAfter', parseInt(e.target.value, 10) + aggregateQtyAmbient);
                            } else {
                              updateCalculatedValue(
                                setFieldValue,
                                { ...values, DipBefore: e.target.value },
                              );
                            }
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            if (e.target.value) {
                              e.target.value = parseInt(e.target.value.split('.')[0], 10);
                            }
                            if (!!aggregateFlag && !!allLidSelected) {
                              setFieldValue('DipAfter', parseInt(e.target.value, 10) + aggregateQtyAmbient);
                            } else {
                              updateCalculatedValue(
                                setFieldValue,
                                { ...values, DipBefore: e.target.value },
                              );
                            }
                            handleChange(e); // eslint-disable-next-line
                            touched.DipBefore = true;
                          }}
                          invalid={!!errors.DipBefore && touched.DipBefore}
                          autoComplete="off"
                        />
                      </InputGroup>
                      {
                        !!errors.DipBefore && touched.DipBefore
                          ? <FormText color="error" data-testid="DipBefore-feedback">{errors.DipBefore}</FormText>
                          : <FormText color="help" data-testid="DipBefore-info">Only whole numbers are accepted for this field.</FormText>
                      }
                    </FormGroup>
                  </Col>

                  {/* Field: DipAfter */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="DipAfter">Dip After (A):</Label>
                      <InputGroup className="input-colour">
                        <Input
                          type="number"
                          name="DipAfter"
                          data-testid="DipAfter-field"
                          value={values.DipAfter}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            updateCalculatedValue(
                              setFieldValue,
                              { ...values, DipAfter: e.target.value },
                            );
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            if (e.target.value) {
                              e.target.value = parseInt(e.target.value.split('.')[0], 10);
                            }
                            updateCalculatedValue(
                              setFieldValue,
                              { ...values, DipAfter: e.target.value },
                            );
                            handleChange(e); // eslint-disable-next-line
                            touched.DipAfter = true;
                          }}
                          invalid={!!errors.DipAfter && touched.DipAfter}
                          autoComplete="off"
                          disabled={!!aggregateFlag && !!allLidSelected}
                        />
                      </InputGroup>
                      {(aggregateFlag && allLidSelected) ? (
                        <FormText className="text-help">We will calculate this for you.</FormText>
                      ) : (
                        (!!errors.DipAfter && touched.DipAfter)
                          ? <FormText color="error" data-testid="DipAfter-feedback">{errors.DipAfter}</FormText>
                          : <FormText color="help" data-testid="DipAfter-info">Only whole numbers are accepted for this field.</FormText>
                      )}
                    </FormGroup>
                  </Col>

                  {/* Field: Calculated */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="Calculated">Calculated (A - B):</Label>
                      <InputGroup className="input-colour">
                        <Input
                          type="number"
                          name="Calculated"
                          data-testid="Calculated-field"
                          value={values.Calculated}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={!!errors.Calculated && touched.Calculated}
                          autoComplete="off"
                          disabled
                        />
                      </InputGroup>
                      {
                        (!!errors.Calculated && touched.Calculated)
                          ? <FormText color="error" data-testid="Calculated-feedback">{errors.Calculated}</FormText>
                          : <FormText color="help" data-testid="Calculated-info">We will calculate this for you.</FormText>
                      }
                    </FormGroup>
                  </Col>

                  {/* Field: ActualDischargeQty */}
                  <Col xs="12" md="6">
                    <FormGroup className="mb-10">
                      <Label for="ActualDischargeQty">Actual Discharge Quantity:</Label>
                      <InputGroup className="input-colour">
                        <Input
                          type="number"
                          name="ActualDischargeQty"
                          data-testid="ActualDischargeQty-field"
                          value={values.ActualDischargeQty}
                          onChange={(e) => {
                            handleChange(e);
                            ambientValidationCheck(
                              { ...values, ActualDischargeQty: e.target.value },
                            );
                          }}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value && e.target.value !== 0) {
                              e.target.value = parseInt(e.target.value.split('.')[0], 10);
                            }
                            updateCalculatedValue(
                              setFieldValue,
                              { ...values, ActualDischargeQty: e.target.value },
                            );
                            handleChange(e); // eslint-disable-next-line
                            touched.ActualDischargeQty = true;
                          }}
                          invalid={
                            (!!errors.ActualDischargeQty && touched.ActualDischargeQty)
                            || !!quantityWarning
                          }
                          autoComplete="off"
                          disabled={!!aggregateFlag && !!allLidSelected}
                        />
                      </InputGroup>
                      {(aggregateFlag && allLidSelected) ? (
                        <FormText>We will calculate this for you.</FormText>
                      ) : (
                        (!!errors.ActualDischargeQty && touched.ActualDischargeQty)
                          ? <FormText color="error" data-testid="ActualDischargeQty-feedback">{errors.ActualDischargeQty}</FormText>
                          : (quantityWarning && <FormText color="warn" data-testid="ActualDischargeQty-feedback">{quantityWarning}</FormText>
                          )
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xs="12">
                    <PrimaryButton
                      disabled={isSubmitting || !isValid || !dirty || ambientDisable}
                      className="submit-button"
                      data-testid="save-button"
                      type="Submit"
                      label="Save"
                      tabIndex={0}
                      onClick={() => saveClickHandler(pageTitleConstant.dropDetailsForm)}
                    />
                  </Col>
                  <Col xs="12" className="mt-6">
                    <div
                      className="green-link"
                      data-testid="clear-all-link"
                      onClick={() => {
                        handleFormReset(resetForm, values);
                        clearFieldsClickHandler(pageTitleConstant.dropDetailsForm);
                      }}
                      role="button"
                    >
                      Clear all fields
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

/**
 * We use oneOfType because
 * the application uses AppSyncClient
 * the tests/jest provide ApolloClient as we use the <MockProvider/>
 */
EnterDropDetails.propTypes = {
  graphClient: PropTypes.oneOfType([
    PropTypes.instanceOf(AWSAppSyncClient).isRequired,
    PropTypes.instanceOf(ApolloClient).isRequired,
  ]).isRequired,
};

// withClient will provide the client in props
export default withClient(EnterDropDetails);
